import apiClient from "../apiClient";
// import { router } from "../../routes/router";
import Swal from "sweetalert2";
import baseService from "../baseService";
// import { router } from '../../routers/router'

/*
User Service
*/
const USERS_ENDPOINT = "/api/users/";
export default class UserService extends baseService {

  USERS_ENDPOINT = USERS_ENDPOINT;
  constructor() {
    super(USERS_ENDPOINT, "user")
  }

  me() {
    return apiClient
      .get(`${this.USERS_ENDPOINT}me`)
      .catch((err) => {
        console.log("GET ME ACCOUNT ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.data.message,
        });
      });
  }

  allUsers() {
    return apiClient
      .get(this.USERS_ENDPOINT)
      .catch((err) => {
        console.log("GET ALL USERS ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.data.message,
        });
      });
  }

  getUserId(id) {
    return apiClient
      .get(`${this.USERS_ENDPOINT}${id}/`)
      .catch((err) => {
        console.log("GET USER ACCOUNT ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível encontrar o usuário",
        });
      });
  }

  editUser(user) {
    return apiClient
      .patch(`${this.USERS_ENDPOINT}${user.id}/`, user)
      .catch((err) => {
        console.log("EDIT USER ACCOUNT ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err,
        });
      });
  }
}
