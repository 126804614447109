import Swal from "sweetalert2";
import apiClient from "./apiClient";

/*
Base Service
*/
export default class baseService {
	constructor(endpoint, model_name, id_key = "id") {
		if (endpoint.charAt(endpoint.length - 1) != "/") {
			endpoint = `${endpoint}/`;
		}
		this.endpoint = endpoint;
		this.model_name = model_name;
		this.id_key = id_key;
	}

	list(config = { params: {} }) {
		//passar dentro do list como parametro o search, ver linha 243 de addDocument
		return apiClient.get(this.endpoint, config).catch((err) => {
			console.log(err);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.data.message,
			});
		});
	}

	detailed_list(ids = []) {
		let promises = [];
		for (let index = 0; index < ids.length; index++) {
			const element = ids[index];
			promises.push(this.retrieve(element));
		}

		return Promise.all(promises).then((r) => {
			let r_obj = {};
			for (let index = 0; index < r.length; index++) {
				const response = r[index];
				if (response.status == 200) {
					let obj = response.data;
					r_obj[obj[this.id_key]] = obj;
				}
			}
			return r_obj;
		});
	}

	create(obj) {
		return apiClient.post(`${this.endpoint}`, obj).catch(async (err) => {
			if (err.request.status != 500) {
				throw err;
			}
			console.log(err);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: `Não foi possível criar ${this.model_name}`,
			});
		});
	}

	retrieve(id) {
		return apiClient.get(`${this.endpoint}${id}/`).catch((err) => {
			console.log(err);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: `Não foi possível encontrar ${this.model_name}`,
			});
		});
	}

	update(obj) {
		return apiClient
			.patch(`${this.endpoint}${obj[this.id_key]}/`, obj)
			.catch((err) => {
				console.log(`EDIT ${this.model_name} ERROR`);
				console.log(err);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: err,
				});
			});
	}

	destroy(id) {
		return apiClient.delete(`${this.endpoint}${id}/`).catch((err) => {
			console.log(`DELETE ${this.model_name} ERROR`);
			console.log(err);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err,
			});
		});
	}
}
