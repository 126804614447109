<template>
	<div>
		<div class="row">
			<div
				class="col-sm-5 col-md-4 col-lg-3"
				style="font-size: 16px; font-weight: bold; color: #878ea4"
			>
				Dados Básicos
			</div>
			<div class="col-sm-7 col-md-8 col-lg-9">
				<hr style="margin-left: -17%" />
			</div>
		</div>
		<form @submit.prevent="createProduct()" id="Impressoras">
			<div
				v-if="!this.product.referencia"
				class="row"
				style="margin: 1%; text-align: left"
			>
				<div
					class="col-sm-12 col-md-3"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Grupo</label>
					<b-select
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						class="form-control"
						v-model="product.reference_model"
						:options="reference_model_options"
						required
					>
					</b-select>
				</div>
				<div
					class="col-sm-12 col-md-3"
					style="font-size: 14px; color: #878ea4"
				>
				<label
						v-if="this.product.referencia"
						for="modelo"
						style="white-space: nowrap"
						>Modelo de Referência({{
							this.product.referencia | truncate(4, "...")
						}})</label
					>

					<label v-else for="modelo">Modelo de Referência</label>
					<vue-select
						style="
								background: #f5f4fc 0% 0% no-repeat padding-box !important;
								border-radius: 2px !important;
								opacity: 1 !important;
								text-transform: uppercase !important;
								display: block !important;
								padding: 2px 2px 2px 12px !important;
								border: 1px solid #ced4da;
							"
						:options="products_option"
						:filterBy="modelFilterBy"
						v-model="product.product"
						></vue-select>
					<p
					style="
						font-size: 10px;
						background: #f5f4fc 0% 0% no-repeat padding-box !important;
							color: #878ea4;
							padding-left: 4px;
							margin-bottom: 0px;"
							v-if="this.product.referencia"
							>
						{{ this.product.referencia | truncate(40, "...") }}
					</p>
				</div>

				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Qt. Contratada</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="number"
						id="qtContratada"
						class="form-control"
						
						v-model="product.amount"
					/>
					<p
						style="
							font-size: 10px;
							color: #878ea4;
							padding-left: 4px;
							margin-bottom: 0px;
						"
						v-if="this.product.contratada"
					>
						{{
							("Limite: " + this.product.contratada)
								| truncate(40, "...")
						}}
					</p>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="">Qt. Aprovada</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						id="qtAprovada"
						class="form-control"
						
						v-model="product.allowed"
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Qt. Liberada</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						id="qtLiberada"
						class="form-control"
						
						v-model="product.released"
					/>
				</div>
			</div>
			<!-- exibe essa linha na customização -->
			<div
				v-if="this.product.referencia"
				class="row"
				style="margin: 1%; text-align: left"
			>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Grupo</label>
					<b-select
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						class="form-control"
						v-model="product.reference_model"
						:options="reference_model_options"
						required
					>
					</b-select>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label
						v-if="this.product.referencia"
						for="modelo"
						style="white-space: nowrap"
						>Modelo de Ref.
					</label>

					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							color: #c0c0c0;
						"
						type="text"
						id="qtContratada"
						class="form-control"
						v-model="this.product.referencia"
						disabled
					/>
					<p
						style="
							font-size: 10px;
							color: #878ea4;
							padding-left: 4px;
							margin-bottom: 0px;
						"
						v-if="this.product.referencia"
					>
						{{ this.product.referencia | truncate(40, "...") }}
					</p>
				</div>
				
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
				
					<label for="modelo">Modelo Cust.</label>
					<vue-select
						style="
								background: #f5f4fc 0% 0% no-repeat padding-box !important;
								border-radius: 2px !important;
								opacity: 1 !important;
								text-transform: uppercase !important;
								display: block !important;
								padding: 2px 2px 2px 12px !important;
								border: 1px solid #ced4da;
							"
						:options="products_option"
						v-model="product.product"
						></vue-select>
					<p
						style="
							font-size: 10px;
							color: #878ea4;
							padding-left: 4px;
							margin-bottom: 0px;
						"
						v-if="this.product.referencia"
					>
						{{ this.product.referencia | truncate(40, "...") }}
					</p>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Qt. Contratada</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="number"
						id="qtContratada"
						class="form-control"
						
						v-model="product.amount"
					/>
					<p
						style="
							font-size: 10px;
							color: #878ea4;
							padding-left: 4px;
							margin-bottom: 0px;
						"
						v-if="this.product.contratada"
					>
						{{
							("Limite: " + this.product.contratada)
								| truncate(40, "...")
						}}
					</p>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="">Qt. Aprovada</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						id="qtAprovada"
						class="form-control"
						
						v-model="product.allowed"
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Qt. Liberada</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						id="qtLiberada"
						class="form-control"
						
						v-model="product.released"
					/>
				</div>
			</div>
			<div class="row" style="margin: 1%; text-align: left">
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Novo ou Usado</label>
					<b-select
						name="novo-usado"
						class="form-control"
						required
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						v-model="product.new"
						:options="[
							{
								value: false,
								text: 'Usado',
							},
							{ value: true, text: 'Novo' },
						]"
					>
					</b-select>
				</div>

				<div
					class="col-sm-12 col-md-3"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Velocidade Mínima</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						id="velocidadeMinima"
						class="form-control"
						
						v-model="product.minimum_specification"
					/>
				</div>
				<div
					class="col-sm-12 col-md-3"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Qt. Transformadores</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						id="quantidadeTransformadores"
						class="form-control"
						
						v-model="product.number_of_transformers"
						
					/>
					<p
						style="
							font-size: 10px;
							color: #878ea4;
							padding-left: 4px;
							margin-bottom: 0px;
						"
						v-if="this.product.limiteTransformers"
					>
						{{
							("Limite: " + this.product.limiteTransformers)
								| truncate(40, "...")
						}}
					</p>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Valor Unitário</label>
					<input
						v-model="product.valor_unitario"
						v-bind="proposal_format"
						class="form-control crm-input"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="valor-total" id="valorTotal">Valor Total</label>
					<input
						v-model="total_product_value"
						v-bind="proposal_format"
						readonly
						class="form-control crm-input"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							opacity: 1;
						"
					/>
				</div>
				<div
						class="col-sm-12 col-md-12 mt-2"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo">Observações</label>
						<input
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
								padding: 2%;
							"
							type="text"
							class="form-control"
							id="modelo"
							v-model="product.obs"
						/>
					</div>
			</div>

			<div class="row">
				<div
					class="col-sm-5 col-md-4 col-lg-3"
					style="
						font-size: 16px;
						font-weight: bold;
						color: #878ea4;
						margin-top: 4px;
					"
				>
					Franquia por Máquina
				</div>
				<div class="col-sm-7 col-md-8 col-lg-9">
					<hr style="margin-left: -9%" />
				</div>
			</div>
			<div class="row" style="margin: 1%; text-align: left">
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Franquia Color</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						id="franquiaColor"
						
						v-model="product.franqcolor"
						
					/>
					<p
						style="
							font-size: 10px;
							color: #878ea4;
							padding-left: 4px;
							margin-bottom: 0px;
						"
						v-if="this.product.limiteColor"
					>
						{{
							("Limite: " + this.product.limiteColor)
								| truncate(40, "...")
						}}
					</p>
				</div>
				<div
					class="col-sm-12 col-md-3"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Franquia P&B</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						id="franquiaPb"
						class="form-control"
						
						v-model="product.franqpb"
						
					/>
					<p
						style="
							font-size: 10px;
							color: #878ea4;
							padding-left: 4px;
							margin-bottom: 0px;
						"
						v-if="this.product.limitePb"
					>
						{{
							("Limite: " + this.product.limitePb)
								| truncate(40, "...")
						}}
					</p>
				</div>

				<div
					class="col-sm-12 col-md-3"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Frq. Digitalização</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						id="franquiaDigitalizacao"
						class="form-control"
						
						v-model="product.franqdg"
					/>
				</div>
			</div>
			<div class="row">
				<div
					class="col-sm-5 col-md-4 col-lg-3"
					style="
						font-size: 16px;
						font-weight: bold;
						color: #878ea4;
						margin-top: 4px;
					"
				>
					Valores Papel
				</div>
				<div class="col-sm-7 col-md-8 col-lg-9">
					<hr style="margin-left: -17%" />
				</div>
			</div>

			<div class="row" style="margin: 1%; text-align: left">
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Quantidade</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						id="quantidade"
						class="form-control"
						
						v-model="product.qtd_paper"
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Valor Unitário</label>
					<input
						v-model="product.per_page"
						v-bind="proposal_format"
						class="form-control crm-input"
						ref="per_page_ref"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="valor-total" id="valorTotal">Valor Total</label>
					<input
						v-model="total_value"
						v-bind="proposal_format"
						readonly
						class="form-control crm-input"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							opacity: 1;
						"
					/>
				</div>
			</div>
			<div class="row">
				<div
					class="col-sm-5 col-md-4 col-lg-3"
					style="
						font-size: 16px;
						font-weight: bold;
						color: #878ea4;
						margin-top: 4px;
					"
				>
					Valores Excedentes
				</div>
				<div class="col-sm-7 col-md-8 col-lg-9">
					<hr style="margin-left: -11%" />
				</div>
			</div>
			<div class="row" style="margin: 1%; text-align: left">
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Impressão Mono</label>
					<input
						v-model="product.surplus_value_bw"
						v-bind="proposal_format"
						class="form-control crm-input"
						ref="surplus_value_bw_ref"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						pattern="^\R\$ [0-9-./-]{0,20}"
						oninvalid="this.setCustomValidity('Digite até 20 números')"
						oninput="setCustomValidity('')"
						
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Impressão Color</label>
					<input
						v-model="product.surplus_value_color"
						v-bind="proposal_format"
						class="form-control crm-input"
						ref="surplus_value_color_ref"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						pattern="^\R\$ [0-9-./-]{0,20}"
						oninvalid="this.setCustomValidity('Digite até 20 números')"
						oninput="setCustomValidity('')"
						
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 14px; color: #878ea4"
				>
					<label for="modelo">Digitalização</label>
					<input
						v-model="product.surplus_value_digitization"
						v-bind="proposal_format"
						class="form-control crm-input"
						ref="surplus_value_digitization_ref"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						pattern="^\R\$ [0-9-./-]{0,20}"
						oninvalid="this.setCustomValidity('Digite até 20 números')"
						oninput="setCustomValidity('')"
						
					/>
				</div>
			</div>
			<div style="margin-top: -20px">
				<button
					type="button"
					class="btn btn-secondary py-2 px-4"
					style="
						font-size: 11px;
						margin-top: 50px;
						background: #878787 0% 0% no-repeat padding-box;
						border-radius: 100px;
						opacity: 1;
					"
					@click="
						() => {
							this.$emit('cancel');
						}
					"
				>
					CANCELAR
				</button>

				<button
					type="submit"
					class="btn btn-primary py-2 px-4"
					style="
						font-size: 11px;
						margin-top: 50px;
						margin-left: 10px;
						background: #0bb4d4 0% 0% no-repeat padding-box;
						border-radius: 100px;
						opacity: 1;
					"
				>
					SALVAR
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import ProductService from "../../services/ProductService/ProductService";
import ProductGroupService from "../../services/ProductGroupService/ProductGroupService";
import ProductProposalService from "../../services/ProductsProposalsService/ProductProposalsService";

import Swal from "sweetalert2";

export default {
	name: "impressoraScanner",
	props: ["value"],
	data() {
		return {
			product_proposal_service: new ProductProposalService(),
			product_service: new ProductService(),
			product_group_service: new ProductGroupService(),
			reference_model:[],
			models: [],
			search: "",
			proposal: {
				client: {},
				proposal_value: 0,
				start_date: null,
				end_date: null,
			},
			product: {
				per_page: 0,
				group: null,
				franqdg: 0,
			},

			proposal_format: {
				decimal: ",",
				separator: ".",
				prefix: "R$ ",
				precision: 4,
			},
			not_found: false,
		};
	},
	mounted() {
		this.product = this.value;
		this.reload();
	},
	methods: {
		valida() {
			if (this.product.amount > this.product.contratada) {
				this.product.amount = this.product.contratada;
			}
			if (
				this.product.number_of_transformers >
				this.product.limiteTransformers
			) {
				this.product.number_of_transformers =
					this.product.limiteTransformers;
			}
			if (this.product.franqcolor > this.product.limiteColor) {
				this.product.franqcolor = this.product.limiteColor;
			}
			if (this.product.franqpb > this.product.limitePb) {
				this.product.franqpb = this.product.limitePb;
			}
		},

		reload() {
			this.product_service
				.list({ params: this.params })
				.then(({ data }) => {
					this.models = data.results;
				});
			this.product_group_service.list({ params: { type: 0 } }).then(({ data }) => {
				this.reference_model = data.results
			})
		},
		changeRoute(e) {
			this.$router.push("/" + e.target.value);
		},
		createProduct() {
			this.submmited = true;

			let product = Object.assign({}, this.product);
			console.log(this.product)
			product.product_obj = this.product.product
			product.product = this.product.product.value
			product.proposal = this.$route.params.id;
			product.surplus_value_bw = Number(`${product.surplus_value_bw}`.replace(",", "."))
			product.surplus_value_digitization = Number(`${product.surplus_value_digitization}`.replace(",", "."))
			product.surplus_value_color = Number(`${product.surplus_value_color}`.replace(",", "."))
			// product.product = product.product.id;

			let promise = new Promise((resolve) => {
				resolve();
			});
			if (product.id) {
				if (product.parent) {
					this.product_proposal_service
						.destroy(product.id)
						.then(this.product_proposal_service.create(product));
				} else {
					promise = this.product_proposal_service
						.update(product)
						.catch(() => {
							Swal.fire({
								title: "Falha!",
								text: "Não foi possivel atualizar o equipamento, verifique o formulário",
								icon: "error",
								showCancelButton: false,
								confirmButtonColor: "#0bb4d4",
								cancelButtonColor: "#A9A9A9",
								confirmButtonText: "OK",
								cancelButtonText: "Cancelar",
							});
						})
						.then(() => {
							Swal.fire({
								title: "Atualizado!",
								text: "Equipamento atualizado com sucesso",
								icon: "success",
								showCancelButton: false,
								confirmButtonColor: "#0bb4d4",
								cancelButtonColor: "#A9A9A9",
								confirmButtonText: "OK",
								cancelButtonText: "Cancelar",
							});
						});
				}
			} else {
				promise = this.product_proposal_service
					.create(product)
					.catch(() => {
						console.log("catch");
						Swal.fire({
							title: "Falha!",
							text: "Não foi possivel adicionar o equipamento, verifique o formulário",
							icon: "error",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					})
					.then(() => {
						Swal.fire({
							title: "Adicionado!",
							text: "Equipamento adicionado com sucesso",
							icon: "success",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					});
			}
			promise.then(() => {
				this.$emit("added-product", this.product);
			});
		},
		checkqt() {
			let released = this.product.released;
			let allowed = this.product.allowed;
			let amount = this.product.amout;
			if (released || allowed > amount) {
				console.log("Quantidade não pode ser maior que a contratada");
			}
		},
		modelFilterBy(option, label, search){
			let case_1 = (option.referencia || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
			let case_2 = (option.nome || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
			return case_1 || case_2
		}
	},
	computed: {
		products_option() {
			let products = this.models;
			let reference_model = this.product.reference_model
			return products.filter(p => !reference_model || p.group == reference_model).map((p) => ({ value: p.id, label: `${p.referencia}`, referencia:p.referencia, nome:p.nome }));
		},
		reference_model_options(){
			let reference_model = this.reference_model
			return reference_model.map((p) => ({ value: p.id, text: p.nome }));
		},
		params() {
			let p = {};
			p.search = this.search;
			p.type = 0;
			return p;
		},

		total_mensal() {
			let amount = this.product.amount;
			let custo = this.product.custo;

			if (!isNaN(amount) && !isNaN(custo)) {
				return `R$ ${(custo * amount).toFixed(2)}`;
			}

			return `R$ 0`;
		},
		total_value() {
			console.log(this.product, "lalalla")
			if (this.product.per_page && !isNaN(this.product.per_page)) {
				return `R$ ${(
					this.product.per_page * this.product.amount
				).toFixed(2)}`;
			}

			return `R$ 0`;
		},
		total_product_value() {
			if (
				this.product.valor_unitario &&
				!isNaN(this.product.valor_unitario)
			) {
				return `R$ ${(
					this.product.valor_unitario * this.product.amount
				).toFixed(2)}`;
			}

			return `R$ 0`;
		},
	},
	watch: {
		search() {
			this.reload();
		},
	},
};
</script>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0


.crm-input
  background-color: #red 0% 0% no-repeat padding-box !important
  border-radius: 2px !important
  opacity: 1 !important
</style>

<style scoped>
>>> {
	--vs-border-style: none;
}

/* >>> .vs--single {
  width: 18.75rem;
  padding: 10px;
  margin: 0;
  border: none;
  height: 38px;
}

>>> .vs__selected-options {
  width: 0;
}

>>> .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
} */
</style>
