import baseService from "../baseService";

/*
Product Service
*/
const PRODUCTS_ENDPOINT = "/api/products/";
export default class ProductService extends baseService {

  constructor() {
    super(PRODUCTS_ENDPOINT, "product")
  }
}
