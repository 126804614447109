<template>
  <div class="font ajust-margin" style="margin-top: 80px; margin-right: 95px; margin-left: 95px;">
    <div style="background: #FFFFFF; box-shadow: 0px 10px 10px rgba(226, 236, 249, 0.5); padding-bottom: 50px; border-radius: 30px;  padding-left: 38px;  padding-right: 38px">
      <div style="padding-top: 28px; margin-bottom:30px" class="d-flex justify-content-between">
        <p class="title">
          {{ $route.params.id ? "Editar" : "Nova" }} Proposta
        </p>
        <div>
          <button class="dark-button light-text">Informações iniciais</button>

          <button
            class="light-button dark-text disabled"
            v-if="!(this.proposal && this.proposal.id)"
            style="cursor: default"
          >
            DOCUMENTOS
          </button>
          <router-link
            v-else
            :to="{ name: 'addDoc', params: { id: this.proposal.id } }"
          >
            <button class="light-button dark-text">Documentos</button>
          </router-link>
        </div>
      </div>
      
    
    

    <div style="margin-bottom: 3%">
      <form action="">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="regiao">Empresa</label>
            <b-form-select
            v-model="proposal.company"
            :options="company_choices"
          ></b-form-select>
          </div>
          <div class="form-group col-md-3">
            <label for="regiao">CNPJ</label>
            <input
              type="text"
              class="form-control"
              v-model="proposal.client.cnpj"
            />
          </div>

          
         
        </div>

        <b-modal
          id="add-clients"
          size="xl"
          title="Adicionar Cliente"
          style="color: rgb(161, 0, 12) !important"
          hide-footer
        >
          <ClienteForm
            @added-client="addedClient"
            @cancel="
              () => {
                this.$bvModal.hide('add-clients');
              }
            "
          ></ClienteForm>
        </b-modal>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="periodo">Nome</label>
            <input
              type="text"
              class="form-control"
              v-model="proposal.client.nome"
            />
            <div style="padding-top: 10px; display: flex; align-items: center"> 
              
              <span v-b-modal.add-clients v-show="not_found" class="material-icons-outlined" style="color:#0BB4D4; float: left; font-size: 15px">
  add_circle
</span>
            <u><a style="color: #0BB4D4; font-size: 14px; padding-left: 10px;" v-b-modal.modal-xl.add-clients v-show="not_found"
              >CLIENTE NÃO REGISTRADO, CLIQUE PARA REGISTRAR</a
            ></u>
            </div>
          </div>
          <div class="form-group col-md-2">
            <label for="periodo"
            
              >Valor da Proposta</label
            >
            <number
              v-model="proposal.proposal_value"
              v-bind="proposal_format"
              class="form-control crm-input"
              ref="proposal_format_ref"
            ></number>
          </div>
          <div class="form-group col-md-2" style="margin-left: 2%">
            <label for="periodo"
              >Validade da Proposta</label
            >
            <date-picker
              style="background: #F9FBFF
                border: 1px solid #E7E7E7
                border-radius: 10px
                padding: 0.375rem 0.75rem"
              v-model="proposal.expire_date"
              format="DD/MM/YYYY"
              valueType="YYYY-MM-DD"
            ></date-picker>
          </div>
        </div>
      </form>
    </div>

   
    <div class="d-flex justify-content-end">
      <button
      type="submit"
      class="btn btn-secondary py-2"
      style="
        background: #7E7E7E;
        border-radius: 8px;
        padding-left: 30px;
        padding-right: 30px;
        margin-right: 20px;
        border: none;
      "
      @click="
          () => {
            this.$router.push('/propostas/');
          }
        "
    >
      Cancelar
    </button>
     <div
      :disabled="!formIsValid"
      class="btn btn-primary py-2 px-4"
      v-bind:style="[
        formIsValid
          ? {
              'background': '#006992',
              'border-radius': '8px',
              'padding-left': '30px',
              'padding-right': '30px',
              'cursor' : 'pointer',
              'border': 'none'
            }
          : {
              'background': '#CBCBCB',
              'border-radius': '8px',
              'padding-left': '30px',
              'padding-right': '30px',
              'cursor' : 'default',
              'border': 'none'
            },
      ]"
      @click="createProposal()"
    >
      Salvar
    </div>

    </div>
    
    </div>
  </div>
</template>


<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')

input.form-control
  background: #F9FBFF !important
  border: 1px solid #E7E7E7 !important
  border-radius: 10px !important
.title
  font-family: 'Poppins', sans-serif
  font-style: normal
  font-weight: 600
  font-size: 20px
  line-height: 33px
  letter-spacing: -0.01em
  color: #000000

.mx-input
  background: #F9FBFF !important
  border: 1px solid #E7E7E7 !important
  border-radius: 10px !important
  padding: 0.375rem 0.75rem !important

input
  background: #F9FBFF
  border: 1px solid #E7E7E7
  border-radius: 10px
  padding: 0.375rem 0.75rem

.btn
  font-family: 'Poppins'
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 24px
  /* identical to box height */
  text-align: center
  letter-spacing: -0.01em
  color: #FFFFFF
  text-transform: capitalize
.date-picker
  background: #F9FBFF !important
  border: 1px solid #E7E7E7 !important
  border-radius: 10px !important
  padding: 0.375rem 0.75rem

.step-description
  font-size: 21px
.dark-button
  background: #6DA1B5
  border-radius: 8px 0px 0px 8px

label
  font-family: 'Poppins'
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 21px

  letter-spacing: -0.01em

  color: #292D32


.light-button
  background: #F9FBFF
  border: 1px solid #E7E7E7
  border-radius: 0px 8px 8px 0px


.dark-text

  color: var(--unnamed-color-384364)
  text-align: center
  font: normal normal bold 10px/11px Montserrat
  letter-spacing: 0.25px
  color: #384364
  text-transform: uppercase
  opacity: 0.6

.light-text
  text-align: center
  font: normal normal bold 10px/11px Montserrat
  letter-spacing: 0.25px
  color: #FFFFFF
  text-transform: uppercase
  opacity: 1

.container
  padding: 0px
  margin: 0px
  display: flex
  justify-content: space-between

.btn-proposta
  background: #0bb4d4 0% 0% no-repeat padding-box
  border-width: 0px
  border-radius: 100px
  opacity: 1
  width: 155px
  height: 46px
  color: #ffffff
  font: normal normal bold 11px/11px Montserrat
  letter-spacing: 1.1px

.dark-button:focus
  outline-style: none
.light-button:focus
  outline-style: none

.input-text
  font: var(--unnamed-font-style-normal) normal 600 13px/66px Montserrat
  letter-spacing: var(--unnamed-character-spacing-0)
  text-align: left
  font: normal normal 600 13px/66px Montserrat
  letter-spacing: 0px
  color: #ACACAC
  opacity: 1
</style>

<style lang="sass">
:root
  --bs-gray-200: #eee
  --bs-gray-500: #999
  --bs-gray-600: #777
  --bs-gray-800: #444
  --bs-light: #f9f9f9
  --bs-pink: #384364

</style>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import "vue2-datepicker/locale/pt-br";

import { number } from "@coders-tm/vue-number-format";

import ClienteForm from "../../components/Cliente/ClienteForm.vue";
import ClientService from "../../services/ClientService/ClientService";
import ProposalService from "../../services/ProposalsService/ProposalsService";

export default {
  components: {
    ClienteForm,
    DatePicker,
    number,
  },
  mounted() {
    let id = this.$route.params.id;
    if (id) {
      this.proposal_service.retrieve(id).then((resp) => {
        this.proposal = resp.data;
        this.$refs["proposal_format_ref"].input({
          target: {
            value: this.proposal.proposal_value,
          },
        });
        this.client_service.retrieve(this.proposal.client).then((resp) => {
          this.proposal.client = resp.data;
        });
      });
    }
  },
  data() {
    return {
      client_service: new ClientService(),
      proposal_service: new ProposalService(),
      proposal: {
        expire_date: null,
        client: {},
        proposal_value: 0,
      },
      requested_client: false,
      not_found: false,
      proposal_format: {
        decimal: ",",
        separator: ".",
        prefix: "R$ ",
        precision: 2,
      },
      company_choices: [
        {value : "00", text: "MAQ-LAREM MAQUINAS MOVEIS E EQUIPAMENTOS LTDA"},
        {value : "01", text: "MAQ-LAREM FILIAL NATAL"},
        {value : "02", text: "MAQ-LAREM FILIAL PERNAMBUCO"},
        {value : "03", text: "ALFAPRINT LOCACOES EIRELLI - ME"},
        {value : "04", text: "SEVERINO MEDEIROS DO NASCIMENTO"},
        {value : "07", text: "MAQ-LAREM FILIAL ALAGOAS"},
        {value : "08", text: "TALLUM SOLUTION LOCACAO E COMERCIO DE EQUIPAMENTOS DE INFORM"}
      ]
    };
  },
  watch: {
    "proposal.client.cnpj": {
      handler(value, oldValue) {
        let v = value.replaceAll(/[^\d]/g, "").slice(0, 14);
        let f_v = this.formatCNPJ(value);
        let old_f_v = this.formatCNPJ(oldValue);
        while (!/\d/.test(f_v[f_v.length - 1]) && f_v.length > 0) {
          f_v = f_v.slice(0, f_v.length - 1);
        }
        if (v.length >= 14) {
          if (old_f_v != f_v) {
            this.requested_client = false;
          }
          if (!this.requested_client) {
            this.not_found = false;
            this.client_service
              .list({ params: { cnpj: f_v.replaceAll(/\D/g, "") } })
              .then((client) => {
                let results = client.data.results;
                if (results.length) {
                  //Liste os clientes baseado no editado mais recentemente
                  results = results.sort((a, b) => {
                    if (a.dtalt == null) {
                      return 1;
                    }
                    if (b.dtalt == null) {
                      return -1;
                    }
                    return a.dtalt - b.dtalt;
                  });
                  this.proposal.client = results[0];
                } else {
                  this.not_found = true;
                }
              });
            this.requested_client = true;
          }
        } else {
          this.requested_client = false;
          this.not_found = false;
        }

        this.proposal.client.cnpj = f_v;
      },
    },
  },
  computed: {
    formIsValid() {
      return (
        this.proposal.client.cnpj &&
        this.proposal.client.nome &&
        this.proposal.expire_date &&
        this.proposal.proposal_value
      );
    },
  },
  methods: {
    createProposal() {
      let proposal = Object.assign({}, this.proposal);
      proposal.client = proposal.client.codigo;
      if (this.$route.params.id) {
        proposal.products = undefined;
        this.proposal_service.update(proposal).then((resp) => {
          let r = resp.data;
          this.$router.push({ name: "addDoc", params: { id: r.id } });
        });
      } else {
        this.proposal_service.create(proposal).then((resp) => {
          let r = resp.data;
          this.$router.push({ name: "addDoc", params: { id: r.id } });
        });
      }
    },
    addedClient(client) {
      this.proposal.client.cnpj = " ";
      this.$nextTick(() => {
        Object.assign(this.proposal.client, client);
      });

      this.$bvModal.hide("add-clients");
    },
    formatCNPJ(cnpj) {
      if (!cnpj) {
        return cnpj;
      }
      let v = cnpj.replaceAll(/[^\d]/g, "").slice(0, 14);
      return v.replace(
        /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
        "$1.$2.$3/$4-$5"
      );
    },
  },
};
</script>
