import { render, staticRenderFns } from "./Pcs.vue?vue&type=template&id=585e69b9&scoped=true&"
import script from "./Pcs.vue?vue&type=script&lang=js&"
export * from "./Pcs.vue?vue&type=script&lang=js&"
import style0 from "./Pcs.vue?vue&type=style&index=0&id=585e69b9&prod&scoped=true&lang=sass&"
import style1 from "./Pcs.vue?vue&type=style&index=1&id=585e69b9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "585e69b9",
  null
  
)

export default component.exports