<template>
	<div>
		<form id="form-cliente" @submit.prevent="createClient()" style="padding: 38px;">
			<div class="row gx-5">
				<div class="form-group col-md-2">
					<label for="cnpj" style="font-size: 12px">CNPJ</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						id="cnpj"
						v-model="client.cnpj"
						required
						pattern="^[0-9-./-]{18}"
						oninvalid="this.setCustomValidity('Digite 14 números')"
						oninput="setCustomValidity('')"
					/>
				</div>
				<div class="form-group col-md-6">
					<label for="inputRazaoSocial" style="font-size: 12px"
						>Razão Social</label
					>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="text"
						class="form-control"
						id="inputRazaoSocial"
						v-model="client.nome"
						required
						pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-/\W|_/.'\s ]+$"
						oninvalid="this.setCustomValidity('Digite apenas letras')"
						oninput="setCustomValidity('')"
					/>
				</div>

				<div class="form-group col-md-4">
					<label for="fantasia" style="font-size: 12px"
						>Nome Fantasia</label
					>
					<input
						v-model="client.fantasia"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="text"
						class="form-control"
						id="fantasia"
						pattern="^[a-zA-Z0-9-/\W|_/ ]*$"
						oninvalid="this.setCustomValidity('Digite apenas letras ou números')"
						oninput="setCustomValidity('')"
					/>
				</div>
			</div>

			<div class="row gx-5">
				<div class="form-group col-md-2">
					<label for="inputCep" style="font-size: 12px">CEP</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						id="inputCep"
						v-model="client.cep"
						v-mask="'#####-###'"
						@keyup="onKeyup"
						@keydown="onKeydown($event)"
						required
						pattern="^[0-9--]{9}"
						oninvalid="this.setCustomValidity('Digite 8 números')"
						oninput="setCustomValidity('')"
					/>
				</div>

				<div class="form-group col-md-6">
					<label for="inputCep" style="font-size: 12px"
						>Endereço</label
					>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="text"
						class="form-control"
						id="inputEndereco"
						v-model="client.end"
						required
						pattern="^[a-zA-Z0-9-/\W|_/ ]*$"
						oninvalid="this.setCustomValidity('Digite apenas letras ou números')"
						oninput="setCustomValidity('')"
					/>
				</div>

				<div class="form-group col-md-1">
					<label for="inputCep" style="font-size: 12px"
						>Número</label
					>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="text"
						class="form-control"
						id="inputComplemento"
						v-model="client.num"
						pattern="^[a-zA-Z0-9-/\W|_/ ]*$"
						oninvalid="this.setCustomValidity('Digite apenas letras ou números')"
						oninput="setCustomValidity('')"
					/>
				</div>

				<div class="form-group col-md-3">
					<label for="inputCep" style="font-size: 12px"
						>Complemento</label
					>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="text"
						class="form-control"
						id="inputComplemento"
						v-model="client.comp"
						pattern="^[a-zA-Z0-9-/\W|_/ ]*$"
						oninvalid="this.setCustomValidity('Digite apenas letras ou números')"
						oninput="setCustomValidity('')"
					/>
				</div>
			</div>
			<div class="row gx-5">
				<div class="form-group col-md-2">
					<label for="inputCep" style="font-size: 12px">Estado</label>

					<select
						name="estados-brasil"
						v-model="client.estado"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						class="form-control"
						id="inputEstado"
						required
					>
						<option
							disabled
							selected
							value
							style="display: none"
						></option>
						<option value="AC">Acre</option>
						<option value="AL">Alagoas</option>
						<option value="AP">Amapá</option>
						<option value="AM">Amazonas</option>
						<option value="BA">Bahia</option>
						<option value="CE">Ceará</option>
						<option value="DF">Distrito Federal</option>
						<option value="ES">Espírito Santo</option>
						<option value="GO">Goiás</option>
						<option value="MA">Maranhão</option>
						<option value="MT">Mato Grosso</option>
						<option value="MS">Mato Grosso do Sul</option>
						<option value="MG">Minas Gerais</option>
						<option value="PA">Pará</option>
						<option value="PB">Paraíba</option>
						<option value="PR">Paraná</option>
						<option value="PE">Pernambuco</option>
						<option value="PI">Piauí</option>
						<option value="RJ">Rio de Janeiro</option>
						<option value="RN">Rio Grande do Norte</option>
						<option value="RS">Rio Grande do Sul</option>
						<option value="RO">Rondônia</option>
						<option value="RR">Roraima</option>
						<option value="SC">Santa Catarina</option>
						<option value="SP">São Paulo</option>
						<option value="SE">Sergipe</option>
						<option value="TO">Tocantins</option>
					</select>
				</div>

				<div class="form-group col-md-5">
					<label for="inputCep" style="font-size: 12px">Cidade</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="text"
						class="form-control"
						id="inputCidade"
						v-model="client.cidade"
						pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s ]+$"
						oninvalid="this.setCustomValidity('Digite apenas letras')"
						oninput="setCustomValidity('')"
						required
					/>
				</div>

				<div class="form-group col-md-5">
					<label for="inputCep" style="font-size: 12px">Bairro</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="text"
						class="form-control"
						id="inputBairro"
						v-model="client.bairro"
						pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s ]+$"
						oninvalid="this.setCustomValidity('Digite apenas letras')"
						oninput="setCustomValidity('')"
						required
					/>
				</div>
			</div>

			<div class="row gx-5">
				<div class="form-group col-md-4">
					<label for="inputCep" style="font-size: 12px"
						>Nome do Contato</label
					>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="text"
						class="form-control"
						id="inputContato"
						v-model="client.contato"
						pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s ]+$"
						oninvalid="this.setCustomValidity('Digite apenas letras')"
						oninput="setCustomValidity('')"
						required
					/>
				</div>
				<div class="form-group col-md-4">
					<label for="inputCep" style="font-size: 12px">E-mail</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="email"
						class="form-control"
						id="inputEmail"
						v-model="client.email"
						required
					/>
				</div>
				<div class="form-group col-md-4">
					<label for="inputCep" style="font-size: 12px"
						>Telefone</label
					>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						id="inputTelefone"
						v-model="client.fone"
						v-mask="'(##) #####-####'"
						required
						pattern="^[0-9-(-) ]*$"
						oninvalid="this.setCustomValidity('Digite apenas números')"
						oninput="setCustomValidity('')"
					/>
				</div>
			</div>
			<!-- <div class="row gx-5">
				<div class="form-group col-md-3">
					<label for="risco_interno" style="font-size: 12px"
						>Risco Interno</label
					>
					<b-select
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						v-model="client.internal_risk"
						class="form-control"
						required
						:options="[
							{
								value: 0,
								text: 'Nenhum',
							},
							{ value: 1, text: 'Em Dívida' },
						]"
					></b-select>
				</div>
				<div class="form-group col-md-3">
					<label for="risco_interno" style="font-size: 12px"
						>Risco Externo</label
					>
					<b-select
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						class="form-control"
						v-model="client.external_risk"
						required
						:options="[
							{
								value: 0,
								text: 'Nenhum',
							},
							{ value: 1, text: 'Negativado no Serasa' },
						]"
					></b-select>
				</div>
			</div> -->
			<div
				style="
					display: flex;
					flex-direction: row-reverse;
					margin-top: 30px;
					margin-bottom: 10px;
				"
			>
				<button
					type="submit"
					class="btn btn-primary py-2 px-4 me-3"
					style="
						background: #006992;
						border-radius: 8px;
						padding-left: 30px;
						padding-right: 30px;
						border: none
					"
				>
					AVANÇAR
				</button>
				<button
					type="reset"
					class="btn btn-secondary py-2 px-4"
					@click="
						() => {
							this.$emit('cancel');
						}
					"
					style="
						background: #7E7E7E;
						border-radius: 8px;
						padding-left: 30px;
						padding-right: 30px;
						margin-right: 20px;
						border: none;
					"
				>
					CANCELAR
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

import ClientService from "../../services/ClientService/ClientService";
export default {
	props: ["value"],
	mounted() {
		if (this.value) {
			this.$nextTick(() => {
				console.log(JSON.parse(JSON.stringify(this.value)));
				this.requested_client = true;
				this.client = this.value;
			});
		}
	},
	data() {
		return {
			client_service: new ClientService(),

			client: {
				cnpj: "",
				nome: "",
				fantasia: "",
				cep: "",
				end: "",
				comp: "",
				estado: "",
				cidade: "",
				bairro: "",
				contato: "",
				email: "",
				fone: "",
				internal_risk: 0,
				external_risk: 0,
			},
			size_limits: {
				cnpj: 14,
				nome: 60,
				fantasia: 60,
				cep: 8,
				end: 60,
				comp: 20,
				estado: 2,
				cidade: 30,
				bairro: 30,
				contato: 20,
				email: 200,
				fone: 11,
			},
			requested_client: false,
		};
	},
	watch: {
		"client.cnpj": {
			handler(value) {
				let v = value.replaceAll(/[^\d]/g, "").slice(0, 14);
				let f_v = v.replace(
					/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
					"$1.$2.$3/$4-$5"
				);
				while (!/\d/.test(f_v[f_v.length - 1]) && f_v.length > 0) {
					f_v = f_v.slice(0, f_v.length - 1);
				}

				if (v.length >= 14) {
					if (!this.requested_client) {
						this.not_found = false;
						this.client_service.cnpj(f_v).then((client) => {
							if (client.data) {
								delete client.data["codigo"];
								for (const key in client.data) {
									if (
										Object.hasOwnProperty.call(
											client.data,
											key
										)
									) {
										const element = client.data[key];
										let limit = this.size_limits[key];
										if (limit && element) {
											client.data[key] = element.slice(
												0,
												limit
											);
										}
									}
								}
								Object.assign(this.client, client.data);
							}
						});
						this.requested_client = true;
					}
				} else {
					this.requested_client = false;
					this.not_found = false;
				}

				this.client.cnpj = f_v;
			},
		},
		"client.cep": {
			handler() {
				var cep = this.client.cep.replace(/\D/g, "");
				if (!/^[0-9]{8}$/.test(cep)) return;

				axios
					.get("https://viacep.com.br/ws/" + cep + "/json/")
					.then((response) => response.data)
					.then((obj) => {
						this.client.end = this.client.end ? this.client.end : obj.logradouro;
						this.client.estado =  this.client.estado ? this.client.estado : obj.uf;
						this.client.cidade = this.client.cidade ? this.client.cidade : obj.localidade;
						this.client.bairro = this.client.bairro ? this.client.bairro : obj.bairro;
					});
			},
		},
	},
	methods: {
		createClient() {
			this.submmited = true;
			let client = Object.assign({}, this.client);
			var cep = client.cep.replace(/\D/g, "");
			var cnpj = client.cnpj.replace(/\D/g, "");
			var fone = client.fone.replace(/\D/g, "");

			client.cep = cep;
			client.cnpj = cnpj;
			client.fone = fone;

			if (client.contato.length > 20){
				Swal.fire({
					title: "Falha!",
					text: "O nome do contato precisa ter no máximo 20 caracteres",
					icon: "error",
					showCancelButton: false,
					confirmButtonColor: "#0bb4d4",
					cancelButtonColor: "#A9A9A9",
					confirmButtonText: "OK",
					cancelButtonText: "Cancelar",
				});
				return
			}

			if (client.comp.length > 20){
				Swal.fire({
					title: "Falha!",
					text: "O complemento do endereço precisa ter no máximo 20 caracteres",
					icon: "error",
					showCancelButton: false,
					confirmButtonColor: "#0bb4d4",
					cancelButtonColor: "#A9A9A9",
					confirmButtonText: "OK",
					cancelButtonText: "Cancelar",
				});
				return
			}

			if (client.bairro.length > 30){
				Swal.fire({
					title: "Falha!",
					text: "O bairro precisa ter no máximo 30 caracteres",
					icon: "error",
					showCancelButton: false,
					confirmButtonColor: "#0bb4d4",
					cancelButtonColor: "#A9A9A9",
					confirmButtonText: "OK",
					cancelButtonText: "Cancelar",
				});
				return
			}

			let promise = new Promise((resolve) => {
				resolve();
			});

			if (client.codigo) {
				promise = this.client_service
					.update(client)
					.catch(() => {
						Swal.fire({
							title: "Falha!",
							text: "Não foi possivel atualizar o cliente, verifique o formulário",
							icon: "error",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					})
					.then(() => {
						Swal.fire({
							title: "Atualizado!",
							text: "Cliente atualizado com sucesso",
							icon: "success",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					});
			} else {
				promise = this.client_service
					.create(client)
					.catch(() => {
						console.log("catch");
						Swal.fire({
							title: "Falha!",
							text: "Não foi possivel adicionar o cliente, verifique o formulário",
							icon: "error",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
						return
					})
					.then((res) => {
						client.codigo = res.data.codigo;
						Swal.fire({
							title: "Adicionado!",
							text: "Cliente adicionado com sucesso",
							icon: "success",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					});
			}
			promise.then(() => {
				this.$emit("added-client", client.codigo);
			});
			// console.log("Form submitted", client);
			// parent.document.getElementById("form-cliente").reset();
			this.client.cep = "";
			this.client.cnpj = "";
			this.client.fone = "";
			this.client.nome = "";
			this.client.fantasia = "";
			this.client.end = "";
			this.client.comp = "";
			this.client.estado = "";
			this.client.cidade = "";
			this.client.bairro = "";
			this.client.contato = "";
			this.client.email = "";
		},

		onKeydown: function (e) {
			if (
				// permite somente numeros
				(e.keyCode >= 48 && e.keyCode <= 57) ||
				(e.keyCode >= 96 && e.keyCode <= 105) ||
				// permite teclas lado direito, esquerdo, delete, backspace, tab e enter
				/^(8|9|13|46|37|39|17)$/.test(e.keyCode) ||
				// permite ctrl+ c,v,x,a,z
				(/^(67|86|88|65|90)$/.test(e.keyCode) && e.ctrlKey)
			)
				return;
			e.preventDefault();
			e.stopPropagation();
		},
		onKeyup: function () {
			var cep = this.client.cep.replace(/\D/g, "");
			if (!/^[0-9]{8}$/.test(cep)) return;

			axios
				.get("https://viacep.com.br/ws/" + cep + "/json/")
				.then((response) => response.data)
				.then((obj) => {
					this.client.end = obj.logradouro;
					this.client.estado = obj.uf;
					this.client.cidade = obj.localidade;
					this.client.bairro = obj.bairro;
				});
		},
	},
	computed: {
		formIsValid() {
			return this.client.cnpj && this.client.nome && this.client.fantasia;
		},
	},
};
</script>
