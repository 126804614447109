<template>
	<div class="font ajust-margin" style="margin-top: 80px; margin-right: 95px; margin-left: 95px;">
		<div style="background: #FFFFFF; box-shadow: 0px 10px 10px rgba(226, 236, 249, 0.5); padding-bottom: 50px; border-radius: 30px;  padding-left: 38px;  padding-right: 38px">
			<div style="padding-top: 28px; margin-bottom:30px" class="d-flex justify-content-between">
				<p class="title">
				{{ $route.params.id ? "Editar" : "Nova" }} Proposta
				</p>
				<div>
					<div class="container">
						<div>
							<router-link
								:to="{
									name: 'editProposta',
									params: { id: this.document.proposal },
								}"
							>
								<button class="light-button dark-text">
									Informações iniciais
								</button>
							</router-link>

							<button class="dark-button light-text">Documentos</button>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="d-flex justify-content-between align-items-center" style="margin-bottom: 30px;">
				<p class="subtitle_anexo">Deseja anexar um novo documento a essa proposta?</p>
				<button class="dark-button-anexo light-text" v-b-modal.addFile> Anexar </button>
			</div>

			<div>
				<table class="table table-sm mt-0">
					<thead>
						<tr style="border-bottom: 1px solid #EEEEEE;">
							<th scope="col">Data</th>
							<th scope="col">Vendedor Responsavel</th>
							<th scope="col">Titulo</th>
							<th scope="col">Arquivo</th>
							<th scope="col" width="20%"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(doc, i) in docs" :key="i" style="border-bottom: 1px solid #EEEEEE; padding-bottom: 20px;">
							<td>{{ doc.created_at }}</td>
							<td>
								{{ doc.consultant.first_name }}
							</td>
							<td>{{ doc.name }}</td>
							<td>
								<a :href="doc.file">{{ doc.name }}</a>
							</td>
							<td>
								<span
									class="material-icons-outlined"
									v-if="doc.status"
									v-b-modal.pauseProcess
									style="cursor: pointer"
								>
									pause
								</span>
								<span
									class="material-icons-outlined"
									@click="deleteDoc(i)"
									style="cursor: pointer"
								>
									delete
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="d-flex justify-content-end" style="margin-top: 60px;">
				<button
				type="submit"
				class="btn btn-secondary py-2"
				style="
					background: #7E7E7E;
					border-radius: 8px;
					padding-left: 30px;
					padding-right: 30px;
					margin-right: 20px;
					border: none;
				"
				@click="
					() => {
						this.$router.push('/propostas/');
					}
					"
				>
				Cancelar
				</button>
				<button
				type="submit"
				class="btn btn-secondary"
				style="
					background: #006992;
					border-radius: 8px;
					padding-left: 30px;
					padding-right: 30px;
					margin-right: 20px;
					border: none;
				"
				@click="startProposal()"
				>
				Transformar em pré-contrato
				</button>
			</div>
		</div>

		<b-modal
			id="addFile"
			size="dm"
			title="Adicionar Arquivo"
			style="color: rgb(161, 0, 12) !important"
			:hide-footer="true"
		>
			<AddFile v-model="document" :modal-id="'addFile'" @modalClosed="attachDocument"></AddFile>
		</b-modal>
		<b-modal
			id="approvalProcess"
			size="dm"
			title="Confirma Processo de Aprovação"
			style="color: rgb(161, 0, 12) !important"
			@ok="handleOk"
		>
			<div>Deseja iniciar a aprovação para</div>
			<div v-if="doc">
				<table>
					<tr>
						<td>{{ doc.created_at }}</td>
						<td>
							{{ doc.consultant.first_name }}
							{{ doc.consultant.last_name }}
						</td>
						<td>{{ doc.name }}</td>
						<td>
							<a :href="doc.file">{{ doc.name }}</a>
						</td>
					</tr>
				</table>
			</div>
			<div v-if="current_doc">E parar a aprovação para</div>
			<div v-if="current_doc">
				<table>
					<tr>
						<td>{{ current_doc.created_at }}</td>
						<td>
							{{ current_doc.consultant.first_name }}
							{{ current_doc.consultant.last_name }}
						</td>
						<td>{{ current_doc.name }}</td>
						<td>
							<a :href="current_doc.file">{{
								current_doc.name
							}}</a>
						</td>
					</tr>
				</table>
			</div>
		</b-modal>
		<b-modal
			id="pauseProcess"
			size="dm"
			title="Confirma Pausar Processo de Aprovação"
			style="color: rgb(161, 0, 12) !important"
			@ok="handlePauseOk"
		>
			<div>Deseja pausar o processo de aprovação para</div>
			<div v-if="current_doc">
				<table>
					<tr>
						<td>{{ current_doc.created_at }}</td>
						<td>
							{{ current_doc.consultant.first_name }}
							{{ current_doc.consultant.last_name }}
						</td>
						<td>{{ current_doc.name }}</td>
						<td>
							<a :href="current_doc.file">{{
								current_doc.name
							}}</a>
						</td>
					</tr>
				</table>
			</div>
		</b-modal>
		<b-modal
			id="deleteDocument"
			size="dm"
			title="Removendo arquivo"
			style="color: rgb(161, 0, 12) !important"
			@ok="handleDeleteOk"
		>
			<div>Tem certeza que deseja remover o arquivo</div>
			<div v-if="doc">
				<table>
					<tr>
						<td>{{ doc.created_at }}</td>
						<td>
							{{ doc.consultant.first_name }}
							{{ doc.consultant.last_name }}
						</td>
						<td>{{ doc.name }}</td>
						<td>
							<a :href="doc.file">{{ doc.name }}</a>
						</td>
					</tr>
				</table>
			</div>
		</b-modal>
	</div>
</template>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"

th
  font-family: 'Poppins'
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 21px
  letter-spacing: -0.01em
  color: #B5B7C0


td
  font-family: 'Poppins'
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 21px
  letter-spacing: -0.01em
  color: #292D32
  padding-bottom: 16px !important
  padding-top: 16px !important

.dark-button-anexo
  background: #006992
  border-radius: 8px
  padding: 14px 40px

.table th, .table td
  border: none

.subtitle_anexo
  font-family: 'Poppins', sans-serif
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 33px
  letter-spacing: -0.01em
  color: #000000
  margin-top: 30px

.title
  font-family: 'Poppins', sans-serif
  font-style: normal
  font-weight: 600
  font-size: 20px
  line-height: 33px
  letter-spacing: -0.01em
  color: #000000

.light-button
  background: #F9FBFF
  border: 1px solid #E7E7E7
  border-radius: 8px 0px 0px 8px

.dark-button
  background: #6DA1B5
  border-radius: 0px 8px 8px 0px

.btn
  font-family: 'Poppins'
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 24px
  /* identical to box height */
  text-align: center
  letter-spacing: -0.01em
  color: #FFFFFF
  text-transform: capitalize


.container
  padding: 0px
  margin: 0px
  display: flex
  justify-content: space-between

.dark-button:focus
  outline-style: none
.light-button:focus
  outline-style: none
</style>

<style lang="sass">
.crm-input
  background: #f5f4fc 0% 0% no-repeat padding-box
  border-radius: 2px
  opacity: 1
</style>

<script>
import ClientService from "../../services/ClientService/ClientService";
import DocumentsService from "../../services/DocumentsService/DocumentsService";
import ProposalService from "../../services/ProposalsService/ProposalsService";

import AddFile from "../../components/Propostas/AddFile.vue";
import UserService from "../../services/UserService/UserService";

export default {
	created() {
		this.document.proposal = this.$route.params.id;
		this.reload();
	},
	methods: {

		startProposal() {
			this.proposal_service.update({ id: this.document.proposal, started: 1 }).then(
				preContrato => {
					console.log(preContrato)
					this.$router.push(`/PreContratos/${preContrato.data.id}`)
				}
			);
		},
		attachDocument() {
			let doc = new FormData();
			for (const key in this.document) {
				if (Object.hasOwnProperty.call(this.document, key)) {
					const element = this.document[key];
					console.log(key);
					console.log(element);
					doc.append(key, element);
				}
			}

			this.documents_service.create(doc).then(() => {
				this.reload();
				Object.assign(this.document, { name: "", file: null });
			});
		},
		reload() {
			this.documents_service
				.list({ params: { proposal: this.document.proposal } })
				.then((resp) => {
					this.docs = this.load_docs(resp.data.results);
				});
		},
		load_docs(docs) {
			for (let index = 0; index < docs.length; index++) {
				const element = docs[index];
				if (
					Object.hasOwnProperty.call(this.users, element.consultant)
				) {
					element.consultant = this.users[element.consultant];
				} else {
					if (element.consultant) {
						this.user_service
							.retrieve(element.consultant)
							.then((resp) => {
								this.users[element.consultant] = resp.data;
								element.consultant =
									this.users[element.consultant];
							});
					}
				}
			}
			return docs;
		},
		deleteDoc(i) {
			this.doc = this.docs[i];
			this.$root.$emit("bv::show::modal", "deleteDocument", "#btnShow");
		},
		handleDeleteOk() {
			this.documents_service.destroy(this.doc.id).then(() => {
				this.reload();
			});
		},
		async handleOk() {
			if (this.current_doc) {
				await this.documents_service.update({
					id: this.current_doc.id,
					status: false,
				});
			}
			await this.documents_service.update({
				id: this.doc.id,
				status: true,
			});

			this.reload();
		},
		handlePauseOk() {
			this.documents_service
				.update({
					id: this.current_doc.id,
					status: false,
				})
				.then(() => {
					this.reload();
				});
		},
	},
	data() {
		return {
			client_service: new ClientService(),
			proposal_service: new ProposalService(),
			documents_service: new DocumentsService(),
			user_service: new UserService(),
			document: {
				proposal: null,
				name: "",
				file: "",
			},
			doc: false,
			docs: [],
			users: {},
		};
	},
	components: { AddFile },
	computed: {
		current_doc() {
			for (let index = 0; index < this.docs.length; index++) {
				const element = this.docs[index];
				if (element.status) {
					return element;
				}
			}
			return false;
		},
	},
};
</script>
