import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { router } from "./routes/router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import vSelect from "vue-select";
import vuetify from './plugins/vuetify'
import ElementUI from 'element-ui';

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";


import 'element-ui/lib/theme-chalk/index.css';

import "material-icons/iconfont/material-icons.css";
import "vue-select/dist/vue-select.css";

Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);
Vue.use(ElementUI);


Vue.use(require("../node_modules/vue-truncate"));

Vue.component("vue-select", vSelect);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
