import Swal from "sweetalert2";
import apiClient from "../apiClient";
import baseService from "../baseService";
/*
Site Service
*/
const SITES_ENDPOINT = "/api/sites/";
export default class SiteService extends baseService {
	constructor() {
		// super(SITES_ENDPOINT, "site");
		super(SITES_ENDPOINT, "site", "codigo"); // tava chamando "ID" como sendo a chave, sendo que no back a chave primaria é "codigo"
	}

	// mirror_sites(obj) {
	// 	return apiClient
	// 		.patch(
	// 			`${this.endpoint}${obj.codigo}/` +
	// 				"mirror_site_on_product_proposals/",
	// 			{ proposal_id: obj.contrato }
	// 		)
	// 		.catch((err) => {
	// 			console.log(`EDIT ${this.model_name} ERROR`);
	// 			console.log(err);
	// 			Swal.fire({
	// 				icon: "error",
	// 				title: "Oops...",
	// 				text: err,
	// 			});
	// 		});
	// }

	multiply_site(obj, amount) {
		return apiClient
			.post(`${this.endpoint}${obj.site.codigo}/` + "multiply_site/", {
				product_proposal_id: obj.product.id,
				amount: amount,
			})
			.catch((err) => {
				console.log(`EDIT ${this.model_name} ERROR`);
				console.log(err);
				Swal.fire({
					title: "Erro!",
					text: "Não foi possível multiplicar os endereços, produtos insuficientes",
					icon: "error",
					showCancelButton: false,
					confirmButtonColor: "#0bb4d4",
					cancelButtonColor: "#A9A9A9",
					confirmButtonText: "OK",
					cancelButtonText: "Cancelar",
				});
			});
	}

	add_product_proposal(obj, productProposalId) {
		return apiClient
			.patch(`${this.endpoint}${obj.codigo}/` + "add_product_proposal/", {
				proposal_id: productProposalId,
			})
			.catch((err) => {
				console.log(`EDIT ${this.model_name} ERROR`);
				console.log(err);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: err,
				});
			});
	}
}
