import baseService from "../baseService";

/*
Consultants Service
*/
const CONSULTANT_ENDPOINT = "/api/consultants/";
export default class ConsultantsService extends baseService {

  constructor() {
    super(CONSULTANT_ENDPOINT, "consultant")
  }
}
