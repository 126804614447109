<template>
  <div class="font ajust-margin" style="margin-top: 80px; margin-left: 95px;">
    <div style="background: #FFFFFF; box-shadow: 0px 10px 10px rgba(226, 236, 249, 0.5); border-radius: 30px; padding-left: 38px;  padding-right: 38px">
      <div style="padding-top: 28px; margin-bottom:60px" class="d-flex justify-content-between">
        <p class="title">
          {{ $route.params.id ? "Editar" : "Novo" }} Pré-contrato
        </p>
      </div>

      <div class="stepper row">
        <div
          class="step col-3"
          :class="{
            active: this.current_step == 1,
            completed: this.current_step > 1,
          }"
        >
          <div
            class="step-label"
            @click="
              () => {
                this.current_step = 1;
                this.$router.push({
                  name: 'editPrecontrato',
                  params: { id: this.$route.params.id, step: 1 },
                });
              }
            "
            :style="{ cursor: this.current_step != 1 ? 'pointer' : '' }"
          ></div>
          <div class="step-description">
            Dados <br />
            Básicos
          </div>
        </div>
        <div
          class="step col-3"
          :class="{
            active: this.current_step == 2,
            completed: this.current_step > 2,
          }"
        >
          <div
            @click="
              () => {
                if (this.$route.params.id) {
                  this.current_step = 2;
                  this.$router.push({
                    name: 'editPrecontrato',
                    params: { id: this.$route.params.id, step: 2 },
                  });
                }
              }
            "
            class="step-label"
            :style="{
              cursor: this.current_step != 2 && $route.params.id ? 'pointer' : '',
            }"
          ></div>

          <div class="step-description">Contrato</div>
        </div>
        <div
          class="step col-3"
          :class="{
            active: this.current_step == 3,
            completed: this.current_step > 3,
          }"
        >
          <div
            @click="
              () => {
                if (this.$route.params.id) {
                  this.current_step = 3;
                  this.$router.push({
                    name: 'editPrecontrato',
                    params: { id: this.$route.params.id, step: 3 },
                  });
                }
              }
            "
            class="step-label"
            :style="{
              cursor: this.current_step != 3 && $route.params.id ? 'pointer' : '',
            }"
          ></div>

          <div class="step-description">Detalhes do<br/>Contrato</div>
        </div>
        <div
          class="step col-3"
          :class="{
            active: this.current_step == 4,
            completed: this.current_step > 4,
          }"
        >
          <div
            @click="
              () => {
                if (this.$route.params.id) {
                  this.current_step = 4;
                  this.$router.push({
                    name: 'editPrecontrato',
                    params: { id: this.$route.params.id, step: 4 },
                  });
                }
              }
            "
            class="step-label"
            :style="{
              cursor: this.current_step != 4 && $route.params.id ? 'pointer' : '',
            }"
          ></div>
          <div class="step-description">
            Operação e <br />
            Endereços
          </div>
        </div>

      </div>

      <Step1Vue
      v-model="proposal"
      v-if="current_step == 1"
      :key="updated"
      @next="nextStep"
      route="this.$route"
    ></Step1Vue>
    <Step2Vue
      v-model="proposal"
      v-if="current_step == 2"
      :key="updated"
      @next="nextStep"
      @cancel="previousStep"
    ></Step2Vue>
    <Step3Vue
      v-model="proposal"
      v-if="current_step == 4"
      :key="updated"
      @cancel="previousStep"
    >
    </Step3Vue>
    <Step4Vue
      v-model="proposal"
      v-if="current_step == 3"
      :key="updated"
      @next="nextStep"
      @cancel="previousStep"
    >
    </Step4Vue>

      </div>
  </div>
</template>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"

.step-description
  font-size: 21px

.input-text
  font: var(--unnamed-font-style-normal) normal 600 13px/66px Montserrat
  letter-spacing: var(--unnamed-character-spacing-0)
  text-align: left
  font: normal normal 600 13px/66px Montserrat
  letter-spacing: 0px
  color: #ACACAC
  opacity: 1
</style>

<style lang="sass">
.title 
  font-family: 'Poppins'
  font-style: normal
  font-weight: 600
  font-size: 20px
  line-height: 33px
  letter-spacing: -0.01em
  color: #000000

.stepper
  font-family: var(--fnt)
  font-size: var(--fnt-size)
  position: relative
  counter-reset: step 0
  width: 100%
  margin: 2rem 0
  display: flex
  flex-flow: row nowrap
  gap: 0
  justify-content: space-around
  align-items: flex-start

.stepper > .step
  position: relative
  counter-increment: step
  width: 100%

.stepper > .step::before
  position: absolute
  content: ""
  width: 100%
  height: 2px
  background: var(--bs-gray-200)
  left: 0
  top: 1.1rem
  color: #EFF2FF

.stepper > .step > .step-label
  display: flex
  align-items: center
  justify-content: center
  padding-top: -50px
  background: #FFFFFF
  border: 4px solid #EFF2FF
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25)
  color: #EFF2FF

.stepper > .step > .step-label::before
  content: counter(step)

.stepper > .step.active::before
  background: #006992
  
.stepper > .step.active > .step-label
  display: flex
  align-items: center
  justify-content: center
  padding-top: -50px
  background: #FFFFFF
  border: 4px solid #006992
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25)
  color: #006992

.stepper > .step.completed::before
  background: var(--bs-pink)

.stepper > .step.completed > .step-label
  background: #FFFFFF
  border: 4px solid #006992
  color: #006992

.stepper > .step > .step-description
  font-size: x-small
  color: var(--bs-gray-500)
  margin-top: 0.5rem
  overflow-wrap: break-word
  hyphens: auto
  text-align: center

.stepper > .step.active > .step-description,
.stepper > .step.completed > .step-description
  color: var(--bs-gray-800)

.stepper > .step.active > .step-description
  font-weight: bold

.crm-input
  background: #f5f4fc 0% 0% no-repeat padding-box
  border-radius: 2px
  opacity: 1

td select

  height: 35px
  border-color: #E5E5E5
  padding-left: 10px
  width: 150px
  border-radius: 5px
  outline: 0

.select-td
  height: 35px
  border-color: #E5E5E5
  padding-left: 10px
  width: 150px
  border-radius: 5px
  outline: 0

.input-td

  border-color: #E5E5E5
  padding-left: 10px
  width: 150px
  border-radius: 5px
  background: #f5f4fc 0% 0% no-repeat padding-box
  border-radius: 5px
  width: 70px
  height: 35px
  outline: 0
</style>

<script>
import Step1Vue from "../../components/PreContrato/Step1.vue";
import Step2Vue from "../../components/PreContrato/Step2.vue";
import Step3Vue from "../../components/PreContrato/Step3.vue";
import Step4Vue from "../../components/PreContrato/Step4.vue";
import ProposalService from "../../services/ProposalsService/ProposalsService";
import ClientService from "../../services/ClientService/ClientService";
import ConsultantService from "../../services/ConsultantService/ConsultantService";
import { DateTime } from "luxon";

export default {
  components: {
    Step1Vue,
    Step2Vue,
    Step3Vue,
    Step4Vue
  },
  created() {
    let id = this.$route.params.id;
    if (id) {
      this.loadProposal(id);
    }

    this.current_step = this.$route.params.step ? this.$route.params.step : 1;
  },
  data() {
    window.lDt = DateTime;
    let now = DateTime.now();
    let a_month = now.plus({ months: 1 });

    return {
      current_step: 1,
      proposal: {
        client: {},
        proposal_value: 0,
        commissions: [{}],
        start_date: now.toMillis(),
        end_date: a_month.toMillis(),
        started: 1,
      },
      proposal_service: new ProposalService(),
      client_service: new ClientService(),
      consultant_service: new ConsultantService(),
      updated: false,
      consultants: [],
    };
  },
  methods: {
    redirectToStep1() {
      this.current_step = 1;
    },
    redirectToStep2() {
      this.current_step = 2;
    },
    redirectToStep3() {
      this.current_step = 3;
    },
    nextStep(id) {
      this.current_step = Number(this.current_step) + 1;
      this.navigate(id);
    },
    previousStep() {
      this.current_step = Number(this.current_step) - 1;
      this.navigate();
    },
    navigate(id) {
      this.loadProposal(id ?? this.$route.params.id);
      this.$router.push({
        name: "editPrecontrato",
        params: { id: id ?? this.$route.params.id, step: this.current_step },
      });
    },
    loadProposal(id) {
      this.proposal_service.retrieve(id).then(({ data }) => {
        Object.assign(this.proposal, data);
        this.proposal.start_date = DateTime.fromISO(
          this.proposal.start_date
        ).toMillis();
        this.proposal.end_date = DateTime.fromISO(
          this.proposal.end_date
        ).toMillis();
        this.client_service.retrieve(data.client).then(({ data }) => {
          this.proposal.client = data;
          this.updated = !this.updated;
        });
        console.log(this.proposal)

        let consultants_list = new Set(
          this.proposal.commissions.map((p) => p.consultant)
        );
        this.consultant_service
          .detailed_list(Array.from(consultants_list))
          .then((r) => {
            this.consultants = r;
            this.proposal.commissions.forEach((elm) => {
              this.$set(elm, "consultant", {
                  nome: this.consultants[elm.consultant].nome,
                  text: this.consultants[elm.consultant].nome,
                  value: this.consultants[elm.consultant]
                })
            })
          });
      });
    },
  },
};
</script>
