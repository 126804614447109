import baseService from "../baseService";

/*
ApprovalSteps Service
*/
const APPROVALSTEPS_ENDPOINT = "/api/approval_steps/";
export default class ApprovalStepsService extends baseService {
	constructor() {
		super(APPROVALSTEPS_ENDPOINT, "ApprovalSteps");
	}
}
