import baseService from "../baseService";

/*
Product Service
*/
const PRODUCTGROUPS_ENDPOINT = "/api/productgroups/";
export default class ProductGroupService extends baseService {

  constructor() {
    super(PRODUCTGROUPS_ENDPOINT, "productgroups")
  }
}
