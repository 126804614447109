import Swal from "sweetalert2";
import apiClient from "../apiClient";
import baseService from "../baseService";

/*
Documents Service
*/
const DOCUMENT_ENDPOINT = "/api/documents/";
export default class DocumentsService extends baseService {
	constructor() {
		super(DOCUMENT_ENDPOINT, "document");
	}

	public(config = { params: {} }) {
		//passar dentro do list como parametro o search, ver linha 243 de addDocument
		return apiClient.get(this.endpoint + "public/", config).catch((err) => {
			console.log(err);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.data.message,
			});
		});
	}
}
