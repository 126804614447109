import axios from "axios";
import AuthService from "./AuthService/AuthService";
import { getCookie } from "../utils/util";
import Constants from "../core/constants/Constants";

const HTTP_UNAUTHORIZED = 401;

let apiClient = axios.create({
  baseURL: Constants.BASE_URL,
});

apiClient.interceptors.request.use((config) => {
  //Assign Auth Header
  Object.assign(config.headers, {
    Authorization: `Bearer ${getCookie("access")}`,
  })
  return config;
});

apiClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
    let auth_service = new AuthService();
    // If the request fails 
    return auth_service.refreshToken().then(() => {
      console.log(error.config)
      return apiClient.request(error.config);
    }).catch(() => {
      auth_service.logout()
    })
  } else {
    return Promise.reject(error);
  }
});

export default apiClient