<template>
	<div class="font" overflow-hidden>
		<b-modal
			scrollable="true"
			size="lg"
			id="add-geral-document"
			title="Documentos Gerais"
			hide-footer="true"
		>
			<button
				id="novoArquivo"
				class="btn-proposta"
				style="
					height: 30px;
					margin-top: 5px;
					float: right;
					margin-right: 15px;
				"
				@click="exibe"
			>
				NOVO ARQUIVO
			</button>
			<b-row id="escolha" class="hideMe">
				<div
					style="
						width: 100%;
						display: flex;
						align-items: center;
						margin-left: 10px;
						margin-right: 35px;
					"
				>
					<b-col id="etapa">
						<span class="input-title"> Escolha um Arquivo </span>
						<input
							style="
								border-radius: 2px;
								width: 450px;
								padding-bottom: 5px;
								background-color: #f5f4fc;
							"
							type="file"
							class="form-control"
							id="document-name"
							@change="onChange"
						/>
					</b-col>
					<div style="margin-top: 21px">
						<b-button
							id="secondButton"
							size="sm"
							class="btn-reprove hideMe"
							@click="esconde"
						>
							<span>CANCELAR</span>
						</b-button>
						<b-button
							id="aprovar"
							size="sm"
							class="btn-approve hideMe"
							@click="attachDocument()"
						>
							SALVAR
						</b-button>
					</div>
				</div>
			</b-row>
			<div style="margin-bottom: 10px">
				<b-row style="margin-top: 10px; width: 100%">
					<table
						class="table table-sm mt-3"
						style="
							border: 1px solid #ced4da;
							height: 38px;
							width: 100%;
							margin-left: 25px;
						"
					>
						<thead>
							<tr
								style="
									background: #f9f9f9 0% 0% no-repeat
										padding-box;
									border: 1px solid #e1e1e1;
									border-radius: 4px;
									opacity: 1;
								"
							>
								<th style="padding-left: 11px">Data</th>
								<th>Autor do Upload</th>

								<th>Arquivo</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(doc, i) in docs" :key="i">
								<td>{{ doc.created_at | format_date }}</td>
								<td>
									{{ doc.consultant.first_name }}
								</td>

								<td>
									<a :href="doc.file">{{ doc.name }}</a>
								</td>

								<td>
									<span
										class="material-icons-outlined"
										@click="deleteDoc(doc.id)"
										style="
											margin-top: 10px;
											color: #384364;
											cursor: pointer;
											font-size: 16px;
										"
									>
										delete
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</b-row>
			</div>
		</b-modal>
		
		<div class="d-flex">
			<div class="menu">
				<div class="d-flex" style="margin-top: 28px; margin-bottom: 54px; margin-left: 28px; margin-right: 80px;" 
				:class="{ center: isCollapse == true }">
					<img src="/assets/imgs/icon-logo.svg" @click="collapse()"  width="37px">
					<img src="/assets/imgs/logo.svg" width="150px" style="padding-left: 8px;" :class="{ sumir: isCollapse == true }">
				</div>
			
				<el-menu
					default-active="2" 
					class="el-menu-vertical-demo" 
					@open="handleOpen" 
					@close="handleClose" 
					:collapse="isCollapse">

					<el-menu-item index="2" :class="{ center: isCollapse == true }" @click="navegar('/')">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<i class="material-symbols-rounded">
								insert_chart
								</i>
								<span class="font" style="padding-left: 8px;">Dashboard</span>
							</div>
							
							<i class="material-symbols-rounded" :class="{ sumir: isCollapse == true }">
							chevron_right
							</i>
						</div>
					</el-menu-item>

					<el-menu-item index="3" :class="{ center: isCollapse == true }" @click="navegar('/clientes')">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<i class="material-symbols-rounded">
									account_box
								</i>
								<span class="font" style="padding-left: 8px;">Clientes</span>
							</div>
							<i class="material-symbols-rounded" :class="{ sumir: isCollapse == true }">
							chevron_right
							</i>
						</div>
					</el-menu-item>

					<el-menu-item index="4" :class="{ center: isCollapse == true }" @click="navegar('/propostas')">
						<div class="d-flex justify-content-between align-items-center">
							<div>
							<i class="material-symbols-rounded">
								note_add
							</i>
							<span class="font" style="padding-left: 8px;">Propostas</span>
							</div>
							<i class="material-symbols-rounded" :class="{ sumir: isCollapse == true }">
							chevron_right
							</i>
						</div>
					</el-menu-item>

					<el-menu-item index="5" :class="{ center: isCollapse == true }"  @click="navegar('/PreContratos')">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<i class="material-symbols-rounded">
									assignment
								</i>
								<span class="font" style="padding-left: 8px;">Pré-Contratos</span>
							</div>
							<i class="material-symbols-rounded" :class="{ sumir: isCollapse == true }">
							chevron_right
							</i>
						</div>
					</el-menu-item>

					<el-menu-item index="6" :class="{ center: isCollapse == true }"  @click="navegar('/approvals')">
						<div class="d-flex justify-content-between align-items-center">
							<div>
							<i class="material-symbols-rounded">
								check_circle
							</i>
							<span class="font" style="padding-left: 8px;">Aprovações</span>
							</div>
							<i class="material-symbols-rounded" :class="{ sumir: isCollapse == true }">
							chevron_right
							</i>
						</div>
					</el-menu-item>

					<el-menu-item index="7" :class="{ center: isCollapse == true }">
						<div v-b-modal.add-geral-document>
							<i class="material-symbols-rounded">
								description
							</i>
							<span class="font" style="padding-left: 8px;">Documentos</span>
						</div>
					</el-menu-item>


				
				
			</el-menu>
			</div>
			<div style="width: 100vw !important; background-color: #FAFBFF;">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import $ from "jquery";
import { DateTime } from "luxon";
import AuthService from "../../services/AuthService/AuthService";
import DocumentsService from "../../services/DocumentsService/DocumentsService";
import UserService from "../../services/UserService/UserService";

export default {
	name: "NavBar",
	data() {
		return {
			isCollapse: false,
			userService: new UserService(),
			documents_service: new DocumentsService(),
			user_service: new UserService(),
			loggedUser: {},
			active_el: 0,
			document: {
				name: "",
				file: "",
			},

			doc: false,
			docs: [],
			users: {},
		};
	},
	filters: {
		format_date(date) {
			let o_date = DateTime.fromISO(date);
			return o_date.toFormat("dd/MM/y");
		},
	},

	mounted() {
		this.reloadDocument();
		this.getLoggedUser();
	},

	methods: {
		getLoggedUser() {
			this.userService.me().then((resp) => {
				console.log(resp);
				this.loggedUser = resp.data;
			});
		},
		
		navegar(param){
			this.$router.push(param)
		},

		onChange(e) {
			//popula os inputs
			const fileInput = document
				.getElementById("document-name")
				.value.replace(/.*(\/|\\)/, "")
				.replace(/\.[^/.]+$/, "");

			for (const file of e.target.files) {
				this.document.file = file;
			}
			// document.getElementById("document-name").innerHTML = fileInput;
			this.document.name = fileInput;
		},

		openModal(proposalId) {
			this.document.proposal = proposalId;
			// this.reloadDocument();
			this.$root.$emit("bv::show::modal", "add-document", "#btnShow");
		},
		attachDocument() {
			let doc = new FormData();
			for (const key in this.document) {
				if (Object.hasOwnProperty.call(this.document, key)) {
					const element = this.document[key];
					console.log(element);
					console.log(typeof element);
					doc.append(key, element);
				}
			}

			this.documents_service.create(doc).then(() => {
				this.reloadDocument();
				Object.assign(this.document, { name: "", file: null });
			});

			document.getElementById("document-name").value = "";
		},
		reloadDocument() {
			this.documents_service.public({ params: {} }).then((resp) => {
				this.docs = this.load_docs(resp.data.results);
			});
		},
		load_docs(docs) {
			for (let index = 0; index < docs.length; index++) {
				const element = docs[index];
				if (
					Object.hasOwnProperty.call(this.users, element.consultant)
				) {
					element.consultant = this.users[element.consultant];
				} else {
					if (element.consultant) {
						this.user_service
							.retrieve(element.consultant)
							.then((resp) => {
								this.users[element.consultant] = resp.data;
								element.consultant =
									this.users[element.consultant];
							});
					}
				}
			}
			return docs;
		},
		deleteDoc(docId) {
			console.log(docId);
			this.documents_service.destroy(docId).then(({ data }) => {
				console.log(data);
				this.reloadDocument();
			});
		},
		handleDeleteOk() {
			this.documents_service.destroy(this.doc.id).then(() => {
				this.reloadDocument();
			});
		},
		async handleOk() {
			if (this.current_doc) {
				await this.documents_service.update({
					id: this.current_doc.id,
					status: false,
				});
			}
			await this.documents_service.update({
				id: this.doc.id,
				status: true,
			});

			this.reload();
		},
		handlePauseOk() {
			this.documents_service
				.update({
					id: this.current_doc.id,
					status: false,
				})
				.then(() => {
					this.reload();
				});
		},
		exibe() {
			$("#novoArquivo").addClass("hideMe");
			$("#escolha").removeClass("hideMe");
			$("#aprovar").removeClass("hideMe");

			$("#secondButton").removeClass("hideMe");
		},
		esconde() {
			$("#novoArquivo").removeClass("hideMe");
			$("#escolha").addClass("hideMe");
			$("#aprovar").addClass("hideMe");

			$("#secondButton").addClass("hideMe");
		},

		collapse() {
			this.isCollapse = !this.isCollapse;

		},

		logout() {
			new AuthService().logout();
		},

		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.btn:focus,
.btn.focus {
	outline: 0;
	box-shadow: none;
}


.v-btn__content {
	color: inherit;
	display: inherit;
	line-height: normal;
	text-decoration: none;
}

.sumir{
	display: none;
}

.menu{
	background: #FFFFFF;
	box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
	height: 100vh;
}
.el-menu-item.is-active{
    background: #006992;
	border-radius: 8px;
	color: white !important;
}

.el-menu-item:focus{
	background: #006992;
	border-radius: 8px;
	color: white !important;
}

.el-menu-item:hover {
    background: #006992;
	border-radius: 8px;
	color: white !important;
}

.el-menu-item.is-active {
    color: #9197B3;
}

.el-menu-item{
	padding-left: 11px !important;
	padding-right: 11px !important;
	border-radius: 8px;
	color: #9197B3;
	width: 100%;
}
.center{
	display: flex !important;
    justify-content: center;
}

.el-menu {
    border-right: none;
	margin-right: 28px !important;
	margin-left: 28px !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 80%;
	min-height: 400px;
}

.font {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */

	letter-spacing: -0.01em;
}



.sair {
	font: var(--unnamed-font-style-normal) normal bold 10px/11px Montserrat;
	color: var(--unnamed-color-384364);
	text-align: center;
	font: normal normal bold 10px/11px Montserrat;
	letter-spacing: 0.25px;
	color: #384364;
	text-transform: uppercase;
	opacity: 0.49;
	border: 1px solid #eaecef;
	border-radius: 100px;
	cursor: pointer;
	padding: 8px 24px;
}

.material-icons {
	font-size: 22px;
}

.texto {
	font-weight: bold;
	color: #384364;
}
.pages {
	padding-left: 19px;
	font-weight: bold;
	color: #384364;
	opacity: 1;
	font-size: 13px;
	margin-top: 2px;
}

.btn-reprove {
	background-color: #f25d5d;
	margin-right: 20px;
	width: 114px;
	height: 30px;
	border-radius: 150px;
	border: none;
	font-size: 11px;

	font-family: "Montserrat", sans-serif;
	opacity: 1;
}

.btn-reprove:hover {
	background-color: #f25d5d;
	margin-right: 20px;
	width: 114px;
	height: 30px;
	border-radius: 150px;
	border: none;
	font-size: 11px;

	font-family: "Montserrat", sans-serif;
	opacity: 1;
}
.btn-approve {
	background-color: #0bb4d4;
	width: 114px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 30px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}

.btn-approve:hover {
	background-color: #0bb4d4;
	width: 114px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 30px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}
.btn-approve:focus {
	background-color: #0bb4d4;
	width: 120px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 30px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}
td {
	text-align: center;
}
th {
	text-align: center;
}
::-webkit-file-upload-button {
	display: none;
}
</style>
