import { render, staticRenderFns } from "./NovoPreContrato.vue?vue&type=template&id=37d2e8c3&scoped=true&"
import script from "./NovoPreContrato.vue?vue&type=script&lang=js&"
export * from "./NovoPreContrato.vue?vue&type=script&lang=js&"
import style0 from "./NovoPreContrato.vue?vue&type=style&index=0&id=37d2e8c3&prod&scoped=true&lang=sass&"
import style1 from "./NovoPreContrato.vue?vue&type=style&index=1&id=37d2e8c3&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d2e8c3",
  null
  
)

export default component.exports