import baseService from "../baseService";

/*
ProductProposal Service
*/
const PRODUCTPROPOSALS_ENDPOINT = "/api/product-proposals/";
export default class ProductProposalService extends baseService {

  constructor() {
    super(PRODUCTPROPOSALS_ENDPOINT, "product-proposal")
  }
}
