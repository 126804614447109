<template>
	<div class="font ajust-margin" style="margin-top: 4%; margin-bottom: 4%">
		<b-navbar
			toggleable="sm"
			type="light"
			variant="white"
			style="margin-bottom: -1%; margin-left: -1%"
		>
			<b-navbar-brand style="">
				<p class="page_init mb-0">PRÉ-CONTRATO</p>
				<p class="title" style="font-size: 20px">
					Editar Item de Pré-Contrato
				</p>
			</b-navbar-brand>

			<!-- Right aligned nav items -->
		</b-navbar>
		<hr />
		<div class="row">
			<div class="form-group col-sm-12 col-md-4">
				<label
					for=""
					style="font-size: 16px; font-weight: bold; color: #878ea4"
					>Tipo de Equipamento</label
				>

				<b-select
					id="lista"
					class="form-control"
					required
					style="
						background: #f5f4fc 0% 0% no-repeat padding-box;
						border-radius: 2px;
						opacity: 1;
						text-transform: uppercase;
						color: #c0c0c0;
					"
					v-model="selected_device"
					:options="devices_options"
					disabled
				>
				</b-select>
			</div>
		</div>
		<impressora-scanner-vue
			v-if="selected_device == 'impressoraScanner'"
			v-model="product"
			@added-product="
				() => {
					this.$router.push({
						name: 'editPrecontrato',
						params: { id: $route.params.id, step: 2 },
					});
				}
			"
			@cancel="cancelled"
			:key="this.updated"
		></impressora-scanner-vue>
		<mao-de-obra-vue
			v-if="selected_device == 'maodeObra'"
			v-model="product"
			@added-product="
				() => {
					this.$router.push({
						name: 'editPrecontrato',
						params: { id: $route.params.id, step: 2 },
					});
				}
			"
			@cancel="cancelled"
			:key="this.updated"
		></mao-de-obra-vue>

		<pcs-vue
			v-if="selected_device == 'pcs'"
			v-model="product"
			@added-product="
				() => {
					this.$router.push({
						name: 'editPrecontrato',
						params: { id: $route.params.id, step: 2 },
					});
				}
			"
			@cancel="cancelled"
			:key="this.updated"
		></pcs-vue>
		<softwares-vue
			v-if="selected_device == 'software'"
			v-model="product"
			@added-product="
				() => {
					this.$router.push({
						name: 'editPrecontrato',
						params: { id: $route.params.id, step: 2 },
					});
				}
			"
			@cancel="cancelled"
			:key="this.updated"
		></softwares-vue>
		<termicas-plotter-vue
			v-if="selected_device == 'termicasPlotter'"
			v-model="product"
			@added-product="
				() => {
					this.$router.push({
						name: 'editPrecontrato',
						params: { id: $route.params.id, step: 2 },
					});
				}
			"
			@cancel="cancelled"
			:key="this.updated"
		></termicas-plotter-vue>
	</div>
</template>

<script>
import ImpressoraScannerVue from "../../components/ProductProposal/ImpressoraScanner.vue";
import MaoDeObraVue from "../../components/ProductProposal//MaodeObra.vue";
import PcsVue from "../../components/ProductProposal//Pcs.vue";
import SoftwaresVue from "../../components/ProductProposal//Softwares.vue";
import TermicasPlotterVue from "../../components/ProductProposal//TermicasPlotter.vue";
import ProductProposalService from "../../services/ProductsProposalsService/ProductProposalsService";

export default {
	components: {
		ImpressoraScannerVue,
		MaoDeObraVue,
		PcsVue,
		SoftwaresVue,
		TermicasPlotterVue,
	},
	created() {
		let type = this.$route.params.type;

		if (type) {
			this.selected_device = type;
		}

		let id = this.$route.params.product_id;
		if (id) {
			this.loadProduct(id);
		}
	},
	data() {
		return {
			selected_device: "impressoraScanner",
			devices_options: [
				{ value: "impressoraScanner", text: "Impressoras e Scanners" },
				{ value: "software", text: "Softwares" },
				{ value: "pcs", text: "PCs e Desktops" },
				{
					value: "termicasPlotter",
					text: "Térmicas, Plotters e Matriciais",
				},
				{ value: "maodeObra", text: "Mão de Obra" },
			],
			product_service: new ProductProposalService(),

			product: {
				amount: null,
				allowed: null,
				released: null,
				per_page: null,
				desc: "",
				minimum_specification: "",
				accessories: "",
				codigo: "",
				numserie: "",
				coddepto: "",
				contato: "",
				custo: null,
				dataInstalacao: null,
				dtalt: null,
				dtcad: null,
				dataDevolucao: null,
				franqcolor: null,
				franqdg: null,
				franqgf: null,
				franqgfc: null,
				franqpb: null,
				franqtotal: null,
				horas: null,
				horasatende: null,
				horaspapel: null,
				horassup: null,
				ip: "",
				libleitura: "",
				local: "",
				netname: "",
				obs: "",
				opalt: "",
				opcad: "",
				orig: "",
				pat: "",
				situacao: "",
				tipoequip: "",
				tipohoras: null,
				tipointerv: null,
				proposal: null,
				product: null,
				site: undefined,
			},
			updated: false,
		};
	},
	methods: {
		cancelled() {
			this.$router.push({
				name: "editPrecontrato",
				params: { id: this.$route.params.id, step: 2 },
			});
		},
		loadProduct(id) {
			this.product_service.retrieve(id).then(({ data }) => {
				Object.assign(this.product, data);
				console.log(this.product);
			});
			this.updated = !this.updated;
		},
	},
};
</script>

<style></style>
