import baseService from "../baseService";
import apiClient from "../apiClient";
import Swal from "sweetalert2";

/*
Client Service
*/
const CLIENTS_ENDPOINT = "/api/clients";
export default class ClientService extends baseService {
  constructor() {
    super(CLIENTS_ENDPOINT, "client", 'codigo');
  }

  cnpj(cnpj) {
    //passar dentro do list como parametro o search, ver linha 243 de addDocument
    return apiClient
      .get(`${this.endpoint}cnpj/`, { params: { cnpj } })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.data.message,
        });
      });
  }
}
