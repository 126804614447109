<template>
  <div class="form-row">
    <div class="form-group col-md-4">
      <b-form-select
        v-model="filters.status"
        :options="filter_options.status"
      ></b-form-select>
    </div>
    <div class="form-group col-md-4">
      <b-form-select
        v-model="filters.region"
        :options="filter_options.region"
      ></b-form-select>
    </div>

    <div class="form-group col-md-4">
      <date-picker
        style="display: unset"
        format="DD/MM/YYYY"
        valueType="timestamp"
        input-class="form-control"
        id="previsaoFim"
        placeholder="Período"
        v-model="filters.period"
        range
      ></date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
export default {
  props: ["value"],
  components: {
    DatePicker,
  },
  data() {
    return {
      filters: {
        status: null,
        region: null,
        period: [],
      },
      filter_options: {
        status: [
          { value: null, text: "Status" },
          { value: 1, text: "Ativo" },
          { value: 2, text: "Expirado" },
          { value: 3, text: "Próximo" },
        ],
        region: [
          { value: null, text: "Região" },
          { value: "AC", text: "Acre" },
          { value: "AL", text: "Alagoas" },
          { value: "AP", text: "Amapá" },
          { value: "AM", text: "Amazonas" },
          { value: "BA", text: "Bahia" },
          { value: "CE", text: "Ceará" },
          { value: "DF", text: "Distrito Federal" },
          { value: "ES", text: "Espírito Santo" },
          { value: "GO", text: "Goiás" },
          { value: "MA", text: "Maranhão" },
          { value: "MT", text: "Mato Grosso" },
          { value: "MS", text: "Mato Grosso do Sul" },
          { value: "MG", text: "Minas Gerais" },
          { value: "PA", text: "Pará" },
          { value: "PB", text: "Paraíba" },
          { value: "PR", text: "Paraná" },
          { value: "PE", text: "Pernambuco" },
          { value: "PI", text: "Piauí" },
          { value: "RJ", text: "Rio de Janeiro" },
          { value: "RN", text: "Rio Grande do Norte" },
          { value: "RS", text: "Rio Grande do Sul" },
          { value: "RO", text: "Rondônia" },
          { value: "RR", text: "Roraima" },
          { value: "SC", text: "Santa Catarina" },
          { value: "SP", text: "São Paulo" },
          { value: "SE", text: "Sergipe" },
          { value: "TO", text: "Tocantins" },
        ],
      },
    };
  },
  mounted() {
    this.filters = this.value;
  },
  watch: {
    filters: {
      handler() {
        console.log(JSON.parse(JSON.stringify(this.filters)));
        this.$emit("input", this.filters);
      },
      deep: true,
    },
  },
};
</script>

<style>
.mx-table {
  min-width: unset;
  margin-top: unset;
}
</style>