<template>
  <div>
      <NavBar v-if="$route.meta.requiresNavBar" />
      <router-view v-else></router-view>
  </div>
</template>

<script>
import NavBar from "./components/Nav/NavBar.vue";
// import NavLateral from "./components/NavLateral/NavLateral.vue";
import { getCookie } from "./utils/util";
import { router } from "./routes/router";

export default {
  name: "App",
  components: {
    NavBar,
    // NavLateral,
  },

  created: function () {
    if (!getCookie("access")) {
      return router.push("/login");
    }
  },
};
</script>

<style>
@import "/public/styles/bootstrap/variables";
@import "/public/styles/bootstrap/bootstrap";
</style>
