<template>
	<div>
		<div class="row">
			<div class="col-10">
				<p
					class="title"
					style="
						font-size: 14px;
						width: 100%;
						margin-top: 2%;
						margin-bottom: 0px;
						line-height: 30px;
					"
				>
					IMPRESSORAS
				</p>
			</div>
			<div class="col-2">
				<!-- <router-link
					style="text-decoration: none; color: white"
					:to="{ name: 'AddProduct', params: { id: value.id } }"
				> -->
				<button
					v-b-modal.add-product
					class="btn-proposta"
					style="height: 30px; float: right; outline: none"
				>
					Novo item
				</button>
				<!-- </router-link> -->
			</div>
		</div>
		<b-modal
			scrollable="true"
			id="add-product"
			size="xl"
			title="Adição de Equipamento ou Serviço"
			style="color: rgb(161, 0, 12) !important"
			hide-footer
		>
			<AddProducts
				:selected_device="selected_device"
				@added-product="
					() => {
						this.$bvModal.hide('add-product');
						this.reload();
					}
				"
				@cancel="
					() => {
						this.$bvModal.hide('add-product');
					}
				"
			></AddProducts>
		</b-modal>
		<b-modal
			scrollable="true"
			id="edit-product"
			size="xl"
			title="Edição de Equipamento ou Serviço"
			style="color: rgb(161, 0, 12) !important"
			hide-footer
		>
			<EditProducts
				:key="this.current_product"
				:product_id="this.current_product"
				:product_type="this.current_type"
				@added-product="
					() => {
						this.$bvModal.hide('edit-product');
						this.reload();
					}
				"
				@cancel="
					() => {
						this.$bvModal.hide('edit-product');
					}
				"
			></EditProducts>
		</b-modal>
		<b-modal
			scrollable="true"
			id="link-product"
			size="xl"
			title="Anexação de Equipamento ou Serviço"
			style="color: rgb(161, 0, 12) !important"
			hide-footer
		>
			<LinkProducts
				:key="this.current_product"
				:product_id="this.current_product"
				:product_type="this.current_type"
				@added-product="
					() => {
						this.$bvModal.hide('link-product');
						this.reload();
					}
				"
				@cancel="
					() => {
						this.$bvModal.hide('link-product');
					}
				"
			></LinkProducts>
		</b-modal>

		<div style="display: flex">
			<span
				class="material-icons-outlined"
				@click="selected_device='impressoraScanner';$bvModal.show('add-product')"
				style="
					color: #384364;
					cursor: pointer;
					font-size: 18px;
					margin-top: 2px;
				"
			>
				note_add
			</span>
			<p style="margin-left: 5px; margin-bottom: 0px;">
				Sem impressoras e/ou scanners, adicione um item para exibir na
				tabela.
			</p>
		</div>

		<table
			class="table table-sm mt-0"
			id="table-imp"
		>
			<thead>
				<tr
					style="
						background: #f9f9f9 0% 0% no-repeat padding-box;
						border: 0.5px solid #e1e1e1;
						border-radius: 10px;
						opacity: 1;
					"
				>
					<th scope="col">Modelo Ref.</th>
					<th scope="col">Situação</th>
					<th scope="col">Quant. Contratada</th>
					<th scope="col">Quant. Aprovada</th>
					<!-- <th scope="col">Status</th> -->
					<th scope="col">Quant. Liberada</th>
					<th scope="col">Franquia Color</th>
					<th scope="col">Franq. P&B</th>
					<th scope="col">Quant. Transformadores</th>
					<th></th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody
				style="border: none"
				v-for="(product, i) in printers"
				:key="i"
			>
				<tr>
					<td class="left-td">
						{{ product.product_obj.nome }}
					</td>
					<td>
						<b-select
							@change="salvarStatus(product, 'impressoraScanner')"
							name="novo-usado"
							class="form-control"
							required
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							v-model="product.new"
							:options="[
								{
									value: false,
									text: 'Usado',
								},
								{ value: true, text: 'Novo' },
							]"
						>
						</b-select>
					</td>
					<td>
						<b-form-input
							class="input-amount"
							name="qtdContratada"
							id="qtdContratada"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							required
							v-model="product.amount"
							@change="salvarStatus(product, 'impressoraScanner')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-allowed"
							name="qtdAllowed"
							id="qtdAllowed"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.allowed"
							@change="salvarStatus(product, 'impressoraScanner')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-released"
							name="qtdreleased"
							id="qtdreleased"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.released"
							@change="salvarStatus(product, 'impressoraScanner')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-franqcolor"
							name="qtdfranqcolor"
							id="qtdfranqcolor"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.franqcolor"
							@change="salvarStatus(product, 'impressoraScanner')"
						/>
					</td>
					<!-- <td>
						<b-form-input
							class="input-franqpb"
							name="qtdfranqpb"
							id="qtdfranqpb"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.franqpb"
							@change="salvarStatus(product, 'impressoraScanner')"
						/>
					</td> -->
					<td>
						{{ product.franqpb * product.amount }}
					</td>
					<td>
						<b-form-input
							class="input-transformers"
							name="qtdTransformers"
							id="qtdTransformers"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.number_of_transformers"
							@change="salvarStatus(product, 'impressoraScanner')"
						/>
					</td>
					<td>
						<span
							style="
								color: #384364;
								cursor: pointer;
								font-size: 16px;
								margin-right: 5%;
								padding-top: 10%;
							"
							class="material-icons-outlined"
							v-b-tooltip.hover
							title="Customizar equipamento"
							@click="
								linkProduct(product.id, 'impressoraScanner')
							"
						>
							add_link
						</span>
					</td>
					<td>
						<span
							@click="
								editProduct(product.id, 'impressoraScanner')
							"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 16px;
								margin-right: 5%;
								padding-top: 10%;
							"
							class="material-icons-outlined"
						>
							edit
						</span>
					</td>
					<td>
						<span
							class="material-icons-outlined"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 16px;
								margin-right: 5%;
								padding-top: 10%;
							"
							@click="deleteItem(product.id)"
						>
							delete
						</span>
					</td>
				</tr>

				<template>
					<tr
						v-for="(children, i) in product.children"
						:key="i"
						style="background-color: #f0f0f0"
					>
						<td class="link-td left-td">
							<span
								style="
									color: #384364;
									font-size: 16px;
									margin-right: 2%;
									margin-left: 3%;
								"
								class="material-icons-outlined"
							>
								link
							</span>
							{{ children.product_obj.nome }}
						</td>
						<td>
							<b-select
								@change="
									salvarStatus(children, 'impressoraScanner')
								"
								name="novo-usado"
								class="form-control"
								required
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								v-model="children.new"
								:options="[
									{
										value: false,
										text: 'Usado',
									},
									{ value: true, text: 'Novo' },
								]"
							>
							</b-select>
						</td>
						<td>
							<b-form-input
								class="children-amount"
								name="childrenAmount"
								id="qtdAmount"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.amount"
								@change="
									salvarStatus(children, 'impressoraScanner')
								"
							/>
						</td>
						<td>
							<b-form-input
								class="children-allowed"
								name="childrenAllowed"
								id="childrenAllowed"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.allowed"
								@change="
									salvarStatus(children, 'impressoraScanner')
								"
							/>
						</td>
						<td>
							<b-form-input
								class="children-released"
								name="childrenreleased"
								id="childrenreleased"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.released"
								@change="
									salvarStatus(children, 'impressoraScanner')
								"
							/>
						</td>
						<td>
							<b-form-input
								class="children-franqcolor"
								name="childrenfranqcolor"
								id="childrenfranqcolor"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.franqcolor"
								@change="
									salvarStatus(children, 'impressoraScanner')
								"
							/>
						</td>
						<!-- <td>
							<b-form-input
								class="children-franqpb"
								name="childrenfranqpb"
								id="childrenfranqpb"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.franqpb"
								@change="
									salvarStatus(children, 'impressoraScanner')
								"
							/>
						</td> -->
						<td>
							{{ children.franqpb * children.amount }}
						</td>
						<td>
							<b-form-input
								class="children-number_of_transformers"
								name="childrennumber_of_transformers"
								id="childrennumber_of_transformers"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.number_of_transformers"
								@change="
									salvarStatus(children, 'impressoraScanner')
								"
							/>
						</td>
						<td></td>
						<td>
							<span
								@click="
									editProduct(
										children.id,
										'impressoraScanner'
									)
								"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 16px;
									margin-right: 5%;
									padding-top: 10%;
								"
								class="material-icons-outlined"
							>
								edit
							</span>
						</td>
						<td>
							<span
								class="material-icons-outlined"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 16px;
									margin-right: 5%;
									padding-top: 10%;
								"
								@click="deleteItem(children.id)"
							>
								delete
							</span>
						</td>
					</tr>
				</template>
			</tbody>
			<tr style="background: #ebebeb">
				<td class="left-td">
					Total ({{ proposal.printer_ref_qt + " " }} Impressoras)
				</td>
				<td></td>
				<td
					:style="{
						color:
							amounts().printers.amount <= proposal.printer_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().printers.amount }}/{{
						proposal.printer_ref_qt
					}}
				</td>
				<td
					:style="{
						color:
							amounts().printers.allowed <=
							proposal.printer_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().printers.allowed }}/{{
						proposal.printer_ref_qt
					}}
				</td>
				<td
					:style="{
						color:
							amounts().printers.released <=
							proposal.printer_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().printers.released }}/{{
						proposal.printer_ref_qt
					}}
				</td>
				<td
					:style="{
						color:
							amounts().printers.franqcolor >= 0
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().printers.franqcolor }}
				</td>
				<td
					:style="{
						color:
							amounts().printers.franqpb >= 0 ? 'green' : 'red',
					}"
				>
					{{ amounts().printers.franqpb }}
				</td>
				<td
					:style="{
						color:
							amounts().printers.number_of_transformers >= 0
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().printers.number_of_transformers }}
				</td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
		</table>



		<p
			class="title"
			style="
				font-size: 14px;
				width: 100%;
				margin-bottom: 0px;
				margin-top: 2%;
				line-height: 30px;
			"
		>
			SOFTWARES
		</p>
		<div style="display: flex">
			<span
				class="material-icons-outlined"
				@click="selected_device='software';$bvModal.show('add-product')"
				style="
					color: #384364;
					cursor: pointer;
					font-size: 18px;
					margin-top: 2px;
				"
			>
				note_add
			</span>
			<p style="margin-left: 5px">
				Sem softwares, adicione um item para exibir na tabela.
			</p>
		</div>
		<table
			class="table table-sm mt-0"
			id="table-software"
		>
			<thead>
				<tr
					style="
						background: #f9f9f9 0% 0% no-repeat padding-box;
						border: 0.5px solid #e1e1e1;
						border-radius: 10px;
						opacity: 1;
						text-align: center;
					"
				>
					<th scope="col">Modelo</th>
					<th scope="col">Fabricante</th>
					<th scope="col">Quant. Contratada</th>
					<th scope="col">Quant. Aprovada</th>
					<th scope="col">Quant. Liberada</th>
					<th scope="col">Valor Unitario</th>
					<!-- <th scope="col">Status</th> -->
					<th scope="col">Valor Total</th>
					<th></th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(product, i) in softwares" :key="i">
					<td>{{ product.product_obj.nome }}</td>
					<td>
						<b-form-input
							class="input-manufacturer"
							name="manufacturer"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.manufacturer"
							@change="salvarStatus(product, 'sofware')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-amount"
							name="qtdContratada"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.amount"
							@change="salvarStatus(product, 'sofware')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-allowed"
							name="qtdAllowed"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.allowed"
							@change="salvarStatus(product, 'sofware')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-released"
							name="qtdReleased"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.released"
							@change="salvarStatus(product, 'sofware')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-custo"
							name="qtdCusto"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.custo"
							@change="salvarStatus(product, 'sofware')"
						/>
					</td>
					<td>
						{{ product.total_anual }}
					</td>

					<td>
						<span
							@click="editProduct(product.id, 'software')"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 16px;
								margin-right: 5%;
								padding-top: 10%;
							"
							class="material-icons-outlined"
						>
							edit
						</span>
					</td>
					<td>
						<span
							class="material-icons-outlined"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 18px;
								margin-right: 5%;
								padding-top: 10%;
							"
							@click="deleteItem(product.id)"
						>
							delete
						</span>
					</td>
				</tr>
			</tbody>
			<tr style="background: #ebebeb">
				<td>Total ({{ proposal.software_ref_qt + " " }} Softwares)</td>
				<td></td>
				<td
					:style="{
						color:
							amounts().softwares.amount <=
							proposal.software_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().softwares.amount }}/{{
						proposal.software_ref_qt
					}}
				</td>
				<td
					:style="{
						color:
							amounts().softwares.allowed <=
							proposal.software_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().softwares.allowed }}/{{
						proposal.software_ref_qt
					}}
				</td>
				<td
					:style="{
						color:
							amounts().softwares.released <=
							proposal.software_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().softwares.released }}/{{
						proposal.software_ref_qt
					}}
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
		</table>


		<p
			class="title"
			style="
				font-size: 14px;
				width: 100%;
				margin-bottom: 0PX;
				margin-top: 2%;
				line-height: 30px;
			"
		>
			PCS E DESKTOPS
		</p>
		<div style="display: flex">
			<span
				class="material-icons-outlined"
				@click="selected_device='pcs';$bvModal.show('add-product')"
				style="
					color: #384364;
					cursor: pointer;
					font-size: 18px;
					margin-top: 2px;
				"
			>
				note_add
			</span>
			<p style="margin-left: 5px">
				Sem Pcs e/ou desktops, adicione um item para exibir na tabela.
			</p>
		</div>
		<table class="table table-sm mt-0" id="table-pcs">
			<thead>
				<tr
					style="
						background: #f9f9f9 0% 0% no-repeat padding-box;
						border: 0.5px solid #e1e1e1;
						border-radius: 10px;
						opacity: 1;
					"
				>
					<th scope="col">Modelo</th>
					<th scope="col">Fabricante</th>
					<th scope="col">Situação</th>
					<!-- <th scope="col">Status</th> -->
					<th scope="col">Acessórios</th>
					<th>Quant. Contratada</th>
					<th scope="col">Quant. Aprovada</th>
					<th>Quant. Liberada</th>

					<th scope="col">Valor Unitário</th>
					<th scope="col">Valor Total Mensal</th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody style="border: none" v-for="(product, i) in pcs" :key="i">
				<tr>
					<td class="left-td">{{ product.product_obj.nome }}</td>
					<td>
						<b-form-input
							class="input-manufacturer"
							name="manufacturer"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.manufacturer"
							@change="salvarStatus(product, 'pcs')"
						/>
					</td>

					<td>
						<b-select
							@change="salvarStatus(product, 'pcs')"
							name="novo-usado"
							class="form-control"
							required
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							v-model="product.new"
							:options="[
								{
									value: false,
									text: 'Usado',
								},
								{ value: true, text: 'Novo' },
							]"
						>
						</b-select>
					</td>
					<td>
						<b-form-input
							class="input-accessories"
							name="accessories"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.accessories"
							@change="salvarStatus(product, 'pcs')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-amount"
							name="amount"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.amount"
							@change="salvarStatus(product, 'pcs')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-allowed"
							name="allowed"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.allowed"
							@change="salvarStatus(product, 'pcs')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-released"
							name="released"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.released"
							@change="salvarStatus(product, 'pcs')"
						/>
					</td>

					<td>
						<b-form-input
							class="input-custo"
							name="custo"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.custo"
							@change="salvarStatus(product, 'pcs')"
						/>
					</td>
					<td>{{ product.total_mensal }}</td>
					<td></td>
					<td>
						<span
							@click="linkProduct(product.id, 'pcs')"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 16px;
								margin-right: 5%;
								padding-top: 10%;
							"
							class="material-icons-outlined"
							v-b-tooltip.hover
							title="Anexar equipamento"
						>
							add_link
						</span>
					</td>
					<td>
						<span
							@click="editProduct(product.id, 'pcs')"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 16px;
								margin-right: 5%;
								padding-top: 10%;
							"
							class="material-icons-outlined"
						>
							edit
						</span>
					</td>
					<td>
						<span
							class="material-icons-outlined"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 18px;
								margin-right: 5%;
								padding-top: 10%;
							"
							@click="deleteItem(product.id)"
						>
							delete
						</span>
					</td>
				</tr>
				<template>
					<tr
						v-for="(children, i) in product.children"
						:key="i"
						style="background-color: #f0f0f0"
					>
						<td class="link-td left-td">
							<span
								style="
									color: #384364;
									font-size: 16px;
									margin-right: 2%;
									margin-left: 5%;
								"
								class="material-icons-outlined"
							>
								link
							</span>
							{{ children.product_obj.nome }}
						</td>
						<td>
							<b-form-input
								class="children-manufacturer"
								name="childrenmanufacturer"
								id="qtdmanufacturer"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.manufacturer"
								@change="salvarStatus(children, 'pcs')"
							/>
						</td>

						<td>
							<b-select
								@change="salvarStatus(children, 'pcs')"
								name="novo-usado"
								class="form-control"
								required
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								v-model="product.new"
								:options="[
									{
										value: false,
										text: 'Usado',
									},
									{ value: true, text: 'Novo' },
								]"
							>
							</b-select>
						</td>
						<td>
							<b-form-input
								class="children-accessories"
								name="childrenaccessories"
								id="qtdaccessories"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.accessories"
								@change="salvarStatus(children, 'pcs')"
							/>
						</td>

						<td>
							<b-form-input
								class="children-amount"
								name="childrenamount"
								id="qtdamount"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.amount"
								@change="salvarStatus(children, 'pcs')"
							/>
						</td>
						<td>
							<b-form-input
								class="children-allowed"
								name="childrenallowed"
								id="qtdallowed"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.allowed"
								@change="salvarStatus(children, 'pcs')"
							/>
						</td>
						<td>
							<b-form-input
								class="children-released"
								name="childrenreleased"
								id="qtdreleased"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.released"
								@change="salvarStatus(children, 'pcs')"
							/>
						</td>
						<td>
							<b-form-input
								class="children-custo"
								name="childrencusto"
								id="qtdcusto"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.custo"
								@change="salvarStatus(children, 'pcs')"
							/>
						</td>
						<td>{{ children.total_mensal }}</td>
						<td></td>
						<td></td>
						<td>
							<span
								@click="editProduct(children.id, 'pcs')"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 16px;
									margin-right: 5%;
									padding-top: 10%;
								"
								class="material-icons-outlined"
							>
								edit
							</span>
						</td>
						<td>
							<span
								class="material-icons-outlined"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 16px;
									margin-right: 5%;
									padding-top: 10%;
								"
								@click="deleteItem(children.id)"
							>
								delete
							</span>
						</td>
					</tr>
				</template>
			</tbody>
			<tr style="background: #ebebeb">
				<td class="left-td">
					Total ({{ proposal.pc_ref_qt }} Pcs e Desktops)
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td
					:style="{
						color:
							amounts().pcs.amount <= proposal.pc_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().pcs.amount }}/{{ proposal.pc_ref_qt }}
				</td>
				<td
					:style="{
						color:
							amounts().pcs.allowed <= proposal.pc_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().pcs.allowed }}/{{ proposal.pc_ref_qt }}
				</td>
				<td
					:style="{
						color:
							amounts().pcs.released <= proposal.pc_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().pcs.released }}/{{ proposal.pc_ref_qt }}
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
		</table>


		<p
			class="title"
			style="
				font-size: 14px;
				width: 100%;
				margin-bottom: 0PX;
				margin-top: 2.5%;
				line-height: 30px;
			"
		>
			TÉRMICAS, PLOTTERS E MATRICIAIS
		</p>
		<div style="display: flex">
			<span
				class="material-icons-outlined"
				@click="selected_device='termicasPlotter';$bvModal.show('add-product')"
				style="
					color: #384364;
					cursor: pointer;
					font-size: 18px;
					margin-top: 2px;
				"
			>
				note_add
			</span>
			<p style="margin-left: 5px">
				Sem térmicas, plotter e/ou matriciais, adicione um item para
				exibir na tabela.
			</p>
		</div>
		<table
			class="table table-sm mt-0"
			id="table-termicas"
		>
			<thead>
				<tr
					style="
						background: #f9f9f9 0% 0% no-repeat padding-box;
						border: 0.5px solid #e1e1e1;
						border-radius: 10px;
						opacity: 1;
					"
				>
					<th scope="col">Modelo</th>
					<th scope="col">Fabricante</th>

					<th scope="col">Situação</th>
					<!-- <th scope="col">Status</th> -->
					<th scope="col">Quant. Contratada</th>
					<th scope="col">Quant. Aprovada</th>
					<th scope="col">Quant. Liberada</th>
					<th scope="col">Valor Unitário</th>
					<th scope="col">Valor Total Mensal</th>
					<th></th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody
				style="border: none"
				v-for="(product, i) in termicas"
				:key="i"
			>
				<tr>
					<td style="text-transform: uppercase" class="left-td">
						{{ product.product_obj.nome }}
					</td>
					<td>
						<b-form-input
							class="input-manufacturer"
							name="manufacturer"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.manufacturer"
							@change="salvarStatus(product, 'termicasPlotter')"
						/>
					</td>

					<td>
						<b-select
							@change="salvarStatus(product, 'termicasPlotter')"
							name="novo-usado"
							class="form-control"
							required
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							v-model="product.new"
							:options="[
								{
									value: false,
									text: 'Usado',
								},
								{ value: true, text: 'Novo' },
							]"
						>
						</b-select>
					</td>
					<td>
						<b-form-input
							class="input-amount"
							name="amount"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.amount"
							@change="salvarStatus(product, 'termicasPlotter')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-allowed"
							name="allowed"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.allowed"
							@change="salvarStatus(product, 'termicasPlotter')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-released"
							name="released"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.released"
							@change="salvarStatus(product, 'termicasPlotter')"
						/>
					</td>
					<td>
						<b-form-input
							class="input-custo"
							name="custo"
							style="
								border-radius: 2px;
								background: #fff 0% 0% no-repeat padding-box;
							"
							type="text"
							v-model="product.custo"
							@change="salvarStatus(product, 'termicasPlotter')"
						/>
					</td>
					<td>{{ product.total_mensal }}</td>
					<td>
						<span
							@click="linkProduct(product.id, 'termicasPlotter')"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 16px;
								margin-right: 5%;
								padding-top: 10%;
							"
							class="material-icons-outlined"
							v-b-tooltip.hover
							title="Anexar equipamento"
						>
							add_link
						</span>
					</td>
					<td>
						<span
							@click="editProduct(product.id, 'termicasPlotter')"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 16px;

								padding-top: 10%;
							"
							class="material-icons-outlined"
						>
							edit
						</span>
					</td>
					<td>
						<span
							class="material-icons-outlined"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 18px;
								margin-right: 5%;
								padding-top: 10%;
							"
							@click="deleteItem(product.id)"
						>
							delete
						</span>
					</td>
				</tr>
				<template>
					<tr
						v-for="(children, i) in product.children"
						:key="i"
						style="background-color: #f0f0f0"
					>
						<td
							class="left-td"
							style="
								text-transform: uppercase;
								display: flex;
								margin-top: -0.9px;
								padding-top: 8.5px;
							"
						>
							<span
								style="
									color: #384364;
									font-size: 16px;
									margin-right: 2%;
									margin-left: 4%;
								"
								class="material-icons-outlined"
							>
								link
							</span>
							{{ children.product_obj.nome }}
						</td>

						<td>
							<b-form-input
								class="children-manufacturer"
								name="childrenmanufacturer"
								id="qtdmanufacturer"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.manufacturer"
								@change="
									salvarStatus(children, 'termicasPlotter')
								"
							/>
						</td>
						<td>
							<b-select
								@change="
									salvarStatus(children, 'termicasPlotter')
								"
								name="novo-usado"
								class="form-control"
								required
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								v-model="children.new"
								:options="[
									{
										value: false,
										text: 'Usado',
									},
									{ value: true, text: 'Novo' },
								]"
							>
							</b-select>
						</td>
						<td>
							<b-form-input
								class="children-amount"
								name="childrenamount"
								id="qtdamount"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.amount"
								@change="
									salvarStatus(children, 'termicasPlotter')
								"
							/>
						</td>
						<td>
							<b-form-input
								class="children-allowed"
								name="childrenallowed"
								id="qtdallowed"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.allowed"
								@change="
									salvarStatus(children, 'termicasPlotter')
								"
							/>
						</td>
						<td>
							<b-form-input
								class="children-released"
								name="childrenreleased"
								id="qtdreleased"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.released"
								@change="
									salvarStatus(children, 'termicasPlotter')
								"
							/>
						</td>
						<td>
							<b-form-input
								class="children-custo"
								name="childrencusto"
								id="qtdcusto"
								style="
									border-radius: 2px;
									background: #f0f0f0 0% 0% no-repeat
										padding-box;
								"
								type="text"
								v-model="children.custo"
								@change="
									salvarStatus(children, 'termicasPlotter')
								"
							/>
						</td>
						<td>{{ children.total_mensal }}</td>
						<td></td>
						<td>
							<span
								@click="
									editProduct(children.id, 'termicasPlotter')
								"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 16px;
									margin-right: 5%;
									padding-top: 10%;
								"
								class="material-icons-outlined"
							>
								edit
							</span>
						</td>
						<td>
							<span
								class="material-icons-outlined"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 18px;
									margin-right: 5%;
									padding-top: 10%;
								"
								@click="deleteItem(children.id)"
							>
								delete
							</span>
						</td>
					</tr>
				</template>
			</tbody>
			<tr style="background: #ebebeb">
				<td class="left-td">
					Total ({{ proposal.termic_ref_qt + " " }} Térmicas)
				</td>
				<td></td>
				<td></td>
				<td
					:style="{
						color:
							amounts().termicas.amount <= proposal.termic_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().termicas.amount }}/{{ proposal.termic_ref_qt }}
				</td>
				<td
					:style="{
						color:
							amounts().termicas.allowed <= proposal.termic_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().termicas.allowed }}/{{
						proposal.termic_ref_qt
					}}
				</td>
				<td
					:style="{
						color:
							amounts().termicas.released <=
							proposal.termic_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().termicas.released }}/{{
						proposal.termic_ref_qt
					}}
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
		</table>


		<p
			class="title"
			style="
				font-size: 14px;
				width: 100%;
				margin-bottom: 0px;
				margin-top: 2%;
				line-height: 30px;
			"
		>
			MÃO DE OBRA
		</p>

		<div style="display: flex">
			<span
				class="material-icons-outlined"
				@click="selected_device='maodeObra';$bvModal.show('add-product')"
				style="
					color: #384364;
					cursor: pointer;
					font-size: 18px;
					margin-top: 2px;
				"
			>
				note_add
			</span>
			<p style="margin-left: 5px">
				Sem mão de obra, adicione um item para exibir na tabela.
			</p>

		</div>

		<table
			class="table table-sm mt-0"
			id="table-mdo"
		>
			<thead>
				<tr
					style="
						background: #f9f9f9 0% 0% no-repeat padding-box;
						border: 0.5px solid #e1e1e1;
						border-radius: 10px;
						opacity: 1;
					"
				>
					<th scope="col">Descrição</th>
					<th scope="col">Quant. Contratada</th>
					<th scope="col">Quant. Aprovada</th>
					<th scope="col">Quant. Liberada</th>
					<th scope="col">Valor Unitário</th>
					<th scope="col">Valor Total Mensal</th>
					<th scope="col">Valor Total</th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<template v-for="(product, i) in maodeObra">
					<tr :key="i">
						<td>
							<b-form-input
								class="input-desc"
								name="desc"
								style="
									border-radius: 2px;
									background: #fff 0% 0% no-repeat padding-box;
								"
								type="text"
								v-model="product.desc"
								@change="salvarStatus(product, 'maodeObra')"
							/>
						</td>
						<td>
							<b-form-input
								class="input-amount"
								name="amount"
								style="
									border-radius: 2px;
									background: #fff 0% 0% no-repeat padding-box;
								"
								type="text"
								v-model="product.amount"
								@change="salvarStatus(product, 'maodeObra')"
							/>
						</td>
						<td>
							<b-form-input
								class="input-allowed"
								name="allowed"
								style="
									border-radius: 2px;
									background: #fff 0% 0% no-repeat padding-box;
								"
								type="text"
								v-model="product.allowed"
								@change="salvarStatus(product, 'maodeObra')"
							/>
						</td>
						<td>
							<b-form-input
								class="input-released"
								name="released"
								style="
									border-radius: 2px;
									background: #fff 0% 0% no-repeat padding-box;
								"
								type="text"
								v-model="product.released"
								@change="salvarStatus(product, 'maodeObra')"
							/>
						</td>
						<td>
							<b-form-input
								class="input-custo"
								name="custo"
								style="
									border-radius: 2px;
									background: #fff 0% 0% no-repeat padding-box;
								"
								type="text"
								v-model="product.custo"
								@change="salvarStatus(product, 'maodeObra')"
							/>
						</td>
						<td>{{ product.total_mensal }}</td>
						<td>{{ product.total_anual }}</td>

						<td>
							<span
								@click="editProduct(product.id, 'maodeObra')"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 16px;
									margin-right: 5%;
									padding-top: 10%;
								"
								class="material-icons-outlined"
							>
								edit
							</span>
						</td>
						<td>
							<span
								class="material-icons-outlined"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 18px;
									margin-right: 5%;
									padding-top: 10%;
								"
								@click="deleteItem(product.id)"
							>
								delete
							</span>
						</td>
					</tr>
					<!-- <tr :key="i" v-if="isOpen && selectedIndex === i">
            <table
              style="margin-top: 0px !important"
              class="table table-sm mt-0"
            >
              <tbody>
                <tr>
                  <td>{{ product.desc }}</td>
                  <td>{{ product.amount }}</td>
                  <td>{{ product.custo }}</td>
                  <td>{{ product.total_mensal }}</td>
                  <td>{{ product.total_anual }}</td>
                  <td>
                    <span
                      style="
                        color: #384364;
                        cursor: pointer;
                        font-size: 16px;
                        margin-right: 5%;
                        padding-top: 10%;
                      "
                      class="material-icons-outlined"
                      @click="
                        isOpen = !isOpen;
                        selectedIndex = i;
                      "
                    >
                      add_link
                    </span>

                    <router-link
                      style="text-decoration: none; color: white"
                      :to="{
                        name: 'editProduct',
                        params: {
                          id: value.id,
                          product_id: product.id,
                          type: 'maodeObra',
                        },
                      }"
                    >
                      <span
                        style="
                          color: #384364;
                          cursor: pointer;
                          font-size: 16px;
                          margin-right: 5%;
                          padding-top: 10%;
                        "
                        class="material-icons-outlined"
                      >
                        edit
                      </span>
                    </router-link>
                  </td>
                  <td>
                    <span
                      class="material-icons-outlined"
                      style="
                        color: #384364;
                        cursor: pointer;
                        font-size: 18px;
                        margin-right: 5%;
                        padding-top: 10%;
                      "
                      @click="deleteItem(product.id)"
                    >
                      delete
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </tr>
          <span :key="i" v-else></span> -->
				</template>
			</tbody>
			<tr style="background: #ebebeb">
				<td>Total ({{ proposal.handwork_ref_qt }})</td>

				<td
					:style="{
						color:
							amounts().maodeObra.amount <=
							proposal.handwork_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().maodeObra.amount }}/{{
						proposal.handwork_ref_qt
					}}
				</td>
				<td
					:style="{
						color:
							amounts().maodeObra.allowed <=
							proposal.handwork_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().maodeObra.allowed }}/{{
						proposal.handwork_ref_qt
					}}
				</td>
				<td
					:style="{
						color:
							amounts().maodeObra.released <=
							proposal.handwork_ref_qt
								? 'green'
								: 'red',
					}"
				>
					{{ amounts().maodeObra.released }}/{{
						proposal.handwork_ref_qt
					}}
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
		</table>

		<div class="d-flex justify-content-end" style="padding-bottom: 40px;">
			<button
				type="submit"
				class="btn btn-secondary py-2 px-4 float-md-right"
				style="
					background: #7E7E7E;
					border-radius: 8px;
					padding-left: 30px;
					padding-right: 30px;
					margin-right: 20px;
					border: none;
				"
				@click="
					() => {
						this.$emit('cancel');
					}
				"
			>
				Voltar
			</button>
			<button
			type="submit"
			class="btn btn-primary"
			style="
				background: #006992;
				border-radius: 8px;
				padding-left: 30px;
				padding-right: 30px;
				border: none
			"
			:disabled="!amountsMatch"
			@click="
				() => {
					this.$emit('next');
				}
			"
			>
				Avançar
			</button>
		</div>
	</div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";
import ProductProposalService from "../../services/ProductsProposalsService/ProductProposalsService";
import { getMonths } from "../../utils/util";
import AddProducts from "../Products/AddProducts.vue";
import EditProducts from "../Products/EditProducts.vue";
import LinkProducts from "../Products/LinkProducts.vue";

export default {
	components: {
		AddProducts,
		EditProducts,
		LinkProducts,
	},
	props: ["value"],
	data() {
		return {
			selected_device: "impressoraScanner",
			products: [],
			parentProducts: [],
			productProposalService: new ProductProposalService(),
			isOpen: false,
			selectedIndex: null,
			current_product: null,
			current_type: null,
		};
	},
	mounted() {
		this.reload();
	},
	created() {
		this.proposal = this.value;
	},

	methods: {
		show() {
			$(document).ready(function () {
				console.log($(this).find(".fa.fa-angle-down"));
				$(this).find("i").toggleClass("fa-angle-down fa-angle-up");

				$(this)
					.find(".show-details")
					.siblings(".details")
					.toggleClass("open")
					.slideToggle("milliseconds");
			});
		},

		salvarStatus(product, productType) {
			// product.amount = Number(product.amount);
			// product.allowed = Number(product.allowed);
			// product.released = Number(product.released);

			//IMPLEMENTAR METODO DE VALIDAÇÃO

			this.current_type = productType;

			this.current_product = product;

			this.productProposalService.update(product);
		},
		editProduct(productId, productType) {
			this.current_type = productType;
			this.current_product = productId;

			this.$root.$emit("bv::show::modal", "edit-product", "#btnShow");
		},
		linkProduct(productId, productType) {
			this.productProposalService.retrieve(productId).then(({ data }) => {
				Object.assign(this.product, data);
				this.$set(this.product, "referencia", data.product_obj.nome);
				this.$set(this.product, "contratada", data.amount);
				this.$set(this.product, "limiteColor", data.franqcolor);
				this.$set(this.product, "limitePb", data.franqpb);
				this.$set(this.product, "limiteQtd", data.amount);
				this.$set(this.product, "limiteTransformers", data.amount);
			});
			this.current_product = productId;
			this.current_type = productType;
			this.$root.$emit("bv::show::modal", "link-product", "#btnShow");
		},
		reload() {
			this.productProposalService
				.list({ params: { proposal: this.$route.params.id } })
				.then(({ data }) => {
					this.products = data.results;
				});
		},

		async deleteItem(id) {
			Swal.fire({
				title: "Certeza que deseja deletar?",
				text: "Essa ação não poderá ser desfeita",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#0bb4d4",
				cancelButtonColor: "#A9A9A9",

				cancelButtonText: "Cancelar",
				confirmButtonText: "Deletar",
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					//Fazer cliente sumir da página

					this.productProposalService.destroy(id).then(({ data }) => {
						console.log(data);
						this.reload();
					});
					Swal.fire(
						"Deletado!",
						"Equipamento deletado com sucesso",
						"success"
					);
				}
			});
		},

		calc_values(product) {
			product.total_mensal = product.amount * product.custo;
			product.total_anual =
				product.total_mensal *
				getMonths(
					this.proposal.start_date,
					this.proposal.end_date
				);
			return product;
		},
		amounts() {
			let qtd = {
				printers: {
					amount: 0,
					allowed: 0,
					released: 0,
					franqcolor: 0,
					franqpb: 0,
					number_of_transformers: 0,
				},
				softwares: { amount: 0, allowed: 0, released: 0 },
				pcs: { amount: 0, allowed: 0, released: 0 },
				termicas: { amount: 0, allowed: 0, released: 0 },
				maodeObra: { amount: 0, allowed: 0, released: 0 },
			};

			let fields = [
				"amount",
				"allowed",
				"released",
				"franqcolor",
				"franqpb",
				"number_of_transformers",
			];
			let products = [
				"printers",
				"softwares",
				"pcs",
				"termicas",
				"maodeObra",
			];

			fields.forEach((fieldElement) => {
				products.forEach((productElement) => {

					if (productElement === "printers" && fieldElement === "franqpb") {

						let sumOfFranPBMultipliedByAmount = 0;

						this[productElement].forEach((v) => {
							sumOfFranPBMultipliedByAmount += v.franqpb * v.amount
						})
						qtd[productElement][fieldElement] += sumOfFranPBMultipliedByAmount;

					} else {

						qtd[productElement][fieldElement] += this[
							productElement
						].reduce((a, b) => Number(a) + Number(b[fieldElement]), 0);

					}

					if (["maodeObra", "softwares"].includes(productElement)) {
						return;
					}

					if (productElement === "printers" && fieldElement === "franqpb") {
						this[productElement].forEach((parentElement) => {

							let sumOfChildrenFranPBMultipliedByAmount = 0;

							parentElement.children.forEach((v) => {
								sumOfChildrenFranPBMultipliedByAmount += v.franqpb * v.amount
							})
							qtd[productElement][fieldElement] += sumOfChildrenFranPBMultipliedByAmount
						});

					} else {

						this[productElement].forEach((parentElement) => {
							qtd[productElement][fieldElement] +=
								parentElement.children.reduce(
									(a, b) => Number(a) + Number(b[fieldElement]),
									0
								);
						});
					}
				});
			});

			this.printers

			return qtd;
		},
	},
	computed: {
		amountsMatch() {

			let amounts = this.amounts()

			let printersMatch = (
				[amounts.printers.allowed, amounts.printers.amount, amounts.printers.released].every(value => value === this.proposal.printer_ref_qt)
			)

			let softwaresMatch = (
				[amounts.softwares.allowed, amounts.softwares.amount, amounts.softwares.released].every(value => value === this.proposal.software_ref_qt)
			)

			let pcsDesktopsMatch = (
				[amounts.pcs.allowed, amounts.pcs.amount, amounts.pcs.released].every(value => value === this.proposal.pc_ref_qt)
			)

			let termicasPlotterMatriciaisMatch = (
				[amounts.termicas.allowed, amounts.termicas.amount, amounts.termicas.released].every(value => value === this.proposal.termic_ref_qt)
			)

			let maoDeObraMatch = (
				[amounts.maodeObra.allowed, amounts.maodeObra.amount, amounts.maodeObra.released].every(value => value === this.proposal.handwork_ref_qt)
			)

			return (
				printersMatch &&
				softwaresMatch &&
				pcsDesktopsMatch &&
				termicasPlotterMatriciaisMatch &&
				maoDeObraMatch
			)
		},
		printers() {
			let products = this.products
				.filter((p) => p.product_obj.type == 0)
				.filter((p) => p.termic === false);

			let childrenProducts = products.filter(
				(element) => element.parent !== null
			);

			let parentProducts = products.filter(
				(element) => element.parent === null
			);

			parentProducts.forEach((parent) => {
				parent.children = childrenProducts.filter(
					(element) => element.parent === parent.id
				);
			});

			return parentProducts.map(this.calc_values);
		},
		softwares() {
			let products = this.products;

			return products
				.filter((p) => p.product_obj.type == 1)
				.map(this.calc_values);
		},
		pcs() {
			let products = this.products.filter((p) => p.product_obj.type == 2);

			let childrenProducts = products.filter(
				(element) => element.parent !== null
			);

			let parentProducts = products.filter(
				(element) => element.parent === null
			);

			parentProducts.forEach((parent) => {
				parent.children = childrenProducts.filter(
					(element) => element.parent === parent.id
				);
			});

			return parentProducts.map(this.calc_values);
		},
		termicas() {
			let products = this.products.filter((p) => p.termic);

			let childrenProducts = products.filter(
				(element) => element.parent !== null
			);

			let parentProducts = products.filter(
				(element) => element.parent === null
			);

			parentProducts.forEach((parent) => {
				parent.children = childrenProducts.filter(
					(element) => element.parent === parent.id
				);
			});

			return parentProducts.map(this.calc_values);
		},
		maodeObra() {
			let products = this.products.filter((p) => p.product_obj.type == 4);

			return products.map(this.calc_values);
		},
	},
};
</script>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"
.table_container
  display: flex
  justify-content: center
  align-items: center


ul
  padding-left:0px
  list-style:none
  margin-top:1rem

.details
  display: none

.details .open
  display: table-cell

td

  line-height: 150%

th
  line-height: 150%
.btn-proposta
  background: #006992
  border-radius: 10px
  font-family: 'Poppins'
  font-style: normal
  font-weight: 500
  font-size: 1rem
  text-align: center
  letter-spacing: -0.01em
  color: #FFFFFF
  padding-top: 15px
  padding-bottom: 25px

.link-td
  display: flex
  margin-top: -0.6px
  padding-top: 8.5px

.left-td
  text-align: left !important

table
  border: 1px solid #D3D3D3

td:empty:before
  content: ""

input
  text-align: center
  border: none
   font: var(--unnamed-font-style-normal) normal medium 12px/31px Montserrat
  letter-spacing: var(--unnamed-character-spacing-0)
  text-align: center
  font: normal normal medium 12px/31px Montserrat
  font-size: 12px
  letter-spacing: 0px
  color: #3d3d3d
  opacity: 1
  vertical-align: middle !important
  line-height: 30px
  cursor: pointer

select
  text-align: center
  border: none
   font: var(--unnamed-font-style-normal) normal medium 12px/31px Montserrat
  letter-spacing: var(--unnamed-character-spacing-0)
  text-align: center
  font: normal normal medium 12px/31px Montserrat
  font-size: 12px
  letter-spacing: 0px
  color: #3d3d3d
  opacity: 1
  vertical-align: middle !important
  line-height: 30px
  cursor: pointer
  width: 80px
  margin-left: 5%
</style>
