<template>
    <div>
      <form @submit.prevent="createProposal()">
      <div>
          <div class="row">
            <div class="col-md-4 col-xl-2 col-md-3">
              <label for="meses" style="font-size: 12px">Meses do Contrato</label>
              <input
                style="
                  background: #f5f4fc 0% 0% no-repeat padding-box;
                  border-radius: 2px;
                  opacity: 1;
                "
                type="text"
                class="form-control noHover"
                id="meses"
                readonly
                :value="duration_in_months"
              />
            </div>
            <div class="col-md-4 col-xl-2 col-md-3">
              <label for="previsaoInicio" style="font-size: 12px"
                >Previsão de Início</label
              >
  
              <date-picker
                style="display: unset"
                format="DD/MM/YYYY"
                valueType="timestamp"
                input-class="form-control crm-input date-picker"
                id="previsaoInicio"
                v-model="proposal.start_date"
              ></date-picker>
            </div>
            <div class="col-md-4 col-xl-2 col-md-3">
              <label for="previsaoFim" style="font-size: 12px"
                >Previsão de Fim</label
              >
              <date-picker
                style="display: unset"
                format="DD/MM/YYYY"
                valueType="timestamp"
                input-class="form-control crm-input date-picker"
                id="previsaoFim"
                v-model="proposal.end_date"
              ></date-picker>
            </div>
            <div class="col-md-4 col-xl-3 col-md-3">
              <label for="valorContrato" style="font-size: 12px"
                >Valor do Contrato</label
              >
              <input
                style="
                  background: #f5f4fc 0% 0% no-repeat padding-box;
                  border-radius: 2px;
                  opacity: 1;
                "
                type="text"
                class="form-control noHover"
                id="valorMensal"
                readonly
                v-bind="proposal_format"
                v-model="totalContrato"
              />
            </div>
            <div class="col-md-4 col-xl-2 col-md-3">
              <label for="comissao" style="font-size: 12px">Comissão em %</label>
              <input
                style="
                  background: #f5f4fc 0% 0% no-repeat padding-box;
                  border-radius: 2px;
                  opacity: 1;
                "
                type="number"
                class="form-control"
                id="comissaoTotal"
                v-model="proposal.total_commission"
              />
            </div>
          </div>
        </div>
          <div class="d-flex justify-content-end mt-5" style="padding-bottom: 40px;">
            <button
              type="reset"
              class="btn btn-secondary py-2 px-4 float-md-right"
              style="
                background: #7E7E7E;
                border-radius: 8px;
                padding-left: 30px;
                padding-right: 30px;
                margin-right: 20px;
                border: none;
              "
              @click="
                () => {
                  this.$router.push('/PreContratos/');
                }
              "
            >
              Cancelar
            </button>
            <div
              :disabled="!formIsValid"
              @click="createProposal()"
              class="btn btn-primary"
              v-bind:style="[
                formIsValid
                  ? {
                    'background': '#006992',
                    'border-radius': '8px',
                    'padding-left': '30px',
                    'padding-right': '30px',
                    'cursor' : 'pointer',
                    'border': 'none'
                    }
                  : {
                    'background': '#CBCBCB',
                    'border-radius': '8px',
                    'padding-left': '30px',
                    'padding-right': '30px',
                    'cursor' : 'default',
                    'border': 'none',
                    'pointer-events': 'none' // pra bloquear o clique, ele tava deixando clicar mesmo mudando o estilo pra bloqueado
                    // aparentemente é pq o parametro de disabled nao funciona em div, apenas em botao (mas vou deixar o parametro
                    //  mesmo assim so por precaucao, nao sei se esta sendo usado pra outra coisa).
                    },
              ]"
            >
              Avançar
            </div>
          </div>
  
  
  
        <b-modal
          id="add-clients"
          size="xl"
          title="Adicionar Cliente"
          style="color: rgb(161, 0, 12) !important"
          hide-footer
        >
          <ClienteForm @added-client="addedClient"></ClienteForm>
        </b-modal>
      </form>
    </div>
  </template>
  
  <script>
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import "vue2-datepicker/locale/pt-br";
  import ClientService from "../../services/ClientService/ClientService";
  import ConsultantService from "../../services/ConsultantService/ConsultantService";
  import ProposalService from "../../services/ProposalsService/ProposalsService";
  import { DateTime } from "luxon";
  // import { getMonths } from "../../utils/util";
  import ProductProposalService from "../../services/ProductsProposalsService/ProductProposalsService";
  import ClienteForm from "../../components/Cliente/ClienteForm.vue";
  
  export default {
    components: {
      ClienteForm,
      DatePicker
    },
    props: ["value"],
    data() {
      return {
        productProposalService: new ProductProposalService(),
        products: [],
        client_service: new ClientService(),
        consultant_service: new ConsultantService(),
        proposal_service: new ProposalService(),
        proposal: {
          client: {},
          proposal_value: 0,
          total_commission: 0,
          proposal_total_value: 0
        },
        consultants_options: [],
        kind_options: ["Hunter", "Farmer", "Closer", "Outros"],
        proposal_format: {
          decimal: ",",
          separator: ".",
          prefix: "R$ ",
          precision: 2,
        },
        not_found: false,
        company_choices: [
          {value : "00", text: "MAQ-LAREM MAQUINAS MOVEIS E EQUIPAMENTOS LTDA"},
          {value : "01", text: "MAQ-LAREM FILIAL NATAL"},
          {value : "02", text: "MAQ-LAREM FILIAL PERNAMBUCO"},
          {value : "03", text: "ALFAPRINT LOCACOES EIRELLI - ME"},
          {value : "04", text: "SEVERINO MEDEIROS DO NASCIMENTO"},
          {value : "07", text: "MAQ-LAREM FILIAL ALAGOAS"},
          {value : "08", text: "TALLUM SOLUTION LOCACAO E COMERCIO DE EQUIPAMENTOS DE INFORM"}
        ]
      };
    },
    async mounted() {
      
      this.proposal = this.value;
      if (this.$route.params.cnpj){
        let cliente = await this.client_service.retrieve(this.$route.params.cnpj)
        this.proposal.client.cnpj = cliente.data.cnpj
      }
  
  
      if (this.proposal && this.proposal.client && this.proposal.client.nome) {
        this.requested_client = true;
      }
      
    
      this.consultant_service.list().then((r) => {
        this.consultants_options = r.data.results.map((r) => ({
          value: r,
          text: r.nome,
          nome: r.nome,
        }));
        this.consultants_options.push({
          value: null,
          text: "ADMIN",
          nome: "ADMIN"
        });
      });
      this.$refs["proposal_format_ref"].input({
        target: {
          value: this.proposal.proposal_value,
        },
      });
      if (this.$route.params.id) {
        this.productProposalService
          .list({ params: { proposal: this.$route.params.id } })
          .then(({ data }) => {
            this.products = data.results;
          });
      }
  
  
    },
    methods: {
      async loadProposal(proposal) {
        proposal.start_date = DateTime.fromISO(
          proposal.start_date
        ).toMillis();
        proposal.end_date = DateTime.fromISO(
          proposal.end_date
        ).toMillis();
        let { data: client } = await this.client_service.retrieve(
          proposal.client
        );
        proposal.client = client;
  
        this.proposal = proposal;
      },
      createProposal(stay) {
        let p = Object.assign({}, this.proposal);
        p.start_date = DateTime.fromMillis(p.start_date).toISODate();
        p.end_date = DateTime.fromMillis(p.end_date).toISODate();
        p.client = p.client.codigo;
        p.commissions = p.commissions
        .filter((o_c) => {
          return o_c.consultant && o_c.kind && o_c.commission_value;
        })
        .map((o_c) => {
          let c = Object.assign({}, o_c);
          if (c.consultant.value){
            c.consultant = c.consultant.value.id;
          }else{
            c.consultant = c.consultant.id;
          }
          if (isNaN(c.commission_value)) {
            c.commission_value = c.commission_value.replaceAll(",", ".");
          }
          return c;
        });
        this.proposal_service
          .update(p)
          .then((resp) => {
            this.loadProposal(resp.data);
          })
          .then(() => {
            if (!stay) {
              this.$emit("next", this.proposal.id);
            }
          });
        },
    },
    
    computed: {
      totalContrato(){
        return `R$ ${(this.proposal.proposal_total_value).toFixed(2)}`
      },
      formIsValid() {
        return (
          this.proposal.start_date &&
          this.proposal.end_date 
        );
      },
      duration_in_months() {
        if (this.proposal.start_date && this.proposal.end_date) {
          let d_start_date = DateTime.fromMillis(
            this.proposal.start_date
          );
          let d_end_date = DateTime.fromMillis(this.proposal.end_date);
          let months =
            d_end_date.diff(d_start_date, ["days"]).days / 30;
          //  let m = String(months).split('.')[0]
          //  m = parseInt(m)
  
          const monthString = (monthNumber) => {
            monthNumber = parseInt(monthNumber);
  
            if (monthNumber === 0) {
              return "";
            }
            if (monthNumber > 1) {
              return "Meses";
            }
            return "Mês";
          };
          return `${months.toFixed(0)} ${monthString(months.toFixed(0))}`;
        }
  
        return "";
      },
    }
  };
  </script>
  
  <style scoped lang="sass">
  @import "~/public/styles/sass/index.scss"
  td
    text-align: center
    line-height: 150%
  th
    line-height: 150%
  
  .step-description
    font-size: 21px
  
  .input-text
    font: var(--unnamed-font-style-normal) normal 600 13px/66px Montserrat
    letter-spacing: var(--unnamed-character-spacing-0)
    text-align: left
    font: normal normal 600 13px/66px Montserrat
    letter-spacing: 0px
    color: #ACACAC
    opacity: 1
  
  .crm-input
    background: #f5f4fc 0% 0% no-repeat padding-box !important
    border-radius: 2px
    opacity: 1
  
  
  </style>
  
  <style lang="sass">
  .date-picker
    background-color: #f5f4fc !important
    border-radius: 2px !important
  
  .stepper
    font-family: var(--fnt)
    font-size: var(--fnt-size)
    position: relative
    counter-reset: step 0
    width: 100%
    margin: 2rem 0
    display: flex
    flex-flow: row nowrap
    gap: 0
    justify-content: space-around
    align-items: flex-start
  
  .stepper > .step
    position: relative
    counter-increment: step
    width: 100%
    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: center
  
  .stepper > .step::before
    position: absolute
    content: ""
    width: 100%
    height: 2px
    background: var(--bs-gray-200)
    left: 0
    top: 1.1rem
  
  .stepper > .step > .step-label
    position: relative
    width: 2.2rem
    height: 2.2rem
    line-height: 2.2rem
    background: var(--bs-gray-200)
    color: var(--bs-gray-600)
    text-align: center
    border-radius: 50%
    margin: 0 auto
  
  .stepper > .step > .step-label::before
    content: counter(step)
  
  .stepper > .step.active::before
    background: linear-gradient(to right, var(--bs-pink), var(--bs-gray-200))
  
  .stepper > .step.active > .step-label
    background: var(--bs-pink)
    color: var(--bs-light)
    font-weight: bold
  
  .stepper > .step.completed::before
    background: var(--bs-pink)
  
  .stepper > .step.completed > .step-label
    background: var(--bs-pink)
    color: var(--bs-light)
  .stepper > .step > .step-description
    font-size: x-small
    color: var(--bs-gray-500)
    margin-top: 0.5rem
    overflow-wrap: break-word
    hyphens: auto
    text-align: center
  
  .stepper > .step.active > .step-description,
  .stepper > .step.completed > .step-description
    color: var(--bs-gray-800)
  
  .stepper > .step.active > .step-description
    font-weight: bold
  
  td select
  
    height: 35px
    border-color: #E5E5E5
    padding-left: 10px
    width: 150px
    border-radius: 5px
    outline: 0
  
  .select-td
    height: 35px
    border-color: #E5E5E5
    padding-left: 10px
    width: 150px
    border-radius: 5px
    outline: 0
  
  .input-td
  
    border-color: #E5E5E5
    padding-left: 10px
    width: 150px
    border-radius: 5px
    background: #f5f4fc 0% 0% no-repeat padding-box
    border-radius: 5px
    width: 70px
    height: 35px
    outline: 0
  </style>
  