import baseService from "../baseService";

/*
Steps Service
*/
const STEPS_ENDPOINT = "/api/steps/";
export default class StepsService extends baseService {

  constructor() {
    super(STEPS_ENDPOINT, "steps")
  }
}
