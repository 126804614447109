<template>
  <div>
    <form @submit.prevent="createProposal()">
      <div style="margin-bottom: 3%">
        <div class="row">
          <div class="form-group col-md-3">
            <label for="regiao">Empresa</label>
            <b-form-select
            style="
                background: #f5f4fc 0% 0% no-repeat padding-box;
                border-radius: 2px;
                opacity: 1;
              "
            v-model="proposal.company"
            :options="company_choices"
            ></b-form-select>
          </div>
          <div class="col-md-4 col-xl-2 col-md-3">
            <label for="regiao" style="font-size: 12px">CNPJ</label>
            <input
              style="
                background: #f5f4fc 0% 0% no-repeat padding-box;
                border-radius: 2px;
                opacity: 1;
              "
              type="text"
              class="form-control"
              id="cnpj"
              v-model="proposal.client.cnpj"
              required
              pattern="^[0-9-./-]{18}"
              oninvalid="this.setCustomValidity('Digite 14 números')"
              oninput="setCustomValidity('')"
            />
          </div>

          <div class="col-md-5">
            <label for="periodo" style="font-size: 12px">Nome</label>
            <input
              style="
                background: #f5f4fc 0% 0% no-repeat padding-box;
                border-radius: 2px;
                opacity: 1;
                text-transform: uppercase;
              "
              type="text"
              class="form-control"
              id="inputRazaoSocial"
              v-model="proposal.client.nome"
              required
              pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-/\W|_/.'\s ]+$"
              oninvalid="this.setCustomValidity('Digite apenas letras')"
              oninput="setCustomValidity('')"
            />
            <div style="padding-top: 10px; display: flex; align-items: center">
              <span
                v-b-modal.add-clients
                v-show="not_found"
                class="material-icons-outlined"
                style="color: #0bb4d4; float: left; font-size: 15px"
              >
                add_circle
              </span>
              <u
                ><a
                  style="color: #0bb4d4; font-size: 14px; padding-left: 10px"
                  v-b-modal.add-clients
                  v-show="not_found"
                  >CLIENTE NÃO REGISTRADO, CLIQUE PARA REGISTRAR</a
                ></u
              >
            </div>
          </div>
          <div class="col-xl-2 col-md-3">
            <label for="comissao" style="font-size: 12px">Comissão em %</label>
            <input
              style="
                background: #f5f4fc 0% 0% no-repeat padding-box;
                border-radius: 2px;
                opacity: 1;
              "
              type="number"
              class="form-control"
              id="comissaoTotal"
              v-model="proposal.total_commission"
            />
          </div>
        </div>

        <div class="row">
          <!-- <div class="col-md-4 col-xl-2 col-md-3">
            <label for="valorMensal" style="font-size: 12px"
              >Valor Mensal</label
            >
            <number
              v-model="proposal.proposal_value"
              v-bind="proposal_format"
              class="form-control crm-input"
              ref="proposal_format_ref"
            ></number>
          </div>
          <div class="col-md-4 col-xl-2 col-md-3">
            <label for="meses" style="font-size: 12px">Meses do Contrato</label>
            <input
              style="
                background: #f5f4fc 0% 0% no-repeat padding-box;
                border-radius: 2px;
                opacity: 1;
              "
              type="text"
              class="form-control noHover"
              id="meses"
              readonly
              :value="duration_in_months"
            />
          </div>
          <div class="col-md-4 col-xl-2 col-md-3">
            <label for="previsaoInicio" style="font-size: 12px"
              >Previsão de Início</label
            >

            <date-picker
              style="display: unset"
              format="DD/MM/YYYY"
              valueType="timestamp"
              input-class="form-control crm-input date-picker"
              id="previsaoInicio"
              v-model="proposal.start_date"
            ></date-picker>
          </div>
          <div class="col-md-4 col-xl-2 col-md-3">
            <label for="previsaoFim" style="font-size: 12px"
              >Previsão de Fim</label
            >
            <date-picker
              style="display: unset"
              format="DD/MM/YYYY"
              valueType="timestamp"
              input-class="form-control crm-input date-picker"
              id="previsaoFim"
              v-model="proposal.end_date"
            ></date-picker>
          </div>
          <div class="col-md-4 col-xl-2 col-md-3">
            <label for="valorContrato" style="font-size: 12px"
              >Valor do Contrato</label
            >
            <input
              style="
                background: #f5f4fc 0% 0% no-repeat padding-box;
                border-radius: 2px;
                opacity: 1;
              "
              type="text"
              class="form-control noHover"
              id="valorMensal"
              readonly
              :value="total_value"
            />
          </div>
          <div class="col-md-4 col-xl-2 col-md-3">
            <label for="comissao" style="font-size: 12px">Comissão em %</label>
            <input
              style="
                background: #f5f4fc 0% 0% no-repeat padding-box;
                border-radius: 2px;
                opacity: 1;
              "
              type="number"
              class="form-control"
              id="comissaoTotal"
              v-model="proposal.total_commission"
            />
          </div> -->
          <!-- <div
            style="display: flex; justify-content: flex-start; width: 82.506rem"
          >
            <p
              class="title"
              style="font-size: 14px; width: 400px; margin-left: 1.5%"
            >
              LOCAIS DOS EQUIPAMENTOS
            </p>
          </div>
          <div class="row gx-5" style="padding-left: 1.5%; margin-top: -15px">
            <div class="col-xl-2 col-md-3">
              <label for="regMet" style="font-size: 12px; width: 10rem"
                >Expectativa Reg. Metrop.</label
              >
              <input
                style="
                  background: #f5f4fc 0% 0% no-repeat padding-box;
                  border-radius: 2px;
                  opacity: 1;
                "
                type="text"
                class="form-control"
                id="regMet"
                v-model="proposal.expected_metropolitan"
              />
            </div>
            <div class="col-xl-2 col-md-3">
              <label for="interior" style="font-size: 12px"
                >Expectativa Interior</label
              >
              <input
                style="
                  background: #f5f4fc 0% 0% no-repeat padding-box;
                  border-radius: 2px;
                  opacity: 1;
                "
                type="text"
                class="form-control"
                id="interior"
                v-model="proposal.expected_countryside"
              />
            </div>
            <div class="col-xl-2 col-md-3">
              <label for="regMet" style="font-size: 12px; width: 10rem"
                >Realidade Reg. Metrop.</label
              >
              <input
                style="
                  background: #f5f4fc 0% 0% no-repeat padding-box;
                  border-radius: 2px;
                  opacity: 1;
                  color: #88878d
                "
                type="text"
                class="form-control"
                id="regMet"
                v-model="proposal.actual_metropolitan"
              />
            </div>
            <div class="col-xl-2 col-md-3">
              <label for="interior" style="font-size: 12px"
                >Realidade Interior</label
              >
              <input
                style="
                  background: #f5f4fc 0% 0% no-repeat padding-box;
                  border-radius: 2px;
                  opacity: 1;
                  color: #88878d
                "
                type="text"
                class="form-control"
                id="interior"
                v-model="proposal.actual_countryside"
              />
            </div>
          </div> -->
        </div>
      </div>

      <div class="row" style="margin-top: -25px">
        <div class="col-xl-6">
          <p class="title" style="font-size: 14px">EQUIPAMENTOS E SERVIÇOS</p>
          <table class="table table-sm mt-0 ajust-table space-table">
            <thead>
              <tr
                style="
                  background: #f9f9f9 0% 0% no-repeat padding-box;
                  border: 0.5px solid #e1e1e1;
                  border-radius: 10px;
                  opacity: 1;
                "
              >
                <th scope="col" class="text-center">Tipo</th>
                <th scope="col" class="text-center">Contratada</th>
                <!-- <th scope="col" class="text-center">Digitada</th> -->
                <th scope="col" class="text-center">Aprovada</th>
                <!-- <th scope="col">Status</th> -->
                <th scope="col" class="text-center">Liberada</th>
              </tr>
            </thead>

            <tbody>
              <tr>

                <td> IMPRESSORAS </td>

                <td>
                  <b-form-input
                    class="input-equipamentos"
                    name="impContratada"
                    id="impContratada"
                    style="
                    border-radius: 2px;
                    background: #fff 0% 0% no-repeat padding-box;
                  "
                    type="text"
                    v-model="proposal.printer_ref_qt"
                  />
                </td>
                <!-- <td>{{ amounts.printers.amount }}</td> -->
                <td>{{ amounts.printers.allowed }}</td>
                <td>{{ amounts.printers.released }}</td>
              </tr>
              <tr>
                <td>SOFTWARES</td>
                <td>
                  <b-form-input
                    class="input-equipamentos"
                    name="sofContratada"
                    id="sofContratada"
                    type="text"
                    v-model="proposal.software_ref_qt"
                  />
                </td>
                <!-- <td>{{ amounts.softwares.amount }}</td> -->
                <td>{{ amounts.softwares.allowed }}</td>
                <td>{{ amounts.softwares.released }}</td>
              </tr>
              <tr>

                <td>PCS E DESKTOPS</td>

                <td>
                  <b-form-input
                    class="input-equipamentos"
                    name="pcContratada"
                    id="pcContratada"
                    type="text"
                    v-model="proposal.pc_ref_qt"
                  />
                </td>
                <!-- <td>{{ amounts.pcs.amount }}</td> -->
                <td>{{ amounts.pcs.allowed }}</td>
                <td>{{ amounts.pcs.released }}</td>
              </tr>
              <tr>
                <td>TÉRMICAS E PLOTTERS</td>
                <td>
                  <b-form-input
                    class="input-equipamentos"
                    name="termicaContratada"
                    id="termicaContratada"
                    type="text"
                    v-model="proposal.termic_ref_qt"
                  />
                </td>
                <!-- <td>{{ amounts.termicas.amount }}</td> -->
                <td>{{ amounts.termicas.allowed }}</td>
                <td>{{ amounts.termicas.released }}</td>
              </tr>
              <tr>
                <td>MÃO DE OBRA/HORAS</td>
                <td>
                  <b-form-input
                    class="input-equipamentos"
                    name="maoContratada"
                    id="maoContratada"
                    style="background: #fff 0% 0% no-repeat padding-box;"
                    type="text"
                    v-model="proposal.handwork_ref_qt"
                  />
                </td>
                <!-- <td>{{ amounts.maodeObra.amount }}</td> -->
                <td>{{ amounts.maodeObra.allowed }}</td>
                <td>{{ amounts.maodeObra.released }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-xl-6">
          <p class="title" style="font-size: 14px">
            COMISSÕES
            <span
              :style="{
                color:
                  computed_total_commission === proposal.total_commission
                    ? 'green'
                    : 'red',
              }"
              >({{ computed_total_commission }}% de
              {{ proposal.total_commission }}%)
              <span v-if="proposal_ok">- {{ proposal_ok }}</span>
              <span v-else>- Os valores devem ser iguais para prosseguir.</span>
              </span

            >
          </p>
          <table class="table table-sm mt-0 ajust-table" id="principal">
            <tr
              style="
                background: #f9f9f9 0% 0% no-repeat padding-box;
                border: 0.5px solid #e1e1e1;
                border-radius: 10px;
                opacity: 1;
              "
            >
              <th scope="col" class="text-center">Consultor</th>
              <th scope="col" class="text-center">Tipo</th>
              <th scope="col" class="text-center">Comissão</th>

              <th scope="col"></th>
            </tr>

            <tr
              v-for="(commission, index) in proposal.commissions"
              :key="index"
            >
              <td style="padding-left: 0">

                <vue-select :options="consultants_options"
                  label="nome"
                  :reduce="consultants_option => consultants_option.value"
                  :value="commission.consultant"
                  style="
                      border-radius: 2px;
                      background: #fff 0% 0% no-repeat padding-box;
                    "
                  @input="(consultant) => { inputcommission(index, consultant)}"
                  ></vue-select>

                <!-- <b-form-select
                  style="
                    border-radius: 2px;
                    background: #fff 0% 0% no-repeat padding-box;
                  "
                  id="consultor"
                  v-model="commission.consultant"
                  :options="consultants_options"
                  :label-field="'nome'"
                  @change="updateConsultants(commission)"
                ></b-form-select>-->
              </td>
              <td>
                <b-form-input v-model="commission.kind" list="tipo"
                @change="updateConsultants(commission)"
                style="
                    border-radius: 2px;
                    width: 6rem;
                    background: #fff 0% 0% no-repeat padding-box;
                    padding-right: 2px;
                  "
                ></b-form-input>
                <datalist id="tipo">
                    <option v-for="(kind, i) in kind_options" :key="i">{{ kind }}</option>
                </datalist>
               <!-- Versao antiga para consulta -->
                <!-- <b-form-select
                  id="tipo"
                  style="
                    border-radius: 2px;
                    width: 6rem;
                    background: #fff 0% 0% no-repeat padding-box;
                    padding-right: 2px;
                  "
                  v-model="commission.kind"
                  :options="kind_options"
                  @change="updateConsultants(commission)"
                ></b-form-select> -->
              </td>
              <td>
                <b-form-input
                  style="
                    border-radius: 2px;
                    width: 6rem;
                    background: #fff 0% 0% no-repeat padding-box;
                  "
                  name="comissao"
                  id="comissao"
                  type="text"
                  v-model="commission.commission_value"
                  @change="updateConsultants(commission)"
                />
              </td>

              <td>
                <span
                  class="material-icons"
                  style="cursor: pointer; font-size: 20px; margin-top: 7px"
                  @click="removeParcela(index)"
                >
                  delete
                </span>
              </td>
            </tr>
            <a
              style="
                color: #9b9b9b;
                text-decoration: none;
                cursor: pointer;
                font-size: 11px;
                white-space: nowrap;
              "
              @click="adicionarParcela"
              >TOQUE PARA ADICIONAR NOVO ITEM</a
            >
          </table>
          
        </div>
      </div>
        <div class="d-flex justify-content-end" style="padding-bottom: 40px;">
          <button
            type="reset"
            class="btn btn-secondary py-2 px-4 float-md-right"
            style="
              background: #7E7E7E;
              border-radius: 8px;
              padding-left: 30px;
              padding-right: 30px;
              margin-right: 20px;
              border: none;
            "
            @click="
              () => {
                this.$router.push('/PreContratos/');
              }
            "
          >
            Cancelar
          </button>
          <div
            :disabled="!formIsValid"
            @click="createProposal()"
            class="btn btn-primary"
            v-bind:style="[
              formIsValid
                ? {
                  'background': '#006992',
                  'border-radius': '8px',
                  'padding-left': '30px',
                  'padding-right': '30px',
                  'cursor' : 'pointer',
                  'border': 'none'
                  }
                : {
                  'background': '#CBCBCB',
                  'border-radius': '8px',
                  'padding-left': '30px',
                  'padding-right': '30px',
                  'cursor' : 'default',
                  'border': 'none',
                  'pointer-events': 'none' // pra bloquear o clique, ele tava deixando clicar mesmo mudando o estilo pra bloqueado
                  // aparentemente é pq o parametro de disabled nao funciona em div, apenas em botao (mas vou deixar o parametro
                  //  mesmo assim so por precaucao, nao sei se esta sendo usado pra outra coisa).
                  },
            ]"
          >
            Avançar
          </div>
        </div>



      <b-modal
        id="add-clients"
        size="xl"
        title="Adicionar Cliente"
        style="color: rgb(161, 0, 12) !important"
        hide-footer
      >
        <ClienteForm @added-client="addedClient"></ClienteForm>
      </b-modal>
    </form>
  </div>
</template>

<script>
// import { number } from "@coders-tm/vue-number-format";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import ClientService from "../../services/ClientService/ClientService";
import ConsultantService from "../../services/ConsultantService/ConsultantService";
import ProposalService from "../../services/ProposalsService/ProposalsService";
import { DateTime } from "luxon";
// import { getMonths } from "../../utils/util";
import ProductProposalService from "../../services/ProductsProposalsService/ProductProposalsService";
import ClienteForm from "../../components/Cliente/ClienteForm.vue";

export default {
  components: {
    ClienteForm,
    // DatePicker,
    // number,
  },
  props: ["value"],
  data() {
    return {
      productProposalService: new ProductProposalService(),
      products: [],
      client_service: new ClientService(),
      consultant_service: new ConsultantService(),
      proposal_service: new ProposalService(),
      proposal: {
        client: {
          cnpj: 0,
        },
        proposal_value: 0,
        total_commission: 0,
      },
      consultants_options: [],
      kind_options: ["Hunter", "Farmer", "Closer", "Outros"],
      proposal_format: {
        decimal: ",",
        separator: ".",
        prefix: "R$ ",
        precision: 2,
      },
      not_found: false,
      company_choices: [
        {value : "00", text: "MAQ-LAREM MAQUINAS MOVEIS E EQUIPAMENTOS LTDA"},
        {value : "01", text: "MAQ-LAREM FILIAL NATAL"},
        {value : "02", text: "MAQ-LAREM FILIAL PERNAMBUCO"},
        {value : "03", text: "ALFAPRINT LOCACOES EIRELLI - ME"},
        {value : "04", text: "SEVERINO MEDEIROS DO NASCIMENTO"},
        {value : "07", text: "MAQ-LAREM FILIAL ALAGOAS"},
        {value : "08", text: "TALLUM SOLUTION LOCACAO E COMERCIO DE EQUIPAMENTOS DE INFORM"}
      ]
    };
  },
  async mounted() {
    
    this.proposal = this.value;
    if (this.$route.params.cnpj){
      let cliente = await this.client_service.retrieve(this.$route.params.cnpj)
      this.proposal.client.cnpj = cliente.data.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      this.proposal.client.nome = cliente.data.nome
    }


    if (this.proposal && this.proposal.client && this.proposal.client.nome) {
      this.requested_client = true;
    }
    
  
    this.consultant_service.list().then((r) => {
      this.consultants_options = r.data.results.map((r) => ({
        value: r,
        text: r.nome,
        nome: r.nome,
      }));
      this.consultants_options.push({
        value: null,
        text: "ADMIN",
        nome: "ADMIN"
      });
    });
    this.$refs["proposal_format_ref"].input({
      target: {
        value: this.proposal.proposal_value,
      },
    });
    if (this.$route.params.id) {
      this.productProposalService
        .list({ params: { proposal: this.$route.params.id } })
        .then(({ data }) => {
          this.products = data.results;
        });
    }


  },
  methods: {
    inputcommission(commission_index, consultant) {
      this.$set(this.proposal.commissions, commission_index, Object.assign({}, this.proposal.commissions[commission_index], { consultant: consultant }))
      this.updateConsultants(this.proposal.commissions[commission_index])
    },
    adicionarParcela: function () {
      if (this.proposal.commissions.length < 3) {
        this.proposal.commissions.push({
          consultant: this.consultants_options[0],
          kind: this.kind_options[0],
          commission_value: 0
        });
      }
    },
    removeParcela(index) {
      this.proposal.commissions.splice(index, 1);
    },

    updateConsultants(commission) {
      if (
        commission.consultant &&
        commission.kind &&
        commission.commission_value
      ) {
        this.createProposal(true);
      }
    },
    async loadProposal(proposal) {
      proposal.start_date = DateTime.fromISO(
        proposal.start_date
      ).toMillis();
      proposal.end_date = DateTime.fromISO(
        proposal.end_date
      ).toMillis();
      let { data: client } = await this.client_service.retrieve(
        proposal.client
      );
      proposal.client = client;

      let consultants_list = new Set(
        proposal.commissions.map((p) => p.consultant)
      );
      let consultants = await this.consultant_service.detailed_list(
        Array.from(consultants_list)
      );
      for (let index = 0; index < proposal.commissions.length; index++) {
        const element = proposal.commissions[index];
        element.consultant = consultants[element.consultant];
      }

      this.proposal = proposal;
    },
    createProposal(stay) {
      let p = Object.assign({}, this.proposal);
      p.start_date = DateTime.fromMillis(p.start_date).toISODate();
      p.end_date = DateTime.fromMillis(p.end_date).toISODate();
      p.client = p.client.codigo;

      p.commissions = p.commissions
        .filter((o_c) => {
          return o_c.consultant && o_c.kind && o_c.commission_value;
        })
        .map((o_c) => {
          let c = Object.assign({}, o_c);
          if (c.consultant.value){
            c.consultant = c.consultant.value.id;
          }else{
            c.consultant = c.consultant.id;
          }
          if (isNaN(c.commission_value)) {
            c.commission_value = c.commission_value.replaceAll(",", ".");
          }
          return c;
        });

      if (this.proposal.id) {
        this.proposal_service
          .update(p)
          .then((resp) => {
            this.loadProposal(resp.data);
          })
          .then(() => {
            if (!stay) {
              this.$emit("next", this.proposal.id);
            }
          });
      } else {
        this.proposal_service
          .create(p)
          .then((resp) => {
            return this.loadProposal(resp.data);
          })
          .then(() => {
            if (!stay) {
              this.$emit("next", this.proposal.id);
            }
          });
      }
    },
    addedClient(client) {
      this.proposal.client.cnpj = " ";
      this.$nextTick(() => {
        Object.assign(this.proposal.client, client);
      });

      this.$bvModal.hide("add-clients");
    },
    formatCNPJ(cnpj) {
      if (!cnpj) {
        return cnpj;
      }
      let v = cnpj.replaceAll(/[^\d]/g, "").slice(0, 14);
      return v.replace(
        /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
        "$1.$2.$3/$4-$5"
      );
    },
  },
  watch: {
    "proposal.client.cnpj": {
      handler(value, oldValue) {
        let v = value.replaceAll(/[^\d]/g, "").slice(0, 14);
        let f_v = this.formatCNPJ(value);
        let old_f_v = this.formatCNPJ(oldValue);
        while (!/\d/.test(f_v[f_v.length - 1]) && f_v.length > 0) {
          f_v = f_v.slice(0, f_v.length - 1);
        }
        console.log(old_f_v, 'jidbh')
        if (v.length >= 14) {
          if (old_f_v != f_v) {
            this.requested_client = false;
          }
          if (!this.requested_client) {
            this.not_found = false;
            this.client_service
              .list({ params: { cnpj: f_v.replaceAll(/\D/g, "") } })
              .then((client) => {
                let results = client.data.results;
                if (results.length) {
                  //Liste os clientes baseado no editado mais recentemente
                  results = results.sort((a, b) => {
                    if (a.dtalt == null) {
                      return 1;
                    }
                    if (b.dtalt == null) {
                      return -1;
                    }
                    return a.dtalt - b.dtalt;
                  });
                  Object.assign(this.proposal.client, results[0])
                } else {
                  this.not_found = true;
                }
              });
            this.requested_client = true;
          }
        } else {
          this.requested_client = false;
          this.not_found = false;
        }

        this.proposal.client.cnpj = f_v;
      },
    },
  },
  computed: {
    formIsValid() {
      return (
        this.proposal.client.cnpj &&
        this.proposal.client.nome &&
        this.computed_total_commission === this.proposal.total_commission // pra so deixar o botao de avançar ativo se o total de comissoes for igual ao total proposto
      );
    },
    duration_in_months() {
      if (this.proposal.start_date && this.proposal.end_date) {
        let d_start_date = DateTime.fromMillis(
          this.proposal.start_date
        );
        let d_end_date = DateTime.fromMillis(this.proposal.end_date);
        let months =
          d_end_date.diff(d_start_date, ["days"]).days / 30;
        //  let m = String(months).split('.')[0]
        //  m = parseInt(m)

        const monthString = (monthNumber) => {
          monthNumber = parseInt(monthNumber);

          if (monthNumber === 0) {
            return "";
          }
          if (monthNumber > 1) {
            return "Meses";
          }
          return "Mês";
        };
        return `${months.toFixed(0)} ${monthString(months.toFixed(0))}`;
      }

      return "";
    },
    total_value() {
      let p_start = this.proposal.start_date;
      let p_end = this.proposal.end_date;
      let p_value = this.proposal.proposal_value;

      if (!(p_start && p_end && p_value)) {
        return "";
      }

      let d_start_date = DateTime.fromMillis(p_start);
      let d_end_date = DateTime.fromMillis(p_end);
      let months = d_end_date.diff(d_start_date, ["days"]).days / 30;
      if (months > 0) {
        let per_month = p_value * months.toFixed(0);
        return `R$ ${per_month.toFixed(2)}`.replaceAll(".", ",");
      }
      return "";
    },
    computed_total_commission() {
      let total = 0;
      if (this.proposal.commissions) {
        this.proposal.commissions.forEach((commission) => {
          if (!isNaN(commission.commission_value)) {
            total += Number(commission.commission_value);
          }
        });
      }
      return !isNaN(total) ? total : 0;
    },
    proposal_ok() {
      if (this.computed_total_commission === this.proposal.total_commission) {
        return "OK";
      }
      return "";
    },

    printers() {
      let products = this.products;
      return products.filter((p) => p.product_obj.type == 0);
    },
    softwares() {
      let products = this.products;
      return products.filter((p) => p.product_obj.type == 1);
    },
    pcs() {
      let products = this.products.filter((p) => p.product_obj.type == 2);
      return products;
    },
    termicas() {
      let products = this.products.filter((p) => p.termic);
      return products;
    },
    maodeObra() {
      let products = this.products.filter((p) => p.product_obj.type == 4);
      return products;
    },
    amounts() {
      let printers = this.printers;
      let softwares = this.softwares;
      let pcs = this.pcs;
      let termicas = this.termicas;
      let maodeObra = this.maodeObra;

      let get_qtd = (products) => {
        let qtd = {
          amount: 0,
          allowed: 0,
          released: 0,
        };
        for (let index = 0; index < products.length; index++) {
          const element = products[index];
          qtd.amount =
            qtd.amount + (isNaN(element.amount) ? 0 : element.amount);
          qtd.allowed =
            qtd.allowed + (isNaN(element.allowed) ? 0 : element.allowed);
          qtd.released =
            qtd.released + (isNaN(element.released) ? 0 : element.released);
        }

        return qtd;
      };

      const id = this.$route?.params?.id;

      return {
        printers: id ? get_qtd(printers) : [],
        softwares: id ? get_qtd(softwares) : [],
        pcs: id ? get_qtd(pcs) : [],
        termicas: id ? get_qtd(termicas) : [],
        maodeObra: id ? get_qtd(maodeObra) : [],
      };
    },
  },
};
</script>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"
td
  text-align: center
  line-height: 150%
th
  line-height: 150%

.step-description
  font-size: 21px

.input-text
  font: var(--unnamed-font-style-normal) normal 600 13px/66px Montserrat
  letter-spacing: var(--unnamed-character-spacing-0)
  text-align: left
  font: normal normal 600 13px/66px Montserrat
  letter-spacing: 0px
  color: #ACACAC
  opacity: 1

.crm-input
  background: #f5f4fc 0% 0% no-repeat padding-box !important
  border-radius: 2px
  opacity: 1


</style>

<style lang="sass">
.date-picker
  background-color: #f5f4fc !important
  border-radius: 2px !important

.stepper
  font-family: var(--fnt)
  font-size: var(--fnt-size)
  position: relative
  counter-reset: step 0
  width: 100%
  margin: 2rem 0
  display: flex
  flex-flow: row nowrap
  gap: 0
  justify-content: space-around
  align-items: flex-start

.stepper > .step
  position: relative
  counter-increment: step
  width: 100%
  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: center

.stepper > .step::before
  position: absolute
  content: ""
  width: 100%
  height: 2px
  background: var(--bs-gray-200)
  left: 0
  top: 1.1rem

.stepper > .step > .step-label
  position: relative
  width: 2.2rem
  height: 2.2rem
  line-height: 2.2rem
  background: var(--bs-gray-200)
  color: var(--bs-gray-600)
  text-align: center
  border-radius: 50%
  margin: 0 auto

.stepper > .step > .step-label::before
  content: counter(step)

.stepper > .step.active::before
  background: linear-gradient(to right, var(--bs-pink), var(--bs-gray-200))

.stepper > .step.active > .step-label
  background: var(--bs-pink)
  color: var(--bs-light)
  font-weight: bold

.stepper > .step.completed::before
  background: var(--bs-pink)

.stepper > .step.completed > .step-label
  background: var(--bs-pink)
  color: var(--bs-light)
.stepper > .step > .step-description
  font-size: x-small
  color: var(--bs-gray-500)
  margin-top: 0.5rem
  overflow-wrap: break-word
  hyphens: auto
  text-align: center

.stepper > .step.active > .step-description,
.stepper > .step.completed > .step-description
  color: var(--bs-gray-800)

.stepper > .step.active > .step-description
  font-weight: bold

td select

  height: 35px
  border-color: #E5E5E5
  padding-left: 10px
  width: 150px
  border-radius: 5px
  outline: 0

.select-td
  height: 35px
  border-color: #E5E5E5
  padding-left: 10px
  width: 150px
  border-radius: 5px
  outline: 0

.input-td

  border-color: #E5E5E5
  padding-left: 10px
  width: 150px
  border-radius: 5px
  background: #f5f4fc 0% 0% no-repeat padding-box
  border-radius: 5px
  width: 70px
  height: 35px
  outline: 0
</style>
