<template>
	<div class="font ajust-margin">
		<div class="row" style="margin-top: -30px">
			<div class="form-group col-sm-12 col-md-4">
				<label
					for=""
					style="font-size: 16px; font-weight: bold; color: #878ea4"
					>Tipo de Equipamento</label
				>

				<b-select
					id="lista"
					class="form-control"
					required
					style="
						background: #f5f4fc 0% 0% no-repeat padding-box;
						border-radius: 2px;
						opacity: 1;
						text-transform: uppercase;
					"
					v-model="selected_device"
					:options="devices_options"
				>
				</b-select>
			</div>
		</div>
		<impressora-scanner-vue
			v-if="selected_device == 'impressoraScanner'"
			v-model="product"
			@added-product="
				() => {
					this.$emit('added-product', this.product);
				}
			"
			@cancel="cancelled"
		></impressora-scanner-vue>
		<mao-de-obra-vue
			v-if="selected_device == 'maodeObra'"
			v-model="product"
			@added-product="
				() => {
					this.$emit('added-product', this.product);
				}
			"
			@cancel="cancelled"
		></mao-de-obra-vue>

		<pcs-vue
			v-if="selected_device == 'pcs'"
			v-model="product"
			@added-product="
				() => {
					this.$emit('added-product', this.product);
				}
			"
			@cancel="cancelled"
		></pcs-vue>
		<softwares-vue
			v-if="selected_device == 'software'"
			v-model="product"
			@added-product="
				() => {
					this.$emit('added-product', this.product);
				}
			"
			@cancel="cancelled"
		></softwares-vue>
		<termicas-plotter-vue
			v-if="selected_device == 'termicasPlotter'"
			v-model="product"
			@added-product="
				() => {
					this.$emit('added-product', this.product);
				}
			"
			@cancel="cancelled"
		></termicas-plotter-vue>
	</div>
</template>

<script>
import ImpressoraScannerVue from "../ProductProposal/ImpressoraScanner.vue";
import MaoDeObraVue from "../ProductProposal/MaodeObra.vue";
import PcsVue from "../ProductProposal/Pcs.vue";
import SoftwaresVue from "../ProductProposal/Softwares.vue";
import TermicasPlotterVue from "../ProductProposal/TermicasPlotter.vue";
import ProductProposalService from "../../services/ProductsProposalsService/ProductProposalsService";

export default {
	components: {
		ImpressoraScannerVue,
		MaoDeObraVue,
		PcsVue,
		SoftwaresVue,
		TermicasPlotterVue,
	},

	props: {
		selected_device: {
			default: "impressoraScanner"
		}
	},

	data() {
		return {
			devices_options: [
				{ value: "impressoraScanner", text: "Impressoras e Scanners" },
				{ value: "software", text: "Softwares" },
				{ value: "pcs", text: "PCs e Desktops" },
				{
					value: "termicasPlotter",
					text: "Térmicas, Plotters e Matriciais",
				},
				{ value: "maodeObra", text: "Mão de Obra" },
			],
			product_service: new ProductProposalService(),
			product: {
				desc: "",
				minimum_specification: "",
				accessories: "",
				codigo: "",
				numserie: "",
				coddepto: "",
				contato: "",
				custo: null,
				dataInstalacao: null,
				dtalt: null,
				dtcad: null,
				dataDevolucao: null,
				franqcolor: null,
				franqdg: null,
				franqgf: null,
				franqgfc: null,
				franqpb: null,
				franqtotal: null,
				horas: null,
				horasatende: null,
				horaspapel: null,
				horassup: null,
				ip: "",
				libleitura: "",
				local: "",
				netname: "",
				obs: "",
				opalt: "",
				opcad: "",
				orig: "",
				pat: "",
				situacao: "",
				tipoequip: "",
				tipohoras: null,
				tipointerv: null,
				proposal: null,
				product: null,
				site: undefined,
			},
		};
	},
	methods: {
		cancelled() {
			this.$bvModal.hide("add-product");
		},
	},

	mounted() {
		let type = this.$route.params.type;

		if (type) {
			this.selected_device = type;
		}
	},
};
</script>

<style></style>
