import axios from "axios";
import Constants from "../../core/constants/Constants";
import { setCookie, getCookie, eraseCookie } from "../../utils/util";
import { router } from "../../routes/router";
import Swal from "sweetalert2";
/*
Auth Service
*/
export default class AuthService {
  LOGIN_ENDPOINT = "/api/login/";
  REFRESH_ENDPOINT = "/api/refresh-token/";

  async login(auth) {
    console.log("running auth");

    const res = await axios
      .post(Constants.BASE_URL + this.LOGIN_ENDPOINT, JSON.stringify(auth), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        console.log("LOGIN ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Email ou senha inválidos",
        });
      });

    if (res && res.status == 200) {
      const data = res.data;

      setCookie("access", data["access"], 1400);
      setCookie("refresh", data["refresh"], 1400);
      router.push("/");
    }
  }

  async refreshToken() {
    console.log("running refreshToken");
    const res = await axios.post(
      Constants.BASE_URL + this.REFRESH_ENDPOINT,
      { refresh: getCookie("refresh") },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (res) {
      const data = res.data;

      setCookie("access", data["access"], 1400);
    } else {
      this.logout();
    }
  }

  logout() {
    eraseCookie("workspace");
    eraseCookie("access");
    eraseCookie("refresh");
    router.push("/login");
  }
}
