import apiClient from "../apiClient";
import baseService from "../baseService";

/*
Proposal Service
*/
const PROPOSALS_ENDPOINT = "/api/proposals/";
export default class ProposalService extends baseService {
	constructor() {
		super(PROPOSALS_ENDPOINT, "proposal");
	}

	pdf(obj) {
		return apiClient.get(`${this.endpoint}` + obj + "/" + "get_pdf/", {
			headers: {
				"Content-Type": "application/pdf",
			},
			responseType: 'blob', // important
		});
	}

	insert_into_databit(obj, codigo_databit) {
		return apiClient.post(`${this.endpoint}` + obj + "/" + "insert_into_databit/", {"codigo_databit":codigo_databit});
	}
}
