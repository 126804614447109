<template>
	<div>
		<p class="title" style="font-size: 14px; line-height: 30px">
			ENDEREÇOS DE EQUIPAMENTOS
		</p>

		<div style="padding-bottom: 60px;">
			<table class="table table-sm mt-0">
				<thead>
					<tr
						style="
							background: #f9f9f9 0% 0% no-repeat padding-box;
							border: 0.5px solid #e1e1e1;
							border-radius: 10px;
							opacity: 1;
						"
					>
						<th style="width: 2rem"></th>
						<th class="text-center" scope="col">Modelo</th>
						<th class="text-center" scope="col">Situação</th>

						<th class="text-center" scope="col">Nome Setor</th>
						<th class="text-center" scope="col">Endereço</th>
						<th class="text-center" scope="col">Cidade</th>
						<th class="text-center" scope="col">Estado</th>
						<th class="text-center" scope="col">Dia e Horário</th>
						<th class="text-center" scope="col"></th>
						<th class="text-center" scope="col"></th>
						<th></th>
						<th></th>
						<th></th>

	
					</tr>
				</thead>
				
				<tbody style="border: none">
					<tr v-for="(p_s, i) in product_sites" :key="i">
						<template v-if="p_s.site !== undefined">
							<td>{{ i + 1 }}</td>
							<td class="left-td">
								{{ p_s.product.product_obj.nome }}
							</td>
							<td>{{ p_s.product.new ? "Novo" : "Usado" }}</td>

							<td>{{ p_s.site.department }}</td>
							<td>{{ p_s.site.logradouro }}</td>
							<td>{{ p_s.site.cidade }}</td>
							<td>{{ p_s.site.estado }}</td>
							<td>
								{{ p_s.site.date_and_time | format_date }}
							</td>
							<td>
								<router-link
									style="text-decoration: none; color: white"
									:to="{
										name: 'editEndereco',
										params: {
											id: proposal.id,
											product_id: p_s.product.id,
											codigo_endereco: p_s.site.codigo,
										},
									}"
								>
									<span
										class="material-icons-outlined"
										style="
											color: #384364;
											cursor: pointer;
											font-size: 17px;

											padding-top: 10%;
										"
									>
										edit
									</span>
								</router-link>
							</td>
							<td>
								<span
									class="material-icons-outlined"
									style="
										color: #384364;
										cursor: pointer;
										font-size: 17px;

										padding-top: 10%;
									"
									@click="deleteEndereco(p_s.site.codigo)"
								>
									delete
								</span>
							</td>
							<td>
								<span
									@click="openModal(p_s)"
									v-b-tooltip.hover
									title="Multiplicar endereço do produto"
									class="material-icons-outlined"
									style="
										color: #384364;
										cursor: pointer;
										font-size: 15px;

										padding-top: 10%;
									"
									v-bind="attrs"
									v-on="on"
								>
									content_copy
								</span>
								<!-- </router-link> -->
							</td>
							<td>
								<span
									@click="attIdEndereco(p_s.site.codigo)"
									v-b-tooltip.hover
									title="Copiar endereço"
									class="material-icons-outlined"
									style="
										color: #384364;
										cursor: pointer;
										font-size: 15px;

										padding-top: 10%;
									"
									v-bind="attrs"
									v-on="on"
								>
									add_box
								</span>
								<!-- </router-link> -->
							</td>
						</template>
						<template v-else>
							<td>{{ i + 1 }}</td>
							<td class="left-td">
								{{ p_s.product.product_obj.nome }}
							</td>
							<td>{{ p_s.product.new ? "Novo" : "Usado" }}</td>

							<td>-</td>
							<td>-</td>
							<td>-</td>
							<td>-</td>
							<td>-</td>
							<td class="remove"></td>
							<td class="remove"></td>
							<td>
								<router-link
									style="text-decoration: none; color: white"
									:to="{
										name: 'novoEndereco',
										params: {
											id: proposal.id,
											product_id: p_s.product.id,
										},
									}"
								>
									<span
										v-b-tooltip.hover
										title="Cadastrar novo endereço de entrega do produto"
										class="material-icons-outlined"
										style="
											color: #384364;
											cursor: pointer;
											font-size: 17px;

											padding-top: 10%;
										"
										v-bind="attrs"
										v-on="on"
									>
										local_shipping
									</span>
								</router-link>
							</td>
							<td>
								<router-link
									style="text-decoration: none; color: white"
									:to="{
										name: 'novoEnderecoCopy',
										params: {
											id: proposal.id,
											product_id: p_s.product.id,
											id_endereco: id_old_endereco
										},
									}"
								>
									<span
										v-b-tooltip.hover
										title="Cadastrar endereço copiado"
										class="material-icons-outlined"
										style="
											color: #384364;
											cursor: pointer;
											font-size: 17px;

											padding-top: 10%;
										"
										v-bind="attrs"
										v-on="on"
									>
										check_box
									</span>
								</router-link>
							</td>
						</template>
					</tr>
				</tbody>
				
			</table>
		</div>

		<div class="d-flex justify-content-end" style="padding-bottom: 40px">
			<button
				type="submit"
				class="btn btn-secondary"
				style="
					background: #7E7E7E;
					border-radius: 8px;
					padding-left: 30px;
					padding-right: 30px;
					margin-right: 20px;
					border: none;
				"
				@click="
					() => {
						this.$emit('cancel');
					}
				"
			>
				Voltar
			</button>
			<button
				type="submit"
				class="btn btn-primary"
				style="
					background: #C84995;
					border-radius: 8px;
					padding-left: 30px;
					padding-right: 30px;
					border: none;
					margin-right: 20px;
				"
				@click="getPdf"
			>
				Ver resumo
			</button>
			<button
				type="submit"
				class="btn btn-primary"
				style="
					background: #006992;
					border-radius: 8px;
					padding-left: 30px;
					padding-right: 30px;
					border: none
				"
				@click="startAprovalls"
			>
				Enviar para aprovações
			</button>
		</div>

			<b-modal id="multiply_site" title="Multiplicar Endereços">
				<div>
					<b-row>
						<b-col style="margin-bottom: 10px" class="mt-3">
							<span class="input-title"> Quantidade </span>
							<input
								required
								type="text"
								class="form-control"
								id="multiply"
								style="
									border-radius: 2px;
									width: 450px;
									height: 30px;
									background-color: #f5f4fc;
								"
								v-model="qtde"
							/>
						</b-col>
					</b-row>
				</div>
				<template #modal-footer="{ cancel }">
					<div
						style="
							display: flex;
							justify-content: center;
							border-top: 0;
						"
						class="w-100"
					>
						<b-button
							size="sm"
							class="btn-reprove"
							@click="cancel()"
						>
							<span>FECHAR</span>
						</b-button>
						<b-button
							size="sm"
							class="btn-approve"
							id="resolveButton"
							@click="multiply_site(current_obj, qtde)"
						>
							MULTIPLICAR
						</b-button>
					</div>
				</template>
			</b-modal>
		</div>
</template>

<script>
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import ProductProposalService from "../../services/ProductsProposalsService/ProductProposalsService";
import ProposalsService from "../../services/ProposalsService/ProposalsService";
import SiteService from "../../services/SiteService/SiteService";

export default {
	props: ["value"],
	data() {
		return {
			siteService: new SiteService(),
			proposalsService: new ProposalsService(),
			productProposalService: new ProductProposalService(),
			products: [],
			enderecos: [],
			id_old_endereco: ''
		};
	},
	created() {
		this.proposal = this.value;
	},
	methods: {
		async startAprovalls() {
			let proposal = await this.proposalsService.retrieve(this.$route.params.id)
			if (proposal.data.start_date && proposal.data.end_date){
				this.proposalsService
					.update({ id: this.$route.params.id, started: 2 })
					.then(() => {
						this.$router.push({ name: "listApprovals" });
					});
			} else {
				Swal.fire({
					title: "Falha!",
					text: "Precisa preencher a data de inicio e data de fim do pré-contrato antes de enviar para aprovações",
					icon: "error",
					showCancelButton: false,
					confirmButtonColor: "#0bb4d4",
					cancelButtonColor: "#A9A9A9",
					confirmButtonText: "OK",
					cancelButtonText: "Cancelar",
				})
			}
		},
		async deleteEndereco(id) {
			Swal.fire({
				title: "Certeza que deseja deletar?",
				text: "Essa ação não poderá ser desfeita",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#0bb4d4",
				cancelButtonColor: "#A9A9A9",
				confirmButtonText: "Deletar",
				cancelButtonText: "Cancelar",
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					//Fazer cliente sumir da página
					this.siteService.destroy(id).then(({ data }) => {
						console.log(data);
						this.reload();
					});
					Swal.fire(
						"Deletado!",
						"Endereço deletado com sucesso",
						"success"
					);
				}
			});
		},
		openModal(obj) {
			this.current_obj = obj;
			this.$root.$emit("bv::show::modal", "multiply_site", "#btnShow");
		},
		getPdf() {
			//Referencia: https://stackoverflow.com/questions/52878170/rendering-pdf-blob-on-chrome
			this.proposalsService
				.pdf(this.$route.params.id)
				.then((response) => {
					const url = window.URL.createObjectURL(
						new Blob([response.data], { type: "application/pdf" })
					);
					window.open(url, "_blank");
					this.$router.push("/PreContratos");
				});
		},

		attIdEndereco(id){
			this.id_old_endereco = id
			Swal.fire(
				"Copiado!",
				"Endereço copiado com sucesso",
				"success"
			);
		},

		getProductName(productId) {
			console.log(this.products);
			return this.products.filter(function (obj) {
				return obj.id === productId;
			})[0].product_obj.nome;
		},

		getProductSituacao(productId) {
			return this.products.filter(function (obj) {
				return obj.id === productId;
			})[0].new
				? "Novo"
				: "Usado";
		},

		multiply_site(obj, amount) {
			let promise = new Promise((resolve) => {
				resolve();
			});
			promise = this.siteService
				.multiply_site(obj, amount)
				.then(
					Swal.fire({
						text: "Endereços multiplicados com sucesso!",
						icon: "success",
						showConfirmButton: true,
						confirmButtonText: "Ok",
						confirmButtonColor: "#0bb4d4",
					})
				)
				.catch(() => {
					console.log("catch");
					Swal.fire({
						title: "Erro!",
						text: "Não foi possível multiplicar os endereços, produtos insuficientes",
						icon: "error",
						showCancelButton: false,
						confirmButtonColor: "#0bb4d4",
						cancelButtonColor: "#A9A9A9",
						confirmButtonText: "OK",
						cancelButtonText: "Cancelar",
					});
				});

			promise.then(() => {
				this.reload();
			});
			this.$root.$emit("bv::hide::modal", "multiply_site", "#btnShow");
		},
		reload() {
			this.siteService
				.list({ params: { product_proposal: this.$route.params.id } })
				.then(({ data }) => {
					this.enderecos = data.results;
					console.log(this.enderecos);
				});
			this.productProposalService
				.list({ params: { proposal: this.$route.params.id } })
				.then(({ data }) => {
					this.products = data.results;
				});
		},

		

		// duplicateEntrega(siteCodigo) {
		// 	let promise = new Promise((resolve) => {
		// 		resolve();
		// 	});

		// 	promise = Swal.fire({
		// 		text: "Endereço duplicado com sucesso.",
		// 		icon: "success",
		// 		showConfirmButton: true,
		// 		confirmButtonText: "Ok",
		// 		confirmButtonColor: "#0bb4d4",
		// 	})
		// 		.then(
		// 			this.siteService.retrieve(siteCodigo).then((result) => {
		// 				let entrega = Object.assign({}, result.data);
		// 				delete entrega.codigo; // pra criar uma nova com os mesmos dados

		// 				this.siteService.create(entrega).then((res) => {
		// 					console.log(res.data);

		// 					this.siteService.add_product_proposal(
		// 						// visualizar no back depois se realmente funciona igual a maneira como está no editEndereco
		// 						res.data,
		// 						res.data.product[0] // pois como foi discutido, cada produto tem uma ocorrencia apenas, entao so vai ter 1 produto por endereco, ja que a duplicacao é realmente um novo objeto de endereco
		// 					);
		// 				});
		// 			})
		// 		)

		// 		.catch(() => {
		// 			console.log("catch");
		// 			Swal.fire({
		// 				title: "Erro!",
		// 				text: "Não foi possivel adicionar o endereço, verifique o formulário",
		// 				icon: "error",
		// 				showCancelButton: false,
		// 				confirmButtonColor: "#0bb4d4",
		// 				cancelButtonColor: "#A9A9A9",
		// 				confirmButtonText: "OK",
		// 				cancelButtonText: "Cancelar",
		// 			});
		// 		});

		// 	promise.then(() => {
		// 		document.location.reload();
		// 	});
		// },
	},
	mounted() {
		this.reload();
	},

	watch: {
		proposal() {
			this.$emit("input", this.proposal);
		},
	},
	filters: {
		format_date(date) {
			let o_date = DateTime.fromISO(date);
			return o_date.toFormat("dd/MM/y hh:mm");
		},
	},
	computed: {
		product_sites() {
			let re = [];
			let products = this.products;
			products.map((e) => {
				e.sites.map((s) => {
					re.push({
						product: e,
						site: s,
					});
				});
			});
			products
				.filter((p) => p.sites.length == 0)
				.map((p) => {
					re.push({
						product: p,
					});
				});
			return re;
		},
	},
};
</script>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"

td
  text-align: center
  line-height: 150%
th
  line-height: 150%
.btn-proposta
  background: #0bb4d4 0% 0% no-repeat padding-box
  border-width: 0px
  border-radius: 100px
  opacity: 1
  width: 155px
  height: 46px
  color: #ffffff
  font: normal normal bold 11px/11px Montserrat
  letter-spacing: 1.1px

.left-td
    text-align: left !important

.remove:empty:before
    content: ''

.btn-reprove
  background-color: #f25d5d
  margin-right: 20px
  width: 130px
  height: 40px
  border-radius: 150px
  border: none
  font-size: 11px
  font-family: "Montserrat", sans-serif
  opacity: 1

.btn-reprove:hover
  background-color: #f25d5d
  margin-right: 20px
  width: 130px
  height: 40px
  border-radius: 150px
  border: none
  font-size: 11px
  font-family: "Montserrat", sans-serif
  opacity: 1

.btn-approve
  background-color: #0bb4d4
  width: 130px
  border-radius: 150px
  border: none
  font-size: 11px
  font-family: "Montserrat", sans-serif
  opacity: 1


.btn-approve:hover
  background-color: #0bb4d4
  width: 130px
  border-radius: 150px
  border: none
  font-size: 11px
  font-family: "Montserrat", sans-serif
  opacity: 1
</style>
