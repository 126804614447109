import Vue from "vue";
import VueRouter from "vue-router";

let authPage = Vue.component("AuthPage", () =>
	import("../pages/Auth/AuthPage.vue")
);
let clientesPage = Vue.component("ClientesPage", () =>
	import("../pages/Clientes/ClientesPage.vue")
);
let usuariosPage = Vue.component("UsuariosPage", () =>
	import("../pages/Usuarios/UsuariosPage.vue")
);
let propostasPage = Vue.component("PropostasPage", () =>
	import("../pages/Propostas/PropostasPage.vue")
);
let contratosPage = Vue.component("ContratosPage", () =>
	import("../pages/Contratos/ContratosPage.vue")
);
let configuracoesPage = Vue.component("ConfiguracoesPage", () =>
	import("../pages/Configuracoes/ConfiguracoesPage.vue")
);
let gestaoDocumentosPage = Vue.component("GestaoDocumentos", () =>
	import("../pages/GestaoDocumentos/GestaoDocumentos.vue")
);

let homePage = Vue.component("HomePage", () =>
	import("../pages/Home/HomePage.vue")
);
let edicaoPage = Vue.component("EditarCliente", () =>
	import("../pages/EditarCliente/EditarCliente.vue")
);
let edicaoPageUsuario = Vue.component("EditarUsuario", () =>
	import("../pages/EditarUsuario/EditarUsuario.vue")
);

let adicionarClientePage = Vue.component("AdicionarCliente", () =>
	import("../pages/AdicionarCliente/AdicionarCliente.vue")
);

let novoEnderecoPage = Vue.component("NovoEndereco", () =>
	import("../pages/NovoEndereco/NovoEndereco.vue")
);

let editEnderecoPage = Vue.component("NovoEndereco", () =>
	import("../pages/NovoEndereco/EditEndereco.vue")
);

let novoRepresentantePage = Vue.component("RepresentanteForm", () =>
	import("../pages/AdicionarRepresentante/AdicionarRepresentante.vue")
);

import Approvals from "../pages/Approvals/Approvals.vue";
import AddDocumentPage from "../pages/Propostas/AddDocument.vue";
import NovaPropostaPage from "../pages/Propostas/NovaProposta.vue";

import addProduct from "../pages/Precontrato/AddProduct.vue";
import editProduct from "../pages/Precontrato/EditProduct";
import linkProduct from "../pages/Precontrato/LinkProduct";
import novoPreContratoPage from "../pages/Precontrato/NovoPreContrato.vue";
import PreContratosPage from "../pages/Precontrato/PreContratos.vue";

const routes = [
	{
		path: "/login",
		component: authPage,
		meta: { requiresNavBar: false, requiresNavLateral: false },
	},
	{
		path: "/",
		component: homePage,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},
	{
		path: "/clientes",
		component: clientesPage,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},
	{
		path: "/novoRepresentante",
		component: novoRepresentantePage,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},

	{
		path: "/usuarios",
		component: usuariosPage,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},
	{
		path: "/gestaoDocumentos",
		component: gestaoDocumentosPage,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},
	{
		path: "/configuracoes",
		component: configuracoesPage,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},
	{
		path: "/propostas",
		component: propostasPage,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},
	{
		path: "/approvals",
		component: {
			render(h) {
				return h("router-view");
			},
		},
		children: [
			{
				name: "listApprovals",
				path: "",
				component: Approvals,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
		],
	},
	{
		path: "/contratos",
		component: contratosPage,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},
	{
		path: "/PreContratos/",
		component: {
			render(h) {
				return h("router-view");
			},
		},
		children: [
			{
				name: "listPrecontrato",
				path: "",
				component: PreContratosPage,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
			{
				name: "novoPrecontrato",
				path: "novo",
				component: novoPreContratoPage,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
			{
				name: "novoPrecontrato",
				path: "novo/:cnpj",
				component: novoPreContratoPage,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
			{
				name: "AddProduct",
				path: ":id/AddProduct/:type?",
				component: addProduct,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
			{
				name: "editProduct",
				path: ":id/:product_id/:type/edit",
				component: editProduct,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
			{
				name: "linkProduct",
				path: ":id/:product_id/:type/link",
				component: linkProduct,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
			{
				name: "novoEndereco",
				path: ":id/:product_id/novoEndereco/",
				component: novoEnderecoPage,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
			{
				name: "novoEnderecoCopy",
				path: ":id/:product_id/novoEndereco/:id_endereco",
				component: novoEnderecoPage,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
			{
				name: "editEndereco",
				path: ":id/:product_id/:codigo_endereco/editEndereco",
				component: editEnderecoPage,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},

			{
				name: "editPrecontrato",
				path: ":id/:step?",
				component: novoPreContratoPage,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
		],
	},
	{
		path: "/editarCliente/:id",
		component: edicaoPage,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},
	{
		path: "/adicionarCliente",
		component: adicionarClientePage,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},
	{
		path: "/editarUsuario",
		component: edicaoPageUsuario,
		meta: { requiresNavBar: true, requiresNavLateral: true },
	},
	{
		path: "/proposta/",
		component: {
			render(h) {
				return h("router-view");
			},
		},
		children: [
			{
				name: "novaProposta",
				path: "new",
				component: NovaPropostaPage,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
			{
				name: "editProposta",
				path: ":id",
				component: NovaPropostaPage,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
			{
				name: "addDoc",
				path: ":id/docs",
				component: AddDocumentPage,
				meta: { requiresNavBar: true, requiresNavLateral: true },
			},
		],
	},
];

export const router = new VueRouter({
	routes, // short for `routes: routes`
});
