var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font ajust-margin",staticStyle:{"margin-top":"80px","margin-left":"95px"}},[_c('div',{staticStyle:{"background":"#FFFFFF","box-shadow":"0px 10px 10px rgba(226, 236, 249, 0.5)","border-radius":"30px","padding-left":"38px","padding-right":"38px"}},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"padding-top":"28px","margin-bottom":"60px"}},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.params.id ? "Editar" : "Novo")+" Pré-contrato ")])]),_c('div',{staticClass:"stepper row"},[_c('div',{staticClass:"step col-3",class:{
          active: this.current_step == 1,
          completed: this.current_step > 1,
        }},[_c('div',{staticClass:"step-label",style:({ cursor: this.current_step != 1 ? 'pointer' : '' }),on:{"click":() => {
              this.current_step = 1;
              this.$router.push({
                name: 'editPrecontrato',
                params: { id: this.$route.params.id, step: 1 },
              });
            }}}),_vm._m(0)]),_c('div',{staticClass:"step col-3",class:{
          active: this.current_step == 2,
          completed: this.current_step > 2,
        }},[_c('div',{staticClass:"step-label",style:({
            cursor: this.current_step != 2 && _vm.$route.params.id ? 'pointer' : '',
          }),on:{"click":() => {
              if (this.$route.params.id) {
                this.current_step = 2;
                this.$router.push({
                  name: 'editPrecontrato',
                  params: { id: this.$route.params.id, step: 2 },
                });
              }
            }}}),_c('div',{staticClass:"step-description"},[_vm._v("Contrato")])]),_c('div',{staticClass:"step col-3",class:{
          active: this.current_step == 3,
          completed: this.current_step > 3,
        }},[_c('div',{staticClass:"step-label",style:({
            cursor: this.current_step != 3 && _vm.$route.params.id ? 'pointer' : '',
          }),on:{"click":() => {
              if (this.$route.params.id) {
                this.current_step = 3;
                this.$router.push({
                  name: 'editPrecontrato',
                  params: { id: this.$route.params.id, step: 3 },
                });
              }
            }}}),_vm._m(1)]),_c('div',{staticClass:"step col-3",class:{
          active: this.current_step == 4,
          completed: this.current_step > 4,
        }},[_c('div',{staticClass:"step-label",style:({
            cursor: this.current_step != 4 && _vm.$route.params.id ? 'pointer' : '',
          }),on:{"click":() => {
              if (this.$route.params.id) {
                this.current_step = 4;
                this.$router.push({
                  name: 'editPrecontrato',
                  params: { id: this.$route.params.id, step: 4 },
                });
              }
            }}}),_vm._m(2)])]),(_vm.current_step == 1)?_c('Step1Vue',{key:_vm.updated,attrs:{"route":"this.$route"},on:{"next":_vm.nextStep},model:{value:(_vm.proposal),callback:function ($$v) {_vm.proposal=$$v},expression:"proposal"}}):_vm._e(),(_vm.current_step == 2)?_c('Step2Vue',{key:_vm.updated,on:{"next":_vm.nextStep,"cancel":_vm.previousStep},model:{value:(_vm.proposal),callback:function ($$v) {_vm.proposal=$$v},expression:"proposal"}}):_vm._e(),(_vm.current_step == 4)?_c('Step3Vue',{key:_vm.updated,on:{"cancel":_vm.previousStep},model:{value:(_vm.proposal),callback:function ($$v) {_vm.proposal=$$v},expression:"proposal"}}):_vm._e(),(_vm.current_step == 3)?_c('Step4Vue',{key:_vm.updated,on:{"next":_vm.nextStep,"cancel":_vm.previousStep},model:{value:(_vm.proposal),callback:function ($$v) {_vm.proposal=$$v},expression:"proposal"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-description"},[_vm._v(" Dados "),_c('br'),_vm._v(" Básicos ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-description"},[_vm._v("Detalhes do"),_c('br'),_vm._v("Contrato")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-description"},[_vm._v(" Operação e "),_c('br'),_vm._v(" Endereços ")])
}]

export { render, staticRenderFns }