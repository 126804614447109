<template>
	<div>
		<b-row>
			<b-col>
				<span style="font-size: 12px; font-family: montserrat">
					Titulo do Arquivo
				</span>
				<input
					disabled
					type="text"
					class="form-control"
					id="name"
					v-model="value.name"
					@change="onChange"
				/>
			</b-col>
		</b-row>
		<div
			style="background-color: #f5f4fc; margin-top: 1rem; padding: 1.5rem"
			:style="{ 'background-color': active ? '#c9c8cf' : '#f5f4fc' }"
			@dragenter.prevent="setActive"
			@dragover.prevent="setActive"
			@dragleave.prevent="setInactive"
			@drop.prevent="onDrop"
		>
			<input
				type="file"
				id="input"
				name="arquivo"
				style="display: none"
				ref="file-input"
				@change="onChange"
			/>
			<b-row align-h="center">
				<b-col cols="12" style="font-size: 44px; text-align: center">
					<b-icon-cloud-arrow-up-fill></b-icon-cloud-arrow-up-fill>
				</b-col>
				<b-col style="text-align: center">
					<div>Arraste o arquivo aqui</div>
					<div>ou</div>
					<div
						style="
							text-decoration: underline;
							color: #0bb4d4;
							cursor: pointer;
						"
						@click="openDialog()"
					>
						SELECIONE O ARQUIVO
					</div>
				</b-col>
			</b-row>
		</div>
		<div style="margin-top: -20px">
			<button
				type="submit"
				class="btn btn-secondary py-2 px-4"
				style="
					font-size: 11px;
					margin-top: 50px;
					background: #878787 0% 0% no-repeat padding-box;
					border-radius: 100px;
					opacity: 1;
				"
				@click="closeModal"
			>
				CANCELAR
			</button>
			<button
				type="submit"
				class="btn btn-primary py-2 px-4"
				style="
					font-size: 11px;
					margin-top: 50px;
					margin-left: 10px;
					background: #0bb4d4 0% 0% no-repeat padding-box;
					border-radius: 100px;
					opacity: 1;
				"
				@click="closeModal"
			>
				SALVAR
			</button>
		</div>
	</div>
</template>

<script>
export default {
	props: ["value", "modalId"],
	methods: {
		setInactive() {
			this.active = false;
		},
		setActive() {
			this.active = true;
		},

		onDrop(e) {
			//carrega documento
			this.active = false;
			this.loadFiles(e.dataTransfer.files);

			this.value.name = e.dataTransfer.files[0].name.replace(
				/\.[^/.]+$/,
				""
			);
			console.log(e);
		},
		openDialog() {
			this.$refs["file-input"].click();
		},
		onChange(e) {
			//popula os inputs
			const fileInput = document
				.getElementById("input")
				.value.replace(/.*(\/|\\)/, "")
				.replace(/\.[^/.]+$/, "");
			this.loadFiles(e.target.files);

			this.value.name = fileInput;
		},
		closeModal() {
			this.$emit('modalClosed');
			this.$root.$emit("bv::hide::modal", this.modalId);
		},
		loadFiles(files) {
			for (const file of files) {
				this.value.file = file;
			}
			if (this.value.name) {
				this.$root.$emit("bv::show::modal", this.modalId);
			}
			this.$emit("input", this.value);
		},
	},

	data() {
		return {
			active: false,
		};
	},
};
</script>

<style></style>
