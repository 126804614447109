<template>
	<div class="font ajust-margin" style="margin-top: 80px; margin-right: 95px; margin-left: 95px;">
		<div style="background: #FFFFFF; box-shadow: 0px 10px 10px rgba(226, 236, 249, 0.5);; border-radius: 30px;">
			<div style="padding-top: 28px; margin-left: 38px;  margin-right: 38px; margin-bottom:60px" class="d-flex justify-content-between">
				<p class="title">Pré-contratos</p>
				<div class="d-flex">
					<div style="margin-right:30px">
						<proposal-filters-vue v-model="filters"></proposal-filters-vue>
					</div>
					
					<router-link :to="{ name: 'novoPrecontrato' }">
						<button class="btn-proposta"> 
							Novo pré-contrato
						</button>
					</router-link>
					
				</div>
			</div>
			<table>
				<thead>
					<tr style="border-bottom: 1px solid #EEEEEE;">
						<th scope="col" style="padding-bottom: 14px; text-align: left; padding-left: 38px;">Cliente</th>
						<th scope="col">Data</th>
						<th scope="col">Valor</th>
						<th scope="col">Pendências</th>
						<th scope="col">Documentos</th>
						<th scope="col" width="10%"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(proposal, i) in proposals" :key="i" style="border-bottom: 1px solid #EEEEEE; padding-left: 38px !important">
						<td style="text-align: left; padding-left: 38px; text-transform: uppercas; padding-top: 16px; padding-bottom: 16px;border-bottom: 1px solid #EEEEEE; padding-left: 38px !important" >
							{{ proposal.client.nome }}
						</td>
						<!-- <td style="">
				{{ proposal.consultant_name }}
			</td> -->
						<td style="">
							{{ proposal.created_at | format_date }}
						</td>
						<td style="">
							{{ proposal.proposal_total_value }}
						</td>
						<td style="">Sem Pendências</td>
						<td>
							<span
								@click="openModal(proposal.id)"
								class="material-icons"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 15px;
									margin-right: 5%;
									padding-top: 10%;
								"
							>
								description
							</span>
						</td>
						<td>
							<span
								class="material-icons-outlined"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 16px;
									margin-right: 5%;
								"
								@click="startProposal(proposal)"
								v-b-tooltip.hover
								title="Enviar pré-contrato para o fluxo de aprovações"
							>
								play_arrow
							</span>

							<router-link
								:to="{
									name: 'editPrecontrato',
									params: { id: proposal.id },
								}"
							>
								<span
									style="
										color: #384364;
										cursor: pointer;
										font-size: 16px;
										margin-right: 5%;
										padding-top: 10%;
									"
									class="material-icons-outlined"
								>
									edit
								</span>
							</router-link>

							<span
								class="material-icons-outlined"
								@click="deleteProposal(proposal.id)"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 16px;
								"
							>
								delete
							</span>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="d-flex justify-content-end" style="margin-top: 34px; margin-right:40px; padding-bottom: 40px;">
				<v-pagination
				class="justify-content-end"
				v-model="page"
				:length="1"
				></v-pagination>
			</div>
		</div>



		<b-modal
			scrollable="true"
			size="lg"
			id="add-document"
			title="Documentos do Pré-Contrato"
			hide-footer="true"
		>
			<button
				id="novoArquivo"
				class="btn-proposta"
				style="
					height: 30px;
					margin-top: 5px;
					float: right;
					margin-right: 15px;
				"
				@click="exibe"
			>
				NOVO ARQUIVO
			</button>
			<b-row id="escolha" class="hideMe">
				<div
					style="
						width: 100%;
						display: flex;
						align-items: center;
						margin-left: 10px;
						margin-right: 35px;
					"
				>
					<b-col id="etapa">
						<span class="input-title"> Escolha um Arquivo </span>
						<input
							style="
								border-radius: 2px;
								width: 450px;
								padding-bottom: 5px;
								background-color: #f5f4fc;
							"
							type="file"
							class="form-control"
							id="document-name"
							@change="onChange"
						/>
					</b-col>
					<div style="margin-top: 21px">
						<b-button
							id="secondButton"
							size="sm"
							class="btn-reprove hideMe"
							@click="esconde"
						>
							<span>CANCELAR</span>
						</b-button>
						<b-button
							id="aprovar"
							size="sm"
							class="btn-approve hideMe"
							@click="attachDocument"
						>
							SALVAR
						</b-button>
					</div>
				</div>
			</b-row>
			<div style="margin-bottom: 10px">
				<b-row style="margin-top: 10px; width: 100%">
					<table
						class="table table-sm mt-3"
						style="
							border: 1px solid #ced4da;
							height: 38px;
							width: 100%;
							margin-left: 25px;
						"
					>
						<thead>
							<tr
								style="
									background: #f9f9f9 0% 0% no-repeat
										padding-box;
									border: 1px solid #e1e1e1;
									border-radius: 4px;
									opacity: 1;
								"
							>
								<th style="padding-left: 11px">Data</th>
								<th>Autor do Upload</th>

								<th>Arquivo</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(doc, i) in docs" :key="i">
								<td>
									{{ doc.created_at | format_date }}
								</td>
								<td>
									{{ doc.consultant.first_name }}
								</td>

								<td>
									<a :href="doc.file">{{ doc.name }}</a>
								</td>

								<td>
									<span
										class="material-icons-outlined"
										@click="deleteDoc(doc.id)"
										style="
											margin-top: 10px;
											color: #384364;
											cursor: pointer;
											font-size: 16px;
										"
									>
										delete
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</b-row>
			</div>
		</b-modal>
		

		
	</div>
</template>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"
.hideMe
  display: none !important

.dark-button

  background: #384364 0% 0% no-repeat padding-box
  opacity: 1
  top: 189px
  left: 330px
  width: 206px
  height: 40px

.light-button
  top: 189px
  left: 535px
  width: 229px
  height: 40px
  background: #ffffff 0% 0% no-repeat padding-box
  border: 1px solid #eaecef
  opacity: 1

.dark-text

  color: var(--unnamed-color-384364)
  text-align: center
  font: normal normal bold 10px/11px Montserrat
  letter-spacing: 0.25px
  color: #384364
  text-transform: uppercase
  opacity: 0.6

.light-text
  text-align: center
  font: normal normal bold 10px/11px Montserrat
  letter-spacing: 0.25px
  color: #FFFFFF
  text-transform: uppercase
  opacity: 1

.container
  padding: 0px
  margin: 0px
  display: flex
  justify-content: space-between

.btn-proposta
  background: #0bb4d4 0% 0% no-repeat padding-box
  border-width: 0px
  border-radius: 100px
  opacity: 1
  width: 155px
  height: 46px
  color: #ffffff
  font: normal normal bold 11px/11px Montserrat
  letter-spacing: 1.1px

.dark-button:focus
  outline-style: none
.light-button:focus
  outline-style: none

.div-botoes
  margin-left: -1.1%
</style>
<style scoped>

th{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */
	letter-spacing: -0.01em;
	color: #B5B7C0;
}

td{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */

	letter-spacing: -0.01em;
	color: #292D32;
}
.btn-proposta{
	background: #006992;
	border-radius: 10px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height */

	text-align: center;
	letter-spacing: -0.01em;

	color: #FFFFFF;
}
.title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 33px;
	/* identical to box height */
	letter-spacing: -0.01em;
	color: #000000;
}
.btn-reprove {
	background-color: #f25d5d;
	margin-right: 20px;
	width: 120px;
	height: 30px;
	border-radius: 150px;
	border: none;
	font-size: 11px;

	font-family: "Montserrat", sans-serif;
	opacity: 1;
}

.btn-reprove:hover {
	background-color: #f25d5d;
	margin-right: 20px;
	width: 120px;
	height: 30px;
	border-radius: 150px;
	border: none;
	font-size: 11px;

	font-family: "Montserrat", sans-serif;
	opacity: 1;
}
.btn-approve {
	background-color: #0bb4d4;
	width: 120px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 30px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}

.btn-approve:hover {
	background-color: #0bb4d4;
	width: 120px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 30px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}
.btn-approve:focus {
	background-color: #0bb4d4;
	width: 120px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 30px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}
::-webkit-file-upload-button {
	display: none;
}
</style>
<script>
import $ from "jquery";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import ProposalFiltersVue from "../../components/Common/ProposalFilters.vue";
import ClientService from "../../services/ClientService/ClientService";
import ConsultantsService from "../../services/ConsultantService/ConsultantService";
import DocumentsService from "../../services/DocumentsService/DocumentsService";
import ProposalsService from "../../services/ProposalsService/ProposalsService";
import UserService from "../../services/UserService/UserService";

export default {
	components: {
		ProposalFiltersVue,
	},
	computed: {
		current_doc() {
			for (let index = 0; index < this.docs.length; index++) {
				const element = this.docs[index];
				if (element.status) {
					return element;
				}
			}
			return false;
		},
	},
	data() {
		return {
			consultant_service: new ConsultantsService(),
			proposal_service: new ProposalsService(),
			client_services: new ClientService(),
			documents_service: new DocumentsService(),
			user_service: new UserService(),
			proposals: [],
			clients: [],

			document: {
				proposal: null,
				name: "",
				file: "",
			},

			doc: false,
			docs: [],
			users: {},

			filters: {
				status: null,
				region: null,
				period: [],
			},
		};
	},
	mounted() {
		this.reload();
		for (let obj of this.proposals) {
			this.getConsultantName(obj).then(
				({ res }) => (obj.consultant_name = res)
			);
		}
	},
	methods: {
		onChange(e) {
			//popula os inputs
			const fileInput = document
				.getElementById("document-name")
				.value.replace(/.*(\/|\\)/, "")
				.replace(/\.[^/.]+$/, "");

			for (const file of e.target.files) {
				this.document.file = file;
			}
			// document.getElementById("document-name").innerHTML = fileInput;
			this.document.name = fileInput;
		},

		openModal(proposalId) {
			this.document.proposal = proposalId;
			this.reloadDocument();
			this.$root.$emit("bv::show::modal", "add-document", "#btnShow");
		},
		attachDocument() {
			let doc = new FormData();
			for (const key in this.document) {
				if (Object.hasOwnProperty.call(this.document, key)) {
					const element = this.document[key];
					console.log(key);
					console.log(element);
					doc.append(key, element);
				}
			}

			this.documents_service.create(doc).then(() => {
				this.reloadDocument();
				Object.assign(this.document, { name: "", file: null });
			});

			document.getElementById("document-name").value = "";
		},
		reloadDocument() {
			this.documents_service
				.list({ params: { proposal: this.document.proposal } })
				.then((resp) => {
					this.docs = this.load_docs(resp.data.results);
				});
		},
		load_docs(docs) {
			for (let index = 0; index < docs.length; index++) {
				const element = docs[index];
				if (
					Object.hasOwnProperty.call(this.users, element.consultant)
				) {
					element.consultant = this.users[element.consultant];
				} else {
					if (element.consultant) {
						this.user_service
							.retrieve(element.consultant)
							.then((resp) => {
								this.users[element.consultant] = resp.data;
								element.consultant =
									this.users[element.consultant];
							});
					}
				}
			}
			return docs;
		},
		deleteDoc(docId) {
			console.log(docId);
			this.documents_service.destroy(docId).then(({ data }) => {
				console.log(data);
				this.reloadDocument();
			});
		},
		handleDeleteOk() {
			this.documents_service.destroy(this.doc.id).then(() => {
				this.reload();
			});
		},
		async handleOk() {
			if (this.current_doc) {
				await this.documents_service.update({
					id: this.current_doc.id,
					status: false,
				});
			}
			await this.documents_service.update({
				id: this.doc.id,
				status: true,
			});

			this.reload();
		},
		handlePauseOk() {
			this.documents_service
				.update({
					id: this.current_doc.id,
					status: false,
				})
				.then(() => {
					this.reload();
				});
		},

		exibe() {
			$("#novoArquivo").addClass("hideMe");
			$("#escolha").removeClass("hideMe");
			$("#aprovar").removeClass("hideMe");

			$("#secondButton").removeClass("hideMe");
		},
		esconde() {
			$("#novoArquivo").removeClass("hideMe");
			$("#escolha").addClass("hideMe");
			$("#aprovar").addClass("hideMe");

			$("#secondButton").addClass("hideMe");
		},

		startProposal(proposal) {
			if (proposal.start_date && proposal.end_date){
				this.proposal_service
				.update({ id: proposal.id, started: 2 })
				.then(() => {
					this.$router.push({ name: "listApprovals" });
				});
			} else {
				Swal.fire({
					title: "Falha!",
					text: "Precisa preencher a data de inicio e data de fim do pré-contrato antes de enviar para aprovações",
					icon: "error",
					showCancelButton: false,
					confirmButtonColor: "#0bb4d4",
					cancelButtonColor: "#A9A9A9",
					confirmButtonText: "OK",
					cancelButtonText: "Cancelar",
				});
			}
			
		},
		async deleteProposal(id) {
			Swal.fire({
				title: "Certeza que deseja deletar?",
				text: "Essa ação não poderá ser desfeita",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#0bb4d4",
				cancelButtonColor: "#A9A9A9",
				confirmButtonText: "Deletar",
				cancelButtonText: "Cancelar",
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					//Fazer cliente sumir da página
					this.proposal_service.destroy(id).then(({ data }) => {
						console.log(data);
						location.reload();
					});
					Swal.fire(
						"Deletado!",
						"Proposta deletada com sucesso",
						"success"
					);
				}
			});
		},
		async reload() {
			let params = { started: 1 };
			if (this.filters.region) {
				params.client__estado = this.filters.region;
			}
			if (this.filters.status) {
				let now = DateTime.now();
				switch (this.filters.status) {
					case 1:
						params.expire_date__gte = now.toISODate();
						break;
					case 2:
						params.expire_date__lte = now.toISODate();
						break;
					case 3:
						params.expire_date__lte = now
							.plus({ days: 7 })
							.toISODate();
						params.expire_date__gte = now.toISODate();
						break;
					default:
						break;
				}
			}
			if (this.filters.period.length > 0) {
				params.expire_date__gte = DateTime.fromMillis(
					this.filters.period[0]
				).toISODate();
				params.expire_date__lte = DateTime.fromMillis(
					this.filters.period[1]
				).toISODate();
			}
			this.proposals = await this.proposal_service
				.list({
					params,
				})
				.then((resp) => resp.data.results);
			let clients_list = new Set(this.proposals.map((p) => p.client));
			this.client_services
				.detailed_list(Array.from(clients_list))
				.then((r) => {
					this.clients = r;
					for (
						let index = 0;
						index < this.proposals.length;
						index++
					) {
						const element = this.proposals[index];
						element.client = this.clients[element.client];
					}
				});
		},
		async getConsultantName(proposal) {
			let consultantProposalArray = proposal.commissions;

			if (
				!(
					typeof consultantProposalArray === "object" &&
					consultantProposalArray.length > 0
				)
			) {
				return "-";
			}

			let consultantArray = await this.consultant_service.list().results;

			consultantArray = consultantArray.filter(function (obj) {
				return obj.id === consultantProposalArray[0].consultant;
			});
			return consultantArray[0].nome;
		},
	},
	filters: {
		format_date(date) {
			let o_date = DateTime.fromISO(date);
			return o_date.toFormat("dd/MM/y");
		},
	},
	watch: {
		filters: {
			handler() {
				this.reload();
			},
			deep: true,
		},
	},
};
</script>
