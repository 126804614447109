<template>
	<div>
		<section id="Softwares">
			<div class="row">
				<div
					class="col-sm-5 col-md-4 col-lg-3"
					style="
						font-size: 16px;
						font-weight: bold;
						color: #878ea4;
						margin-top: 4px;
					"
				>
					Dados Básicos
				</div>
				<div class="col-sm-7 col-md-8 col-lg-9">
					<hr style="margin-left: -17%" />
				</div>
			</div>
			<form @submit.prevent="createProduct()" id="Impressoras">
				<div class="row" style="margin: 1%; text-align: left">
					<div
						class="col-sm-12 col-md-3"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo">Nome do Software</label>
						<b-select
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box !important;
								border-radius: 2px !important;
								opacity: 1 !important;
								text-transform: uppercase !important;
								display: block !important;
								padding: 2px 28px 2px 12px !important;
								border: 1px solid #ced4da;
							"
							v-model="product.product"
							:options="products_option"
							required
						>
						</b-select>
					</div>
					<div
						class="col-sm-12 col-md-3"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo">Fabricante</label>
						<input
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
							"
							type="text"
							class="form-control"
							v-model="product.manufacturer"
						/>
					</div>
					<div
						class="col-sm-12 col-md-2"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo">Qt. Contratada</label>
						<input
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
							"
							type="number"
							id="qtContratada"
							class="form-control"
							required
							pattern="^[0-9-./-]{0,20}"
							oninvalid="this.setCustomValidity('Digite até 20 números')"
							oninput="setCustomValidity('')"
							v-model="product.amount"
							:keyup="setMax"
						/>
						<p
							style="
								font-size: 10px;
								color: #878ea4;
								padding-left: 4px;
							"
							v-if="this.product.contratada"
						>
							{{
								("Limite: " + this.product.contratada)
									| truncate(40, "...")
							}}
						</p>
					</div>
					<div
						class="col-sm-12 col-md-2"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="">Qt. Aprovada</label>
						<input
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
							"
							type="text"
							id="qtAprovada"
							class="form-control"
							pattern="^[0-9-./-]{0,20}"
							oninvalid="this.setCustomValidity('Digite até 20 números')"
							oninput="setCustomValidity('')"
							v-model="product.allowed"
						/>
					</div>
					<div
						class="col-sm-12 col-md-2"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo">Qt. Liberada</label>
						<input
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
							"
							type="text"
							id="qtLiberada"
							class="form-control"
							pattern="^[0-9-./-]{0,20}"
							oninvalid="this.setCustomValidity('Digite até 20 números')"
							oninput="setCustomValidity('')"
							v-model="product.released"
						/>
					</div>
				</div>

				<div class="row" style="margin: 1%; text-align: left">
					<div
						class="col-sm-12 col-md-8"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo">Observações</label>
						<input
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
								padding: 2%;
							"
							type="text"
							class="form-control"
							id="modelo"
							v-model="product.obs"
						/>
					</div>
					<div
						class="col-sm-12 col-md-2"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo">Valor Unitário</label>
						<input
							v-model="product.valor_unitario"
							v-bind="proposal_format"
							class="form-control crm-input"
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
							"
							required
						/>
					</div>
					<div
						class="col-sm-12 col-md-2"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="valor-total" id="valorTotal"
							>Valor Total</label
						>
						<input
							v-model="total_product_value"
							v-bind="proposal_format"
							readonly
							class="form-control crm-input"
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								opacity: 1;
							"
						/>
					</div>
				</div>
				<div class="row">
					<div
						class="col-sm-5 col-md-4 col-lg-3"
						style="
							font-size: 16px;
							font-weight: bold;
							color: #878ea4;
							margin-top: 4px;
						"
					>
						Valores
					</div>
					<div class="col-sm-7 col-md-8 col-lg-9">
						<hr style="margin-left: -26%" />
					</div>
				</div>
				<div class="row" style="margin: 1%; text-align: left">
					<div
						class="col-sm-12 col-md-2"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo">Valor Unitário</label>
						<input
							v-model="product.custo"
							v-bind="proposal_format"
							class="form-control crm-input"
							ref="custo_ref"
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
							"
							pattern="^\R\$ [0-9-./-]{0,20}"
							oninvalid="this.setCustomValidity('Digite até 20 números')"
							oninput="setCustomValidity('')"
							required
						/>
					</div>
					<div
						class="col-sm-12 col-md-2"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo">Valor Página</label>
						<input
							v-model="product.per_page"
							v-bind="proposal_format"
							class="form-control crm-input"
							ref="per_page_ref"
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
							"
							pattern="^\R\$ [0-9-./-]{0,20}"
							oninvalid="this.setCustomValidity('Digite até 20 números')"
							oninput="setCustomValidity('')"
						/>
					</div>
					<div
						class="col-sm-12 col-md-3"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo">Valor Total Mensal</label>
						<input
							type="text"
							v-model="total_mensal"
							class="form-control crm-input"
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
							"
							pattern="^\R\$ [0-9-./-]{0,20}"
							oninvalid="this.setCustomValidity('Digite até 20 números')"
							oninput="setCustomValidity('')"
							readonly
						/>
					</div>
					<div
						class="col-sm-12 col-md-3"
						style="font-size: 14px; color: #878ea4"
					>
						<label for="modelo"
							>Total ({{ duration_in_months }} Meses)</label
						>
						<input
							type="text"
							v-model="total_anual"
							class="form-control crm-input"
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
							"
							pattern="^\R\$ [0-9-./-]{0,20}"
							oninvalid="this.setCustomValidity('Digite até 20 números')"
							oninput="setCustomValidity('')"
							readonly
						/>
					</div>
				</div>
				<div style="margin-top: -20px">
					<button
						type="button"
						class="btn btn-secondary py-2 px-4"
						style="
							font-size: 11px;
							margin-top: 50px;
							background: #878787 0% 0% no-repeat padding-box;
							border-radius: 100px;
							opacity: 1;
						"
						@click="
							() => {
								this.$emit('cancel');
							}
						"
					>
						CANCELAR
					</button>

					<button
						type="submit"
						class="btn btn-primary py-2 px-4"
						style="
							font-size: 11px;
							margin-top: 50px;
							margin-left: 10px;
							background: #0bb4d4 0% 0% no-repeat padding-box;
							border-radius: 100px;
							opacity: 1;
						"
					>
						SALVAR
					</button>
				</div>
			</form>
		</section>
	</div>
</template>

<script>
import Swal from "sweetalert2";

import { DateTime } from "luxon";
import ClientService from "../../services/ClientService/ClientService";
import ConsultantsService from "../../services/ConsultantService/ConsultantService";
import ProductService from "../../services/ProductService/ProductService";
import ProductProposalService from "../../services/ProductsProposalsService/ProductProposalsService";
import ProposalService from "../../services/ProposalsService/ProposalsService";
import { getMonths } from "../../utils/util";

export default {
	name: "software",
	props: ["value"],
	data() {
		return {
			product_proposal_service: new ProductProposalService(),
			product_service: new ProductService(),
			proposal_service: new ProposalService(),
			client_service: new ClientService(),
			consultant_service: new ConsultantsService(),

			proposal: {
				client: {},
				proposal_value: 0,
				commissions: [{}],
				start_date: null,
				end_date: null,
				months: 0,
			},
			proposal_format: {
				decimal: ",",
				separator: ".",
				prefix: "R$ ",
				precision: 4,
			},
			not_found: false,
			products: [],
			product: {
				custo: 0,
				per_page: 0,
			},
			search: "",
		};
	},

	mounted() {
		this.product = this.value;
		// this.$refs["custo_ref"].input({
		// 	target: {
		// 		value: this.product.custo,
		// 	},
		// });
		// this.$refs["per_page_ref"].input({
		// 	target: {
		// 		value: this.product.per_page,
		// 	},
		// });
		this.reload();

		this.loadProposal(this.$route.params.id);

		this.months = getMonths(
			this.proposal.start_date,
			this.proposal.end_date
		);
	},
	methods: {
		changeRoute(e) {
			this.$router.push("/" + e.target.value);
		},
		loadProposal(id) {
			this.proposal_service.retrieve(id).then(({ data }) => {
				Object.assign(this.proposal, data);
				this.proposal.start_date = DateTime.fromISO(
					this.proposal.start_date
				).toMillis();
				this.proposal.end_date = DateTime.fromISO(
					this.proposal.end_date
				).toMillis();
			});
		},
		createProduct() {
			this.submmited = true;
			let product = Object.assign({}, this.product);
			product.proposal = this.$route.params.id;
			let promise = new Promise((resolve) => {
				resolve();
			});

			if (product.id) {
				promise = this.product_proposal_service
					.update(product)
					.catch(() => {
						Swal.fire({
							title: "Falha!",
							text: "Não foi possivel atualizar o equipamento, verifique o formulário",
							icon: "error",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					})
					.then(() => {
						Swal.fire({
							title: "Atualizado!",
							text: "Equipamento atualizado com sucesso",
							icon: "success",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					});
			} else {
				promise = this.product_proposal_service
					.create(product)
					.catch(() => {
						console.log("catch");
						Swal.fire({
							title: "Falha!",
							text: "Não foi possivel adicionar o produto, verifique o formulário",
							icon: "error",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					})
					.then(() => {
						Swal.fire({
							title: "Adicionado!",
							text: "Equipamento adicionado com sucesso",
							icon: "success",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					});
			}
			promise.then(() => {
				this.$emit("added-product", this.product);
			});
		},
		reload() {
			this.product_service
				.list({ params: this.params })
				.then(({ data }) => {
					this.products = data.results;
				});
		},
	},
	computed: {
		products_option() {
			let products = this.products;
			console.log(products);
			return products.map((p) => ({ value: p.id, text: p.nome }));
		},
		params() {
			let p = {};
			p.search = this.search;
			p.type = 1;
			return p;
		},

		total_mensal() {
			let amount = this.product.amount;
			let custo = this.product.custo;

			if (!isNaN(amount) && !isNaN(custo)) {
				return `R$ ${(custo * amount).toFixed(2)}`;
			}

			return "";
		},
		total_anual() {
			let total_mensal = parseFloat(this.total_mensal.slice(3));

			if (!isNaN(total_mensal)) {
				return `R$ ${(total_mensal * this.duration_in_months).toFixed(
					2
				)}`;
			}

			return "";
		},
		duration_in_months() {
			console.log("duration_in_months");
			if (this.proposal.start_date && this.proposal.end_date) {
				let d_start_date = DateTime.fromMillis(
					this.proposal.start_date
				);
				let d_end_date = DateTime.fromMillis(
					this.proposal.end_date
				);
				let months =
					d_end_date.diff(d_start_date, ["days"]).days / 30;
				console.log(months);
				return months.toFixed(0);
			}
			return 0;
		},
		total_product_value() {
			if (
				this.product.valor_unitario &&
				!isNaN(this.product.valor_unitario)
			) {
				return `R$ ${(
					this.product.valor_unitario * this.product.amount
				).toFixed(2)}`;
			}
			return `R$ 0`;
		},
	},
	watch: {
		search() {
			this.reload();
		},
	},
};
</script>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
</style>

<style scoped>
>>> {
	--vs-border-style: none;
}
</style>
