var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font ajust-margin"},[_c('div',{staticClass:"row",staticStyle:{"margin-top":"-30px"}},[_c('div',{staticClass:"form-group col-sm-12 col-md-4"},[_c('label',{staticStyle:{"font-size":"16px","font-weight":"bold","color":"#878ea4"},attrs:{"for":""}},[_vm._v("Tipo de Equipamento")]),_c('b-select',{staticClass:"form-control",staticStyle:{"background":"#f5f4fc 0% 0% no-repeat padding-box","border-radius":"2px","opacity":"1","text-transform":"uppercase"},attrs:{"id":"lista","required":"","options":_vm.devices_options},model:{value:(_vm.selected_device),callback:function ($$v) {_vm.selected_device=$$v},expression:"selected_device"}})],1)]),(_vm.selected_device == 'impressoraScanner')?_c('impressora-scanner-vue',{on:{"added-product":() => {
				this.$emit('added-product', this.product);
			},"cancel":_vm.cancelled},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}}):_vm._e(),(_vm.selected_device == 'maodeObra')?_c('mao-de-obra-vue',{on:{"added-product":() => {
				this.$emit('added-product', this.product);
			},"cancel":_vm.cancelled},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}}):_vm._e(),(_vm.selected_device == 'pcs')?_c('pcs-vue',{on:{"added-product":() => {
				this.$emit('added-product', this.product);
			},"cancel":_vm.cancelled},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}}):_vm._e(),(_vm.selected_device == 'software')?_c('softwares-vue',{on:{"added-product":() => {
				this.$emit('added-product', this.product);
			},"cancel":_vm.cancelled},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}}):_vm._e(),(_vm.selected_device == 'termicasPlotter')?_c('termicas-plotter-vue',{on:{"added-product":() => {
				this.$emit('added-product', this.product);
			},"cancel":_vm.cancelled},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }