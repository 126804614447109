var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"title",staticStyle:{"font-size":"14px","line-height":"30px"}},[_vm._v(" ENDEREÇOS DE EQUIPAMENTOS ")]),_c('div',{staticStyle:{"padding-bottom":"60px"}},[_c('table',{staticClass:"table table-sm mt-0"},[_vm._m(0),_c('tbody',{staticStyle:{"border":"none"}},_vm._l((_vm.product_sites),function(p_s,i){return _c('tr',{key:i},[(p_s.site !== undefined)?[_c('td',[_vm._v(_vm._s(i + 1))]),_c('td',{staticClass:"left-td"},[_vm._v(" "+_vm._s(p_s.product.product_obj.nome)+" ")]),_c('td',[_vm._v(_vm._s(p_s.product.new ? "Novo" : "Usado"))]),_c('td',[_vm._v(_vm._s(p_s.site.department))]),_c('td',[_vm._v(_vm._s(p_s.site.logradouro))]),_c('td',[_vm._v(_vm._s(p_s.site.cidade))]),_c('td',[_vm._v(_vm._s(p_s.site.estado))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("format_date")(p_s.site.date_and_time))+" ")]),_c('td',[_c('router-link',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"to":{
									name: 'editEndereco',
									params: {
										id: _vm.proposal.id,
										product_id: p_s.product.id,
										codigo_endereco: p_s.site.codigo,
									},
								}}},[_c('span',{staticClass:"material-icons-outlined",staticStyle:{"color":"#384364","cursor":"pointer","font-size":"17px","padding-top":"10%"}},[_vm._v(" edit ")])])],1),_c('td',[_c('span',{staticClass:"material-icons-outlined",staticStyle:{"color":"#384364","cursor":"pointer","font-size":"17px","padding-top":"10%"},on:{"click":function($event){return _vm.deleteEndereco(p_s.site.codigo)}}},[_vm._v(" delete ")])]),_c('td',[_c('span',_vm._g(_vm._b({directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"material-icons-outlined",staticStyle:{"color":"#384364","cursor":"pointer","font-size":"15px","padding-top":"10%"},attrs:{"title":"Multiplicar endereço do produto"},on:{"click":function($event){return _vm.openModal(p_s)}}},'span',_vm.attrs,false),_vm.on),[_vm._v(" content_copy ")])]),_c('td',[_c('span',_vm._g(_vm._b({directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"material-icons-outlined",staticStyle:{"color":"#384364","cursor":"pointer","font-size":"15px","padding-top":"10%"},attrs:{"title":"Copiar endereço"},on:{"click":function($event){return _vm.attIdEndereco(p_s.site.codigo)}}},'span',_vm.attrs,false),_vm.on),[_vm._v(" add_box ")])])]:[_c('td',[_vm._v(_vm._s(i + 1))]),_c('td',{staticClass:"left-td"},[_vm._v(" "+_vm._s(p_s.product.product_obj.nome)+" ")]),_c('td',[_vm._v(_vm._s(p_s.product.new ? "Novo" : "Usado"))]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',{staticClass:"remove"}),_c('td',{staticClass:"remove"}),_c('td',[_c('router-link',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"to":{
									name: 'novoEndereco',
									params: {
										id: _vm.proposal.id,
										product_id: p_s.product.id,
									},
								}}},[_c('span',_vm._g(_vm._b({directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"material-icons-outlined",staticStyle:{"color":"#384364","cursor":"pointer","font-size":"17px","padding-top":"10%"},attrs:{"title":"Cadastrar novo endereço de entrega do produto"}},'span',_vm.attrs,false),_vm.on),[_vm._v(" local_shipping ")])])],1),_c('td',[_c('router-link',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"to":{
									name: 'novoEnderecoCopy',
									params: {
										id: _vm.proposal.id,
										product_id: p_s.product.id,
										id_endereco: _vm.id_old_endereco
									},
								}}},[_c('span',_vm._g(_vm._b({directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"material-icons-outlined",staticStyle:{"color":"#384364","cursor":"pointer","font-size":"17px","padding-top":"10%"},attrs:{"title":"Cadastrar endereço copiado"}},'span',_vm.attrs,false),_vm.on),[_vm._v(" check_box ")])])],1)]],2)}),0)])]),_c('div',{staticClass:"d-flex justify-content-end",staticStyle:{"padding-bottom":"40px"}},[_c('button',{staticClass:"btn btn-secondary",staticStyle:{"background":"#7E7E7E","border-radius":"8px","padding-left":"30px","padding-right":"30px","margin-right":"20px","border":"none"},attrs:{"type":"submit"},on:{"click":() => {
					this.$emit('cancel');
				}}},[_vm._v(" Voltar ")]),_c('button',{staticClass:"btn btn-primary",staticStyle:{"background":"#C84995","border-radius":"8px","padding-left":"30px","padding-right":"30px","border":"none","margin-right":"20px"},attrs:{"type":"submit"},on:{"click":_vm.getPdf}},[_vm._v(" Ver resumo ")]),_c('button',{staticClass:"btn btn-primary",staticStyle:{"background":"#006992","border-radius":"8px","padding-left":"30px","padding-right":"30px","border":"none"},attrs:{"type":"submit"},on:{"click":_vm.startAprovalls}},[_vm._v(" Enviar para aprovações ")])]),_c('b-modal',{attrs:{"id":"multiply_site","title":"Multiplicar Endereços"},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ cancel }){return [_c('div',{staticClass:"w-100",staticStyle:{"display":"flex","justify-content":"center","border-top":"0"}},[_c('b-button',{staticClass:"btn-reprove",attrs:{"size":"sm"},on:{"click":function($event){return cancel()}}},[_c('span',[_vm._v("FECHAR")])]),_c('b-button',{staticClass:"btn-approve",attrs:{"size":"sm","id":"resolveButton"},on:{"click":function($event){return _vm.multiply_site(_vm.current_obj, _vm.qtde)}}},[_vm._v(" MULTIPLICAR ")])],1)]}}])},[_c('div',[_c('b-row',[_c('b-col',{staticClass:"mt-3",staticStyle:{"margin-bottom":"10px"}},[_c('span',{staticClass:"input-title"},[_vm._v(" Quantidade ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.qtde),expression:"qtde"}],staticClass:"form-control",staticStyle:{"border-radius":"2px","width":"450px","height":"30px","background-color":"#f5f4fc"},attrs:{"required":"","type":"text","id":"multiply"},domProps:{"value":(_vm.qtde)},on:{"input":function($event){if($event.target.composing)return;_vm.qtde=$event.target.value}}})])],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticStyle:{"background":"#f9f9f9 0% 0% no-repeat padding-box","border":"0.5px solid #e1e1e1","border-radius":"10px","opacity":"1"}},[_c('th',{staticStyle:{"width":"2rem"}}),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Modelo")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Situação")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Nome Setor")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Endereço")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Cidade")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Estado")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Dia e Horário")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}}),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}}),_c('th'),_c('th'),_c('th')])])
}]

export { render, staticRenderFns }