<template>
	<div class="font ajust-margin" style="margin-top: 80px; margin-right: 95px; margin-left: 95px;">
		<div style="background: #FFFFFF; box-shadow: 0px 10px 10px rgba(226, 236, 249, 0.5); border-radius: 30px;">
			<div style="padding-top: 28px; margin-bottom:20px; padding-left: 38px">
				<p class="title"> Aprovações</p>
			</div>
			<div>
				<b-table :items="proposals" :fields="fields" class="ajust-header">
				<template #cell(documents)="row">
					<span
						@click="openModalDocuments(row.item.id)"
						class="material-icons"
						style="
							color: #384364;
							cursor: pointer;
							font-size: 15px;
							margin-right: 7%;
							padding-top: 8%;
							cursor: pointer;
						"
						v-b-tooltip.hover
						title="Visualizar Documento"
					>
						description
					</span>
				</template>
				<template #cell(Dropdown)="row">
					<div class="d-flex">
						<router-link
						:to="{
							name: 'editPrecontrato',
							params: { id: row.item.id, step: 1 },
						}"
						target="_blank"
						style="margin-right: 8px;"
						class="d-flex align-items-center"
					>
						<span
							class="material-icons-outlined"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 15px;
								margin-right: 8px;
								padding-top: 8%;
								cursor: pointer;
							"
							v-b-tooltip.hover
							title="Visualizar pré-contrato"
						>
							visibility
						</span>
					</router-link>
						<span @click="insert_into_databit_modal(row.item)"
							class="material-icons-outlined"
							style="
								color: #384364;
								cursor: pointer;
								font-size: 18px;
								margin-right: 8px;
								padding-top: 8%;
								cursor: pointer;
							"
								v-b-tooltip.hover
								:title="row.item.started == 2 ? 'Inserir no databit' : 'Pré-contrato já inserido'"
								>
							{{ row.item.started == 2 ? 'last_page' : 'done_all'}}
						</span>
					<span
						@click="row.toggleDetails"
						class="material-icons-outlined"
						style="
							color: #384364;
							cursor: pointer;
							font-size: 18px;
							margin-left: 10%;
							padding-top: 8%;
							cursor: pointer;
						"
					>
						keyboard_arrow_down
					</span>
					</div>

				</template>
				<template #row-details="row">
					<b-card style="background: #f5f4fc">
						<div
							style="
								display: flex;
								justify-content: top;
								justify-items: center;
								align-items: start;
								justify-content: center;
							"
							class="col-md-12"
						>
							<div
								v-for="step in steps"
								:key="step.id"
								style="display: flex; justify-items: center"
								class="col-md-2"
							>
								<div
									style="
										display: flex;
										flex-direction: column;
										justify-items: center;
										align-items: center;
									"
								>
									<span
										style="
											color: #666;
											background: none;
											border-radius: 100%;
											border: none;
											width: 60px;
											display: flex;
											justify-content: center;
											opacity: 1;
											line-height: normal;
										"
										class="material-icons btn btn-primary img-rounded"
										v-if="
											step_row(step, row) === undefined ||
											(step_row(step, row) &&
												step_row(step, row).approved ==
													null)
										"
										@click="openModal(row, step)"
									>
										help
									</span>

									<span
										style="
											color: #079f59;
											background: none;
											border-radius: 100%;
											border: none;
											width: 60px;
											display: flex;
											justify-content: center;
											opacity: 1;
											line-height: normal;
										"
										class="material-icons btn btn-primary img-rounded"
										v-else-if="
											step_row(step, row) &&
											step_row(step, row).approved == true
										"
										@click="openModal2(row, step)"
									>
										check_circle
									</span>

									<span
										style="
											color: red;
											background: none;
											border-radius: 100%;
											border: none;
											width: 60px;
											display: flex;
											justify-content: center;
											opacity: 1;
											line-height: normal;
										"
										class="material-icons btn btn-primary img-rounded"
										v-else-if="
											step_row(step, row) &&
											step_row(step, row).approved == false
										"
										@click="
											openModal3(
												row,
												step,
												// getLastDeniedReason(step, row)
												getLastDeniedReason
											)
										"
									>
										block
									</span>

									{{ step.name }}
								</div>

								<div
									v-if="step.next"
									style="
										display: flex;
										justify-items: center;
										align-items: start;
										justify-content: center;
										margin: 5% 22%;
									"
								>
									<span class="material-icons-outlined">
										arrow_forward</span
									>
								</div>
							</div>
						</div>
					</b-card>
				</template>
			</b-table>
			</div>
		</div>

		<b-modal
			scrollable="true"
			size="lg"
			id="add-document"
			title="Documentos do Pré-Contrato"
			hide-footer="true"
		>
			<button
				id="novoArquivo"
				class="btn-proposta"
				style="
					height: 30px;
					margin-top: 5px;
					float: right;
					margin-right: 15px;
				"
				@click="exibeDocuments"
			>
				NOVO ARQUIVO
			</button>
			<b-row id="escolha" class="hideMe">
				<div
					style="
						width: 100%;
						display: flex;
						align-items: center;
						margin-left: 10px;
						margin-right: 35px;
					"
				>
					<b-col id="etapa">
						<span class="input-title"> Escolha um Arquivo </span>
						<input
							style="
								border-radius: 2px;
								width: 450px;
								padding-bottom: 5px;
								background-color: #f5f4fc;
							"
							type="file"
							class="form-control"
							id="document-name"
							@change="onChange"
						/>
					</b-col>
					<div style="margin-top: 21px">
						<b-button
							id="secondButton"
							size="sm"
							class="btn-reprove2 hideMe"
							@click="esconde"
						>
							<span>CANCELAR</span>
						</b-button>
						<b-button
							id="aprovar"
							size="sm"
							class="btn-approve2 hideMe"
							@click="attachDocument"
						>
							SALVAR
						</b-button>
					</div>
				</div>
			</b-row>
			<div style="margin-bottom: 10px">
				<b-row style="margin-top: 10px; width: 100%">
					<table
						class="table table-sm mt-3"
						style="
							border: 1px solid #ced4da;
							height: 38px;
							width: 100%;
							margin-left: 25px;
						"
					>
						<thead>
							<tr
								style="
									background: #f9f9f9 0% 0% no-repeat
										padding-box;
									border: 1px solid #e1e1e1;
									border-radius: 4px;
									opacity: 1;
								"
							>
								<th style="padding-left: 11px">Data</th>
								<th>Autor do Upload</th>

								<th>Arquivo</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(doc, i) in docs" :key="i">
								<td>
									{{ doc.created_at | format_date }}
								</td>
								<td>
									{{ doc.consultant.first_name }}
								</td>

								<td>
									<a :href="doc.file">{{ doc.name }}</a>
								</td>

								<td>
									<span
										class="material-icons-outlined"
										@click="deleteDoc(doc.id)"
										style="
											margin-top: 10px;
											color: #384364;
											cursor: pointer;
											font-size: 16px;
										"
									>
										delete
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</b-row>
			</div>
		</b-modal>


		<b-modal
			id="current-step"
			@ok="approveStep(current_proposal, current_step)"
			@cancel="denyStep(current_proposal, current_step)"
			title="Avaliação do Pré-Contrato"
			v-if="isProposalSelected"
		>
			<div>
				<!-- <b-row>
					<b-col style="margin-bottom: 10px">
						<span class="input-title"> Contrato </span>
						<input
							type="text"
							class="form-control"
							id="contrato"
							style="border-radius: 2px"
							v-model="current_proposal.id"
						/>
					</b-col>
				</b-row> -->
				<div style="margin-left: 30px" id="contrato">
					<p class="input-title" style="margin-bottom: -15px">
						Contrato
					</p>
					<b-row>
						<table
							class="table table-sm mt-3"
							style="
								border: 1px solid #ced4da;
								height: 38px;
								width: 400px;
								margin-left: 15px;
							"
						>
							<thead>
								<th style="padding-left: 11px">
									{{
										format_date(current_proposal.created_at)
									}}
								</th>
								<th style="text-transform: uppercase">
									{{
										current_proposal.client.nome
											| truncate(15, "...")
									}}
								</th>

								<th style="text-align: right">
									{{ current_proposal.proposal_value }}
								</th>
							</thead>
						</table>
					</b-row>
				</div>
				<b-row style="margin-left: 15px">
					<b-col id="etapa">
						<span class="input-title"> Etapa </span>
						<input
							style="
								border-radius: 2px;
								width: 400px;
								padding-bottom: 5px;
							"
							disabled
							type="text"
							class="form-control"
							id="contrato"
							v-model="current_step.name"
						/>
					</b-col>
				</b-row>
				<b-row
					v-if="current_step.name === 'Credito'"
					style="margin-left: 15px"
				>
					<b-col id="credito">
						<span class="input-title"> Risco Interno </span>
						<input
							style="
								border-radius: 2px;
								width: 400px;
								padding-bottom: 5px;
							"
							disabled
							type="text"
							class="form-control"
							id="internal-risk"
							:value="risk(current_proposal.client.internal_risk)"
						/>
					</b-col>
				</b-row>
				<b-row
					v-if="current_step.name === 'Credito'"
					style="margin-left: 15px"
				>
					<b-col id="credito">
						<span class="input-title"> Risco Externo </span>
						<input
							style="
								border-radius: 2px;
								width: 400px;
								padding-bottom: 5px;
							"
							disabled
							type="text"
							class="form-control"
							id="external-risk"
							:value="risk(current_proposal.client.external_risk)"
						/>
					</b-col>
				</b-row>

				<b-row style="margin-top: 15px; margin-left: 15px">
					<b-col id="justificativa" class="hideMe">
						<span class="input-title">
							Justificativa
						</span>
						<input
							style="
								border-radius: 2px;
								width: 400px;
								padding-bottom: 5px;
							"
							type="text"
							class="form-control"
							id="reason"
						/>
					</b-col>
				</b-row>
				<b-row style="margin-top: 15px; margin-left: 15px">
					<b-col id="retorno" class="hideMe">
						<span class="input-title"> Etapa para Retorno </span>
						<b-select
							text-field="name"
							value-field="id"
							style="
								background: #f5f4fc 0% 0% no-repeat padding-box;
								border-radius: 2px;
								opacity: 1;
								width: 400px;
							"
							class="form-control"
							:options="return_to_step_options(current_step)"
							required
							id="return"
						>
						</b-select>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer="{ ok, cancel }">
	<div
		style="
			display: flex;
			justify-content: center;
			border-top: 0;
		"
		class="w-100"
	>
		<b-button
			size="sm"
			class="btn-reprove"
			id="firstButton"
			@click="exibe"
		>
			<span>REJEITAR</span>
		</b-button>
		<b-button
			id="secondButton"
			size="sm"
			class="btn-reprove hideMe"
			@click="cancel()"
		>
			<span>REJEITAR</span>
		</b-button>
		<b-button
			size="sm"
			class="btn-approve"
			id="firstButtonAprovar"
			@click="exibeAprovar"
		>
			<span>APROVAR</span>
		</b-button>
		<b-button
			id="aprovar"
			size="sm"
			class="btn-approve hideMe"
			@click="ok()"
		>
			APROVAR
		</b-button>
		<button
			type="submit"
			class="btn btn-primary"
			style="
				background: #C84995;
				border-radius: 8px;
				padding-left: 30px;
				padding-right: 30px;
				border: none;
				border-radius: 15px;
				margin-right: 20px;
			"
			@click="getPdf"
		>
			Ver resumo
		</button>
	</div>
</template>


		</b-modal>
		<b-modal
			size="lg"
			id="sucess-step"
			@ok="approveStep(current_proposal, current_step)"
			@cancel="denyStep(current_proposal, current_step)"
			title="Detalhes de passo da Avaliação"
			v-if="isProposalSelected"
		>
			<div style="width: 600px; margin-left: 80px">
				<b-row>
					<table
						class="table table-sm mt-4"
						style="border: 2px solid #90ddeb"
					>
						<thead>
							<th class="ajust-th">
								{{ format_date(current_step.created_at) }}
							</th>
							<th class="ajust-th">
								{{ current_proposal.client.nome }}
							</th>
							<th class="ajust-th">
								{{ current_proposal.proposal_value }}
							</th>
							<th
								class="ajust-th"
								style="text-transform: uppercase"
							>
								{{ current_step.name }}
							</th>
						</thead>
					</table>
				</b-row>
				<b-row>
					<!-- <template #row-details="row"> -->
					<table
						class="table table-sm mt-2"
						style="border: 2px solid #90ddeb"
					>
						<thead>
							<th class="ajust-th-body">Data</th>
							<th class="ajust-th-body">Ação</th>
							<th class="ajust-th-body">Autor</th>
							<th class="ajust-th-body">
								Justificativa (Se Aplicável)
							</th>
						</thead>
						<tbody>
							<!-- <tr> -->
							<tr
								v-for="st in steps_row(
									current_step,
									current_proposal
								)"
								:key="st"
							>
								<td class="ajust-td">
									{{ format_date(st.created_at) }}
								</td>
								<td class="ajust-td">
									{{
										st.approved
											? "APROVAÇÃO"
											: st.approved === null
											? "RESOLVIDO"
											: "REJEIÇÂO"
									}}
								</td>
								<td class="ajust-td">
									{{ st.user_obj.first_name }}
								</td>
								<td class="ajust-td">
									{{ st.reason }}
								</td>
							</tr>
						</tbody>
					</table>
					<!-- </template> -->
				</b-row>
				<b-row v-if="current_step.name === 'Estoque'">
					<b-col id="check">
						<span
							class="material-icons-outlined"
							style="font-size: 15px; margin-right: 10px"
						>
							done
						</span>

						<label style="margin-bottom: -10px">
							Parecer Final
						</label>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer="{ cancel }">
				<div
					style="
						display: flex;
						justify-content: center;
						border-top: 0;
					"
					class="w-100"
				>
					<b-button @click="cancel()" size="sm" class="btn-ok">
						OK
					</b-button>
				</div>
			</template>
		</b-modal>
		<b-modal
			id="deny-step"
			@ok="resolveStep(current_proposal, current_step)"
			title="Motivo da Rejeição"
		>
			<div>
				<b-row>
					<b-col style="margin-bottom: 10px" class="mt-3">
						<input
							type="text"
							class="form-control"
							id="reason"
							style="
								border-radius: 2px;
								width: 450px;
								height: 100px;
								background-color: #f5f4fc;
							"
							disabled
							v-model="current_reason"
						/>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer="{ ok, cancel }">
				<div
					style="
						display: flex;
						justify-content: center;
						border-top: 0;
					"
					class="w-100"
				>
					<b-button size="sm" class="btn-reprove" @click="cancel()">
						<span>FECHAR</span>
					</b-button>
					<b-button
						size="sm"
						class="btn-approve"
						id="resolveButton"
						@click="ok()"
					>
						RESOLVIDO
					</b-button>
				</div>
			</template>
		</b-modal>
		<b-modal
			id="code-databit"
			@ok="insert_into_databit(current_proposal, codigo_databit)"
			title="Codigo do contrato"
		>
			<div>
				<b-row>
					<b-col style="margin-bottom: 10px" class="mt-3">
						<input
							type="text"
							class="form-control"
							id="codigo_databit"
							v-model="codigo_databit"
						/>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer="{ ok, cancel }">
				<div
					style="
						display: flex;
						justify-content: center;
						border-top: 0;
					"
					class="w-100"
				>
					<b-button size="sm" class="btn-reprove" @click="cancel()">
						<span>FECHAR</span>
					</b-button>
					<b-button
						size="sm"
						class="btn-approve"
						id="resolveButton"
						@click="ok()"
					>
						INSERIR
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import $ from "jquery";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import ApprovalStepsService from "../../services/ApprovalStepsService/ApprovalStepsService";
import ClientService from "../../services/ClientService/ClientService";
import DocumentsService from "../../services/DocumentsService/DocumentsService";
import ProposalService from "../../services/ProposalsService/ProposalsService";
import StepsService from "../../services/StepsService/StepsService";
import UserService from "../../services/UserService/UserService";

export default {
	data() {
		return {
			approval_steps_service: new ApprovalStepsService(),
			proposal_service: new ProposalService(),
			client_service: new ClientService(),
			users_service: new UserService(),
			user_service: new UserService(),
			steps_service: new StepsService(),
			documents_service: new DocumentsService(),
			proposals: [],
			clients: [],
			users: [],
			steps: [],
			fields: [
				{ key: "client", label: "Cliente", formatter: (c) => c.nome, class: 'cliente' },

				{ key: "created_at", label: "Data", formatter: "format_date" },
				{
					key: "expire_date",
					label: "Validade",
					formatter: "format_expire_date",
				},
				{ key: "proposal_total_value", label: "Valor" },
				{ key: "documents", label: "Pré-Contrato" },
				{ key: "Dropdown", label: "" },
			],
			approval_steps: {},
			current_step: {},
			current_proposal: {},
			by_next: {},
			current_reason: {},
			codigo_databit:"",
			document: {
				proposal: null,
				name: "",
				file: "",
			},
			doc: false,
			docs: [],
			usersDocuments: {},
		};
	},
	mounted() {
		this.reload();
	},
	methods: {
		onChange(e) {
			//popula os inputs
			const fileInput = document
				.getElementById("document-name")
				.value.replace(/.*(\/|\\)/, "")
				.replace(/\.[^/.]+$/, "");

			for (const file of e.target.files) {
				this.document.file = file;
			}
			// document.getElementById("document-name").innerHTML = fileInput;
			this.document.name = fileInput;
		},
		getPdf() {
			//Referencia: https://stackoverflow.com/questions/52878170/rendering-pdf-blob-on-chrome
			this.proposal_service
				.pdf(this.current_proposal.id)
				.then((response) => {
					const url = window.URL.createObjectURL(
						new Blob([response.data], { type: "application/pdf" })
					);
					window.open(url, "_blank");
				});
		},
		risk(current_proposal) {
			if (current_proposal === 0) {
				return "Nenhum";
			}
			return "Em Dívida";
		},
		openModalDocuments(item) {
			this.document.proposal = item;
			this.reloadDocument();
			this.$root.$emit("bv::show::modal", "add-document", "#btnShow");
		},

		return_to_step_options(currentStep) {
			// let all_steps = [
			// 	{ id: 7, name: "Credito" },
			// 	{ id: 8, name: "Comercial" },
			// 	{ id: 9, name: "Logistica" },
			// 	{ id: 10, name: "Gerencia" },
			// 	{ id: 11, name: "Contratos" },
			// 	{ id: 12, name: "Analista" },
			// ];
			// return all_steps.filter((s) => s.id <= currentStep.id);

			// mudei a logica pra nao depender dos ids, pois dependendo das mudanças que peçam
			// vai ter que criar novos objetos pros steps, aí os IDS podem mudar. Da forma abaixo
			// bastaria mudar na mão os nomes e os que vem depois (fica melhor pra manutenção futura)


			// os ids sao necessarios, senao ele nao consegue pegar o
			// valor da option la na funcao de denyStep onde ele faz um getElementById().value

			let all_steps = [
				{id: 14, name: "Credito", next: "Estoque"},
				{id: 16, name: "Estoque", next: "Comercial"},
				{id: 13, name: "Comercial", next: "Contratos"},
				{id: 17, name: "Contratos", next: "Logistica"},
				{id: 15, name: "Logistica", next: "Finalizado"},
				{id: 18, name: "Finalizado", next: null},
			]

			let filteredSteps = [];
			let nextStep = currentStep.name;

			while (nextStep !== null) {
				const step = all_steps.find(step => step.next === nextStep);
				if (step) {
					filteredSteps.unshift(step);
					nextStep = step.name;
				} else {
					nextStep = null;
				}
			}
			return filteredSteps
		},
		reload() {
			this.proposal_service
				.list({ params: { started__gte: 2 } })
				.then((resp) => {
					this.loadProposals(resp.data.results);
				});
			this.steps_service.list().then((resp) => {
				let obj_by_next = Object.fromEntries(
					resp.data.results.map((r) => [r.next, r])
				);
				this.by_next = obj_by_next;

				let r = [obj_by_next[null]];

				while (obj_by_next[r[0].id]) {
					r.unshift(obj_by_next[r[0].id]);
					if (r[0].id == r[1].id) {
						r = r.slice(1);
						break;
					}
				}
				// console.log("IMPRIMINDO O BY_NEXT")
				// console.log(r)
				// console.log("FIM DO IMPRIMINDO BY_NEXT")
				this.steps = r;
			});
		},
		attachDocument() {
			let doc = new FormData();
			for (const key in this.document) {
				if (Object.hasOwnProperty.call(this.document, key)) {
					const element = this.document[key];
					console.log(key);
					console.log(element);
					doc.append(key, element);
				}
			}

			this.documents_service.create(doc).then(() => {
				this.reloadDocument();
				Object.assign(this.document, { name: "", file: null });
			});

			document.getElementById("document-name").value = "";
		},
		reloadDocument() {
			this.documents_service
				.list({ params: { proposal: this.document.proposal } })
				.then((resp) => {
					this.docs = this.load_docs(resp.data.results);
				});
		},
		load_docs(docs) {
			for (let index = 0; index < docs.length; index++) {
				const element = docs[index];
				if (
					Object.hasOwnProperty.call(
						this.usersDocuments,
						element.consultant
					)
				) {
					element.consultant =
						this.usersDocuments[element.consultant];
				} else {
					if (element.consultant) {
						this.user_service
							.retrieve(element.consultant)
							.then((resp) => {
								this.usersDocuments[element.consultant] =
									resp.data;
								element.consultant =
									this.usersDocuments[element.consultant];
							});
					}
				}
			}
			return docs;
		},
		deleteDoc(docId) {
			console.log(docId);
			this.documents_service.destroy(docId).then(({ data }) => {
				console.log(data);
				this.reloadDocument();
			});
		},
		handleDeleteOk() {
			this.documents_service.destroy(this.doc.id).then(() => {
				this.reload();
			});
		},
		async handleOk() {
			if (this.current_doc) {
				await this.documents_service.update({
					id: this.current_doc.id,
					status: false,
				});
			}
			await this.documents_service.update({
				id: this.doc.id,
				status: true,
			});

			this.reload();
		},
		handlePauseOk() {
			this.documents_service
				.update({
					id: this.current_doc.id,
					status: false,
				})
				.then(() => {
					this.reload();
				});
		},
		async loadProposals(proposals) {
			for (let index = 0; index < proposals.length; index++) {
				const element = proposals[index];

				element.client = this.clients[element.client]
					? this.clients[element.client]
					: await this.client_service
							.retrieve(element.client)
							.then((resp) => resp.data);

				if (element.consultant) {
					element.consultant = this.get_user(element.consultant);
				}
				this.approval_steps_service
					.list({ params: { proposal: element.id } })
					.then(async ({ data }) => {
						this.approval_steps[element.id] = data.results;
					});

				element.proposal_value = `R$
					${Intl.NumberFormat("pt-BR").format(element.proposal_value)}
				`;
				// pra formatar os valores monetarios
			}

			this.proposals = proposals;
		},
		editPreContrato(proposal) {
			this.proposal_service.update({ id: proposal.id }).then(() => {
				this.$router.push({ name: "editPrecontrato" });
			});
		},

		exibe() {
			$("#justificativa").removeClass("hideMe");
			$("#retorno").removeClass("hideMe");
			$("#contrato").addClass("hideMe");
			$("#etapa").addClass("hideMe");
			$("#firstButton").addClass("hideMe");
			$("#firstButtonAprovar").addClass("hideMe");
			$("#secondButton").removeClass("hideMe");
		},
		exibeAprovar() {
			$("#justificativa").removeClass("hideMe");
			$("#contrato").addClass("hideMe");
			$("#etapa").addClass("hideMe");
			$("#firstButton").addClass("hideMe");
			$("#firstButtonAprovar").addClass("hideMe");
			$("#aprovar").removeClass("hideMe");
		},
		esconde() {
			$("#novoArquivo").removeClass("hideMe");
			$("#escolha").addClass("hideMe");
			$("#aprovar").addClass("hideMe");

			$("#secondButton").addClass("hideMe");
		},

		exibeDocuments() {
			$("#novoArquivo").addClass("hideMe");
			$("#escolha").removeClass("hideMe");
			$("#aprovar").removeClass("hideMe");

			$("#secondButton").removeClass("hideMe");
		},

		format_date(date) {
			let o_date = DateTime.fromISO(date);
			return o_date.toFormat("dd/MM/y");
		},
		async get_user(user) {
			return this.users[user]
				? this.users[user]
				: await this.users_service
						.retrieve(user)
						.then(({ data }) => data);
		},
		format_expire_date(date) {
			let o_date = DateTime.fromISO(date);
			let now = DateTime.now();
			return `${
				o_date.diff(now, ["days", "hours"]).toObject()["days"]
			} dias`;
		},
		step_row(step, row) {
			let approval_step = this.approval_steps[row.item.id].filter(
				(i) => i.step == step.id
			);
			// console.log(approval_step);
			if (approval_step.length > 0) {
				return approval_step[0];
			}
		},
		steps_row(step, proposal) {
			let approval_steps = this.approval_steps[proposal.id].filter(
				(i) => i.step == step.id
			);
			return approval_steps;
		},
		async getLastDeniedReason(step, row) {
			let me = await this.users_service.me((res) => {
				return res.data;
			});

			let approval_step = this.approval_steps[row.item.id]
				.filter((i) => i.step == step.id)
				.filter((i) => i.user == me.data.id);

			if (approval_step.length > 0) {
				return approval_step[0];
			} else {
				return { reason: "" };
			}
		},

		openModal(row, step) {
			this.current_step = step;
			this.current_proposal = row.item;

			this.$root.$emit("bv::show::modal", "current-step", "#btnShow");
		},
		openModal2(row, step) {
			this.current_step = step;
			this.current_proposal = row.item;
			this.$root.$emit("bv::show::modal", "sucess-step", "#btnShow");
		},
		openModal3(row, step, getReasonAsyncFunction) {
			this.current_step = step;
			this.current_proposal = row.item;
			getReasonAsyncFunction(step, row).then((res) => {
				// console.log(res.reason);
				this.current_reason = res.reason;
			});

			this.$root.$emit("bv::show::modal", "deny-step", "#btnShow");
		},

		approveStep(proposal, step) {
			let reason = document.getElementById("reason").value;
			if (this.by_next[step.id]) {
				let approvalStep = this.approval_steps[proposal.id].filter(
					(i) => i.step == this.by_next[step.id].id
				);

				// console.log("Imprimindo todos os aproval step");
				// console.log(approvalStep);

				approvalStep = approvalStep[0]; // sim, a posicao 0 ta sendo a mais recente, nao lembro o motivo

				// console.log("Imprimindo aproval step posicao 0");
				// console.log(approvalStep);

				if (
					approvalStep == undefined ||
					(approvalStep &&
						(approvalStep.approved == false ||
							approvalStep.approved == null)) // checa se há algum passo anterior que nao possui nenhum status OU
					// que possui um status de reprovado ou de "resolvido porem não aprovado novamente ainda", se ele encontrar
					// aí ele bloqueia e mostra o modal
				) {
					Swal.fire({
						title: "Falha!",
						text: "Existem passos anteriores reprovados",
						icon: "error",
						showCancelButton: false,

						confirmButtonColor: "#0bb4d4",
						cancelButtonColor: "#A9A9A9",
						confirmButtonText: "OK",
						cancelButtonText: "Cancelar",
					});

					return;
				}
			}
			this.approval_steps_service
				.create({
					step: step.id,
					proposal: proposal.id,
					approved: true,
					reason: reason ? reason : "-",
				})
				.then(() => {
					this.proposal_service
						.list({ params: { started: 2 } })
						.then((resp) => {
							this.loadProposals(resp.data.results);
						});
				});
		},
		resolveStep(proposal, step) {
			if (this.by_next[step.id]) {
				let approvalStep = this.approval_steps[proposal.id].filter(
					(i) => i.step == this.by_next[step.id].id
				);

				approvalStep = approvalStep[0];

				if (
					approvalStep == undefined ||
					(approvalStep && approvalStep.approved == false) // checa se há campos não resolvidos antes primeiro
					// se houverem, bloqueia
				) {
					Swal.fire({
						title: "Falha!",
						text: "Existem passos anteriores reprovados",
						icon: "error",
						showCancelButton: false,

						confirmButtonColor: "#0bb4d4",
						cancelButtonColor: "#A9A9A9",
						confirmButtonText: "OK",
						cancelButtonText: "Cancelar",
					});

					return;
				}
			}
			this.approval_steps_service
				.create({
					step: step.id,
					proposal: proposal.id,
					approved: null,
					reason: "",
				})
				.then(() => {
					this.proposal_service
						.list({ params: { started: 2 } })
						.then((resp) => {
							this.loadProposals(resp.data.results);
						});
				});
		},
		denyStep(proposal, step) {
			let reason = document.getElementById("reason").value;

			if (this.by_next[step.id]) {
				let approvalStep = this.approval_steps[proposal.id].filter(
					(i) => i.step == this.by_next[step.id].id
				);

				// console.log("Imprimindo todos os aproval step");
				// console.log(approvalStep);

				approvalStep = approvalStep[0]; // sim, a posicao 0 ta sendo a mais recente, nao lembro o motivo

				// console.log("Imprimindo aproval step posicao 0");
				// console.log(approvalStep);

				if (
					approvalStep == undefined ||
					(approvalStep &&
						(approvalStep.approved == false ||
							approvalStep.approved == null)) // checa se há algum passo anterior que nao possui nenhum status OU
					// que possui um status de reprovado ou de "resolvido porem não aprovado novamente ainda", se ele encontrar
					// aí ele bloqueia e mostra o modal
				) {
					Swal.fire({
						title: "Falha!",
						text: "Existem passos anteriores reprovados",
						icon: "error",
						showCancelButton: false,

						confirmButtonColor: "#0bb4d4",
						cancelButtonColor: "#A9A9A9",
						confirmButtonText: "OK",
						cancelButtonText: "Cancelar",
					});

					return;
				}
			}

			let stepToReturn = document.getElementById("return").value;

			this.approval_steps_service
				.create({
					step: step.id,
					proposal: proposal.id,
					approved: false,
					reason: reason ? reason : "-",
				})
				.then(() => {
					this.proposal_service
						.list({ params: { started: 2 } })
						.then((resp) => {
							this.loadProposals(resp.data.results);
						});

					this.returnToStep(proposal, step, stepToReturn); // chamando a funcao de retornar
				});
		},
		returnToStep(proposal, currentStep, stepToReturn) {

			// tive que mudar essa funcao pelo mesmo motivo que mudei na funcao return_to_step_options mais acima, nao pode mais usar os IDS
			// pois eles ficam mudando, agora por exemplo eles ja mudaram à ponto de nao ficar organizados pela numeração (do menor pro maior)
			// aí ja nao tava funcionando mais, precisei mudar. (so que nessa eu exclui o codigo antigo)

			let all_steps = [
				{id: 14, name: "Credito", next: "Estoque"},
				{id: 16, name: "Estoque", next: "Comercial"},
				{id: 13, name: "Comercial", next: "Contratos"},
				{id: 17, name: "Contratos", next: "Logistica"},
				{id: 15, name: "Logistica", next: "Finalizado"},
				{id: 18, name: "Finalizado", next: null},
			]

			// stepToReturnData é o step para o qual deve retornar, ele deve rejeitar apenas o step que ele escolheu retornar, e todos os à frente até
			// chegar ao step atual (incluindo o step atual) todos os steps à frente (que não estiverem como undefined) devem receber status false
			// (pois é como se tivessem sido rejeitados, vão precisar passar denovo por cada pra resolver e aprovar)

			// fiz o find abaixo pois o stepToReturn original é apenas o ID

			stepToReturn = all_steps.find((step) => step.id === Number(stepToReturn))
			currentStep = all_steps.find((step) => step.id === currentStep.id)

			let stepsToDeny = [ stepToReturn ]

			while(stepToReturn.next !== currentStep.next){
				stepToReturn = stepToReturn.next
				stepToReturn = all_steps.find((step) => step.name === stepToReturn)
				stepsToDeny.push(stepToReturn)
			}

			stepsToDeny.pop() // pra remover o ultimo, pois o ultimo acaba sendo o currentStep, que ja foi recusado na funcao denyStep, antes de chamar a funcao atual

			stepsToDeny.forEach((s) => {
				this.approval_steps_service.create({
					step: s.id,
					proposal: proposal.id,
					approved: false,
					reason: "Pendente de reafirmação",
				});
			});
		},
		insert_into_databit_modal(proposal){
			this.current_proposal = proposal
			this.$root.$emit("bv::show::modal", "code-databit", "#btnShow");
		},
		insert_into_databit(proposal){
			if (proposal.started == 2) {
				if(this.codigo_databit != ""){
					this.proposal_service.insert_into_databit(proposal.id, this.codigo_databit).then(() => {
						this.reload()
					}).catch(({response: {data: {message}}}) => {
						Swal.fire({
							title: "Falha!",
							text: message,
							icon: "error",
							showCancelButton: false,

							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					})
				}else{
					Swal.fire({
						title: "Falha!",
						text: "É obrigatorio preencher o código do contrato",
						icon: "error",
						showCancelButton: false,

						confirmButtonColor: "#0bb4d4",
						cancelButtonColor: "#A9A9A9",
						confirmButtonText: "OK",
						cancelButtonText: "Cancelar",
					});
				}
			}else{
				Swal.fire({
						title: "Falha!",
						text: "Este contrato já foi adicionado ao databit",
						icon: "error",
						showCancelButton: false,

						confirmButtonColor: "#0bb4d4",
						cancelButtonColor: "#A9A9A9",
						confirmButtonText: "OK",
						cancelButtonText: "Cancelar",
					});
			}
		}
	},
	computed: {
		isProposalSelected() {
			let c_p = this.current_proposal;
			return Object.keys(c_p).length != 0;
		},
	},
};
</script>

<style lang="sass">
@import "~/public/styles/sass/index.scss"

.table th
  border-top: none

.ajust-header thead
  background-color: white !important
  border: none !important
  border-bottom: 1px solid #EEEEEE

.dark-button
  background: #384364 0% 0% no-repeat padding-box
  opacity: 1
  top: 189px
  left: 330px
  width: 206px
  height: 40px

.light-button
  top: 189px
  left: 535px
  width: 229px
  height: 40px
  background: #ffffff 0% 0% no-repeat padding-box
  border: 1px solid #eaecef
  opacity: 1

.dark-text

  color: var(--unnamed-color-384364)
  text-align: center
  font: normal normal bold 10px/11px Montserrat
  letter-spacing: 0.25px
  color: #384364
  text-transform: uppercase
  opacity: 0.6

.light-text
  text-align: center
  font: normal normal bold 10px/11px Montserrat
  letter-spacing: 0.25px
  color: #FFFFFF
  text-transform: uppercase
  opacity: 1

.btn-aprov
  background:#0bb4d4
  width: 40px
  height: 40px
  border-radius: 100%
  border: none

.container
  padding: 0px
  margin: 0px
  max-height: 100%
  display: flex
  justify-content: space-between

.btn-proposta
  background: #0bb4d4 0% 0% no-repeat padding-box
  border-width: 0px
  border-radius: 100px
  opacity: 1
  width: 155px
  height: 46px
  color: #ffffff
  font: normal normal bold 11px/11px Montserrat
  letter-spacing: 1.1px

.dark-button:focus
  outline-style: none
.light-button:focus
  outline-style: none

.input-text
  font: var(--unnamed-font-style-normal) normal 600 13px/66px Montserrat
  letter-spacing: var(--unnamed-character-spacing-0)
  text-align: left
  font: normal normal 600 13px/66px Montserrat
  letter-spacing: 0px
  color: #ACACAC
  opacity: 1

.div-botoes
  margin-left: -1.1%

.ajust-header
  text-align: center !important

.ajust-header thead
  background-color: #F9F9F9
  border: 2px solid #F0F0F0

.hideMe
  display: none !important

.table thead th
  border-top: 0px !important
  border-bottom: 0px !important
  color: #B5B7C0
  font-family: Poppins
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: normal
  letter-spacing: -0.14px

.table td
  color: #292D32
  font-family: Poppins
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: normal
  letter-spacing: -0.14px

.cliente
  text-align: left
  padding-left: 38px !important


</style>

<style scoped>
/deep/ .modal-header {
	border-bottom: 0 none;
	padding-bottom: 15px;
}

/deep/ .modal-footer {
	border-top: 0 none;
	margin-bottom: 20px;
}

/deep/ .modal-title {
	font: var(--unnamed-font-style-normal) normal bold 21px/31px Montserrat;
	letter-spacing: var(--unnamed-character-spacing-0);
	font: normal normal bold 21px/31px Montserrat;
	letter-spacing: 0px;
	color: #384364;
	opacity: 1;
	font-size: 20px;
	margin-bottom: -10px;
	padding-left: 10px;
	padding-top: 15px;
}

/deep/ .form-control:disabled {
	background-color: white;
}

.table thead th{
	border-bottom: 0px !important;
}

.btn-reprove {
	background-color: #f25d5d;
	width: 130px;
	height: 40px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}

.btn-reprove:hover {
	background-color: #f25d5d;
	margin-right: 20px;
	width: 130px;
	height: 40px;
	border-radius: 150px;
	border: none;
	font-size: 11px;

	font-family: "Montserrat", sans-serif;
	opacity: 1;
}
.btn-approve {
	background-color: #0bb4d4;
	width: 130px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}

.btn-approve:hover {
	background-color: #0bb4d4;
	width: 130px;
	border-radius: 150px;
	border: none;
	font-size: 11px;

	font-family: "Montserrat", sans-serif;
	opacity: 1;
}

.btn-ok {
	background-color: #0bb4d4;
	width: 130px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 40px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
	color: #ffffff;
}

.btn-ok:hover {
	background-color: #0bb4d4;
	width: 130px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 40px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}

.input-title {
	font-size: 13px;
	font-family: montserrat;
	color: #384364;
}

.btn-reprove2 {
	background-color: #f25d5d;
	margin-right: 20px;
	width: 120px;
	height: 30px;
	border-radius: 150px;
	border: none;
	font-size: 11px;

	font-family: "Montserrat", sans-serif;
	opacity: 1;
}

.btn-reprove2:hover {
	background-color: #f25d5d;
	margin-right: 20px;
	width: 120px;
	height: 30px;
	border-radius: 150px;
	border: none;
	font-size: 11px;

	font-family: "Montserrat", sans-serif;
	opacity: 1;
}
.btn-approve2 {
	background-color: #0bb4d4;
	width: 120px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 30px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}

.btn-approve2:hover {
	background-color: #0bb4d4;
	width: 120px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 30px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}
.btn-approve2:focus {
	background-color: #0bb4d4;
	width: 120px;
	border-radius: 150px;
	border: none;
	font-size: 11px;
	height: 30px;
	font-family: "Montserrat", sans-serif;
	opacity: 1;
}
.modal-table {
	border: 2px solid #90ddeb;
}

.ajust-th {
	border-bottom: none;
	text-align: center;
}

.ajust-td {
	text-align: center;
}

.ajust-th-body {
	text-align: center;
}
::-webkit-file-upload-button {
	display: none;
}
</style>
